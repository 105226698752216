import * as React from 'react';

const LabelView = ({ positions, width, height, type, rightClicked, hideLabel }) => {
    return (
        <>
            {positions &&
                positions.map((item) => {
                    let x = item.x;
                    let y = item.y;
                    let w = item.w;
                    let h = item.h;
                    let playerId = item.pui;
                    let frame_id = item.id;

                    let xpos = (width * x) / 1920;
                    let ypos = (height * y) / 1080;
                    let rect_w = (width * w) / 1920;
                    let rect_h = (height * h) / 1080;

                    let jersey_number = item.jersey;

                    let isLabelShow = hideLabel[playerId];

                    return (
                        <div
                            key={frame_id}
                            style={{
                                backgroundColor: 'rgba(255, 255, 255, 0)',
                                position: 'absolute',
                                left: xpos,
                                top: isLabelShow !== 0 ? ypos - 16 : ypos,
                                width: rect_w,
                                height: isLabelShow !== 0 ? rect_h + 16 : rect_h,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                zIndex: '10'
                            }}
                            onContextMenu={(event) => rightClicked(event, playerId)}
                        >
                            {isLabelShow !== 0 && (
                                <div
                                    style={{
                                        backgroundColor: 'rgba(0, 0, 0, 0.55)',
                                        paddingLeft: 2,
                                        paddingRight: 2,
                                        width: 'fitContent',
                                        height: 16,
                                        leftMargin: 'auto',
                                        rightMargin: 'auto',
                                        fontSize: 12,
                                        color: '#ffffff',
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {jersey_number ? `# ${jersey_number}` : playerId}
                                </div>
                            )}
                            {type === 1 && (
                                <div
                                    style={{
                                        flexGrow: 1,
                                        border: '1px solid red',
                                        width: '100%'
                                    }}
                                ></div>
                            )}
                        </div>
                    );
                })}
        </>
    );
};

export default LabelView;
