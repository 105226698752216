export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const UPDATEPROFILE_SUCCESS = 'UPDATEPROFILE_SUCCESS';
export const UPDATEPROFILE_FAIL = 'UPDATEPROFILE_FAIL';
export const UPDATEPROFILE1_SUCCESS = 'UPDATEPROFILE1_SUCCESS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

export const RESET_PWD_READY = 'RESET_PWD_READY';

export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const UPDATE_COUNT = 'UPDATE_COUNT';
export const UPDATE_CORRECTION_COUNT = 'UPDATE_CORRECTION_COUNT';

export const SETTINGS_LOGIN_PANEL = 'SETTINGS_LOGIN_PANEL';
export const SETTINGS_LOGIN_PANEL_CLOSE = 'SETTINGS_LOGIN_PANEL_CLOSE';
export const SETTINGS_FORGOT_PASSWORD_PANEL = 'SETTINGS_FORGOT_PASSWORD_PANEL';
export const SETTINGS_FORGOT_PASSWORD_PANEL_CLOSE = 'SETTINGS_FORGOT_PASSWORD_PANEL_CLOSE';
export const SETTINGS_REGISTER_PANEL = 'SETTINGS_REGISTER_PANEL';
export const SETTINGS_REGISTER_PANEL_CLOSE = 'SETTINGS_REGISTER_PANEL_CLOSE';
