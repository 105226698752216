import React, { useEffect, useState } from "react";
import "./PlayerDetails.css";
import { format } from "date-fns"
import { Link } from "react-router-dom";
import { CircularProgress } from '@mui/material';
import GameService from '../../services/game.service';
import Bar from "./charts/Bar";
import { ActionData } from '../../components/newcoach/components/common';
import { PLAYER_ICON_DEFAULT } from '../../common/staticData';
import PlayerImgComponent from './PlayerImgComponent';
import {
    statList,
    goalkeeper,
} from '../../common/staticData'
import { getSeriesData } from "../../common/utilities";
const styles = {
    loader: {
        position: 'fixed',
        left: '0px',
        top: '0px',
        width: '100%',
        height: '100%',
        backgroundColor: '#fff',
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    play: {
        color: '#07863d'
    },
    paper: { minWidth: '98%', maxHeight: 'none', backgroundColor: 'transparent' }
};

const arr_stat_profile = ['player_games', 'yellow_cards', 'red_cards'];
const courtAreaId = ['1', '2', '3', '4'];
const gameTime = ['1', '2', '3', '4', '5', '6']

const PlayerGameStats = ({ player, gameData, onMenuChange, gameList, seasonFilter, t, playVideoTag }) => {
    const jersey_style = { color: '#3794c1 !important' }
    const [loading, setLoading] = useState(true);
    const [seasonId, setSeasonId] = useState(null);
    const [playerState, setPlayerState] = useState(null);

    const [datas, setDatas] = useState([]);

    useEffect(() => {
        setLoading(true);
        GameService.getGamePlayerTags( //i call this function here to get the tags
            2,
            null,
            player.player_id,
            gameData.game_id,
            null,
            null,
            null,
            '1,2,3,4,5,6',
            null,
            null,
            null,
            null
        ).then((res) => {
            setDatas(getSeriesData(res));
        });

        if (player.player_position_name === 'Goalkeeper') {

            GameService.getGoalkeepersStatsAdvanceSummary({
                seasonId: seasonFilter === 'none' ? null : seasonFilter.id,
                leagueId: null,
                gameId: gameData.game_id,
                teamId: null,
                playerId: player.player_id,
                gameTime: '1,2,3,4,5,6',
                courtAreaId: null,
                insidePaint: null,
                homeAway: null,
                gameResult: null
            }).then((res) => {
                setPlayerState(res[0]);
            });
        }
        else {

            GameService.getPlayersStatsAdvanceSummary({
                seasonId: seasonFilter === 'none' ? null : seasonFilter.id,
                leagueId: null,
                gameId: gameData.game_id,
                teamId: null,
                playerId: player.player_id,
                gameTime: '1,2,3,4,5,6',
                courtAreaId: null,
                insidePaint: null,
                homeAway: null,
                gameResult: null
            }).then((res) => {
                setPlayerState(res[0]);
            });
        }

        setLoading(false);

    }, [seasonId, player]);


    const handleClick = (data) => {
        try {
            console.log('handleClick', data);
            const newData = data.map((item) => ({
                tag_id: item.id,
                start_time: item.player_tag_start_time,
                end_time: item.player_tag_end_time,
                player_name: item.player_names,
                action_name: item.action_names,
                action_type: item.action_type_names,
                action_result: item.action_result_names,
                game_id: item.game_id,
                team_id: player.team_id,
                court_area: item.court_area_id,
                inside_pain: item.inside_the_pain,
                period: getPeriod(item.period),
                time: item.time_in_game,
                home_team_image: item.home_team_logo,
                away_team_image: item.away_team_logo,
                home_team_goals: item.home_team_goal,
                away_team_goals: item.away_team_goal
            }));
            if (newData.length > 0) {
                playVideoTag({ game: gameData, tagList: newData })
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleDisplayVideo = (cell) => async (e) => {
        if (playerState && playerState[`total_${cell.id}`] > 0 && cell.title !== 'Games') {
            try {
                const res = await GameService.getGamePlayerTags(
                    2,
                    null,
                    `${player.player_id}`,
                    gameData.game_id,
                    ActionData[cell.action].action_id,
                    ActionData[cell.action].action_type_id,
                    ActionData[cell.action].action_result_id,
                    gameTime.length === 0 ? null : gameTime.join(','),
                    courtAreaId.length === 0 ? null : courtAreaId.join(','),
                    null,
                    null,
                    null
                );

                const data = cell.title === 'Shots In The Box'
                    ? res.filter((item) => item.inside_the_pain === true)
                    : cell.title === 'Shots Out Of The Box'
                        ? res.filter((item) => item.inside_the_pain === false)
                        : res;
                const newData = data.map((item) => ({
                    tag_id: item.id,
                    start_time: item.player_tag_start_time,
                    end_time: item.player_tag_end_time,
                    player_name: item.player_names,
                    action_name: item.action_names,
                    action_type: item.action_type_names,
                    action_result: item.action_result_names,
                    game_id: item.game_id,
                    team_id: player.team_id,
                    court_area: item.court_area_id,
                    inside_pain: item.inside_the_pain,
                    period: getPeriod(item.period),
                    time: item.time_in_game,
                    home_team_image: item.home_team_logo,
                    away_team_image: item.away_team_logo,
                    home_team_goals: item.home_team_goal,
                    away_team_goals: item.away_team_goal
                }));
                if (newData.length > 0) {
                    playVideoTag({ game: gameData, tagList: newData })
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    function getPeriod(id) {
        return id === 1 ? 'H1' : id === 2 ? 'H2' : 'OT';
    }

    const getList = (series) => {

        if (series) {
            const llst = []
            const slst = []
            const clst = []
            series.forEach(element => {
                if (element?.data > 0) {
                    llst.push(element.name)
                    slst.push(element.data)
                    clst.push(element.color)
                }
            });
            return { seriesDonut: slst, labelsLst: llst, colorLst: clst }
        }
    }

  
    return (
        <section className="container">
            {loading ? (
                <div style={styles.loader}>
                    <CircularProgress />
                </div>
            ) : (
                <div className="player-row-outer game-stats-row">
                    <div className="back-button w-100">
                        <h3>Game Stats</h3>
                        <Link onClick={() => onMenuChange('details')}>
                            <span className="bi bi-arrow-left-circle"></span> Back
                        </Link>
                    </div>
                    <div className="newGameCol " style={{ width: '29.9%' }}>
                        <div className="player-profile w-100">
                            <div className="match-period">
                                <h3 className="d-flex w-100">
                                    Selected Game <span className="ms-auto">{format(new Date(gameData.game_date), 'dd-MM-yyyy')}</span>
                                </h3>
                            </div>

                            <div className="gameData">
                                <div className="team-logo">
                                    <img src={gameData.home_team_image} alt="" />
                                    <span className="team-name">{gameData.home_team_name}</span>
                                    <span className="team-score">{gameData.home_team_goal}</span>
                                </div>
                                <div className="team-logo">
                                    <img src={gameData.away_team_image} alt="" />
                                    <span className="team-name">{gameData.away_team_name}</span>
                                    <span className="team-score">{gameData.away_team_goal}</span>
                                </div>
                            </div>
                            <div className="stats-row"></div>
                        </div>
                        <div className="player-profile profile-photo mt-3 w-100">
                            {/* <div className="player-photo">
                                <img src={player.player_image ? player.player_image.includes(process.env.REACT_APP_S3_URI) ? player.player_image : process.env.REACT_APP_S3_URI + player.player_image : PLAYER_ICON_DEFAULT}  alt="" />
                            </div> */}
                            {/* <div className="player-photo">
                                <div className="image-container">
                                    <img src={player.player_image ? player.player_image.includes(process.env.REACT_APP_S3_URI) ? player.player_image : process.env.REACT_APP_S3_URI + player.player_image : PLAYER_ICON_DEFAULT} alt="" />
                                    <div className="corner top-left" ><img src={player?.team_image ? player?.team_image.includes(process.env.REACT_APP_S3_URI) ? player?.team_image : process.env.REACT_APP_S3_URI + player?.team_image : '../../../ball-img.png'} alt="" style={{ width: "18%", textAlign: 'left', float: 'left' }} /></div>
                                    <div className="corner top-right" >{player.player_first_name}<br />{player.player_last_name}</div>
                                    <div className="corner bottom-left" style={{ textAlign: 'left' }}>  <img src={getFootImage(player?.player_strong_foot)} alt="" style={{ width: "13%" }} /></div>
                                    <div className="corner bottom-right">
                                        <div className="skill-info-mini skill-infoSec">
                                            <img src="../../../white-jersy-img.png" alt="" />
                                            <span style={jersey_style}>{player?.player_jersey_number < 99 ? player.player_jersey_number : 'NA'}</span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <PlayerImgComponent
                                player={playerState}
                            />

                            <div className="player-minuties-row">
                                <div className="match-period">
                                    <h3>All Games</h3>
                                </div>
                                <div className="player-minutes">
                                    {/*  <div className="team-logo ad-team-logo">
                                    <img src="images/team-logo01.png" alt="">
                                    <h3>U17- Apollon Limassol</h3>
                                </div>  */}
                                    <div className="ad-minutes-detail">
                                        <p>
                                            Total Minutes Played: <span>{playerState?.total_games_player_minutes}</span>
                                        </p>
                                        <p>
                                            Total Games Played: <span>{playerState?.total_games_player_played}</span>
                                        </p>
                                        <p>
                                            Starting 11: <span>{playerState?.total_games_player_starting}</span>
                                        </p>
                                        <p>
                                            Average Minutes Per Game: <span>{playerState?.total_games_player_average_minutes}</span>
                                        </p>
                                        <p>
                                            Percentage Minutes Played: <span>{playerState?.total_games_player_percentage_minutes}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="player-minuties-row">
                                <div className="match-period">
                                    <h3>Selected Game</h3>
                                </div>
                                <div className="player-minutes">
                                    {/* <div className="team-logo ad-team-logo">
                        <img src="images/team-logo01.png" alt=""/>
                        <h3>U17- Apollon Limassol</h3>
                    </div> */}
                                    <div className="ad-minutes-detail">
                                        <p>
                                            Minutes Played: <span>{playerState?.selected_games_player_minutes}</span>
                                        </p>
                                        <p>
                                            Starting 11: <span>{playerState?.selected_games_player_starting === 1 ? 'Yes' : 'No'}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="player-profile newGameColSec" style={{ width: '28.9%' }}>
                        {datas.map((stat, index) => (
                            stat.total > 0 && (
                                <Bar
                                    className="mb-3"
                                    title={stat.title}
                                    seriesBar={stat.seriesBar}
                                    seriesDonut={getList(stat.seriesBar)}
                                    total={stat.total}
                                    tags={stat.tags}
                                    handleClick={handleClick}
                                    key={index}
                                />
                            )
                        ))}
                    </div>

                    <div className="player-profile player-actionOuter" style={{ width: '37.9%' }}>
                        <div className="match-period">
                            <h3>Game Actions</h3>
                        </div>

                        <div className="player-action-details">
                            {((player && player.player_position_name && player.player_position_name === 'Goalkeeper') || (player && player.pos_name && player.pos_name === 'Goalkeeper')
                                ? goalkeeper
                                : statList
                            ).map(
                                (item, index) =>
                                    !arr_stat_profile.includes(item.id) &&
                                    playerState &&
                                    playerState[`total_${item.id}`] > 0 && (
                                        <div className="action-col stats-data" onClick={handleDisplayVideo(item)} key={index}>
                                            <div className="action-img">
                                                <h5>{playerState ? playerState[`total_${item.id}`] : '0'}</h5>
                                            </div>
                                            <h3>{item.title}</h3>
                                            {/*<span>{playerState && playerState[`average_${item.id}`]  ? playerState[`average_${item.id}`] : '0'} per game</span>*/}
                                        </div>
                                    )
                            )}
                        </div>
                    </div>
                </div>
            )}
        </section>
    );
};

export default PlayerGameStats;
