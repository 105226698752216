import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import { TEAM_ICON_DEFAULT } from '../../../common/staticData';
import GameImage from '../../../assets/game_image.jpg';
import SearchIcon from '@mui/icons-material/SearchOutlined';

import GameService from '../../../services/game.service';

const AddHideGameDialog = ({ open, onClose, setRefresh }) => {
    const [loading, setLoading] = useState(false);
    const [teamList, setTeamList] = useState([]);
    const [teamSelectedIndex, setTeamSelectedIndex] = useState(-1);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [currentStep, setCurrentStep] = useState('team');
    const [gameList, setGameList] = useState([]);
    const [gameIds, setGameIds] = useState([]);
    const [searchText, setSearchText] = useState('');
    const gameIdRef = useRef();

    gameIdRef.current = gameIds;

    const handleBack = (e) => {
        setCurrentStep('team');
    };

    const handleContinue = async (e) => {
        setSearchText('');

        if (currentStep === 'team') {
            setCurrentStep('game');
            setGameList([]);
            setGameIds([]);
            setLoading(true);
            GameService.getAllGamesByCoach(null, null, selectedTeam.id, null).then((res) => {
                setGameList(res);
                setLoading(false);
            });

            return;
        }

        await gameIds.map(async (item) => {
            return await GameService.addHideGame(-1, item, selectedTeam.id);
        });
        setRefresh((old) => !old);
        onClose();
    };

    const handleSelectGame = (item) => {
        if (gameIdRef.current.includes(item.id)) setGameIds(gameIdRef.current.filter((data) => data !== item.id));
        else setGameIds((old) => [...old, item.id]);
    };

    const getFilteredList = () => {
        if (currentStep === 'team') return teamList.filter((item) => item.name.toLowerCase().includes(searchText.toLowerCase()));

        return gameList.filter(
            (item) =>
                item.home_team_name.toLowerCase().includes(searchText.toLowerCase()) ||
                item.away_team_name.toLowerCase().includes(searchText.toLowerCase()) ||
                item.league_name.toLowerCase().includes(searchText.toLowerCase()) ||
                item.season_name.toLowerCase().includes(searchText.toLowerCase())
        );
    };

    useEffect(() => {
        setSelectedTeam(null);
        setTeamSelectedIndex(-1);
        setTeamList([]);
        setCurrentStep('team');
        setLoading(true);
        GameService.getAllTeams().then((res) => {
            setTeamList(res);
            setLoading(false);
        });
    }, [open]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Add Hided Games</DialogTitle>
            <DialogContent dividers style={{ width: '460px', minHeight: '48vh', maxHeight: '50vh', overflowY: 'auto', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <TextField
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    placeholder="Search"
                    label=""
                    inputProps={{ 'aria-label': 'Without label' }}
                    variant="outlined"
                    autoFocus
                    sx={{
                        width: '100%',
                        fontSize: '0.8rem',
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                        '& .MuiOutlinedInput-root': { borderRadius: '10px' },
                        '& .MuiOutlinedInput-input': { padding: 0, height: '36px' }
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconButton>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <Divider sx={{ width: '100%' }} />
                {loading && (
                    <div style={{ width: '100%', height: '40vh', zIndex: 9999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                    </div>
                )}
                {!loading &&
                    currentStep === 'team' &&
                    getFilteredList().map((item, index) => (
                        <div
                            key={index}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '16px',
                                border: teamSelectedIndex === index ? '2px solid #ffffff' : '1px solid #7c7d7d',
                                borderRadius: '8px',
                                padding: '4px 8px',
                                cursor: 'pointer',
                                background: item.team_color ? item.team_color : 'transparent'
                            }}
                            onClick={() => {
                                setTeamSelectedIndex(index);
                                setSelectedTeam(item);
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '24px' }}>
                                <img src={item.image ? item.image : TEAM_ICON_DEFAULT} style={{ height: '24px' }} />
                            </div>
                            <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontWeight: 500, fontSize: '0.7rem', color: '#ffffff' }}>{item.name}</Typography>
                        </div>
                    ))}
                {!loading &&
                    currentStep === 'game' &&
                    getFilteredList().map((item, index) => (
                        <div
                            key={index}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                border: gameIdRef.current.includes(item.id) ? '2px solid #ffffff' : '1px solid #7c7d7d',
                                borderRadius: '8px',
                                padding: '4px 8px',
                                cursor: 'pointer',
                                background: gameIdRef.current.includes(item.id) ? '#6c6d6d' : 'transparent'
                            }}
                            onClick={() => handleSelectGame(item)}
                        >
                            <img style={{ height: '45px', width: '75px', borderRadius: '6px' }} src={item.image ? item.image : GameImage} />
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '180px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '20px' }}>
                                        <img src={item.home_team_image ? item.home_team_image : TEAM_ICON_DEFAULT} style={{ height: '20px' }} />
                                    </div>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontWeight: 500, fontSize: '0.7rem', color: '#ffffff' }}>{item.home_team_name}</Typography>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '20px' }}>
                                        <img src={item.away_team_image ? item.away_team_image : TEAM_ICON_DEFAULT} style={{ height: '20px' }} />
                                    </div>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontWeight: 500, fontSize: '0.7rem', color: '#ffffff' }}>{item.away_team_name}</Typography>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '120px' }}>
                                <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontWeight: 500, fontSize: '0.7rem', color: '#ffffff' }}>{item.league_name}</Typography>
                                <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontWeight: 500, fontSize: '0.7rem', color: '#ffffff' }}>{item.season_name}</Typography>
                            </div>
                        </div>
                    ))}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" disabled={loading || currentStep === 'team'} onClick={handleBack}>
                    Back
                </Button>
                <Button variant="outlined" disabled={loading || selectedTeam === null} onClick={handleContinue}>
                    Continue
                </Button>
                <Button variant="outlined" onClick={onClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddHideGameDialog;
