import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress } from '@mui/material';
import GameService from '../../../../../services/game.service';
import { getComparator, getFormattedDate, stableSort } from '../../../components/utilities';
import '../../../coach_style.css';
import TeamGamesVideoPlayer from './videoDialog';
import { getPeriod } from '../../../games/tabs/overview/tagListItem';
import { ActionData } from '../../../components/common';
import GameExportToEdits from '../../../games/tabs/overview/exportEdits';
import GeniusBallLogo from '../../../../../assets/images/football/Genius-Ball-Logo_Original Colour - Black.png';
import s4uLogo from '../../../../../assets/images/football/SmallerS4ULogo.png';
import './DataAnalysis.css';

const properties = [
    { id: 'total_goal', action: 'Goal' },
    { id: 'total_passes', action: 'Passes' },
    { id: 'total_successful_passes', action: 'PassesSuccess' },
    { id: 'total_shot', action: 'GoalKick' },
    { id: 'total_shot_on_target', action: 'GoalOpportunity' },
    { id: 'total_crosses', action: 'Cross' },
    { id: 'total_cross_successful', action: 'CrossSuccess' },
    { id: 'total_dribble', action: 'Dribble' },
    { id: 'total_turnover', action: 'Turnover' },
    { id: 'total_interception', action: 'Interception' },
    { id: 'total_tackle', action: 'Tackle' },
    { id: 'total_fouls', action: 'Foul' },
    { id: 'total_corners', action: 'Corner' },
    { id: 'total_free_kick', action: 'FreeKick' }
    //{ id: 'total_blocked', action: 'Blocked' },
    //{ id: 'total_clearance', action: 'Clearance' },
    //{ id: 'total_saved', action: 'Saves' },
    //{ id: 'total_draw_fouls', action: 'DrawFouls' },

];

const TeamGames = ({ games, gameIds, teamId, seasonId, t, teamName, teamShortName, teamLogo, }) => {
    const [teamStatList, setTeamStatList] = useState([]);
    const [teamSummaryStatList, setTeamSummaryStatList] = useState([]);
    const [playData, setPlayData] = useState([]);
    const [videoOpen, setVideoOpen] = useState(false);
    const [videoURL, setVideoURL] = useState('');
    const [exportOpen, setExportOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [hoverIndex, setHoverIndex] = useState(-1);
    const [loading, setLoading] = useState(false);
    const { user: currentUser } = useSelector((state) => state.auth);
    const tableDataAnalysis = useRef(null);

    const handleDisplayVideo = (cell, isOur, prop) => {
        const value = cell.team_id === cell.home_team_id ? (isOur ? cell[prop.id] : cell[`opp_${prop.id}`]) : isOur ? cell[`opp_${prop.id}`] : cell[prop.id];

        if (value <= 0) return;

             console.log("action", ActionData, prop.action);

        GameService.getGamePlayerTags(
            currentUser.id,
            isOur ? cell.home_team_id : cell.away_team_id,
            null,
            `${cell.game_id}`,
            ActionData[prop.action].action_id,
            ActionData[prop.action].action_type_id,
            ActionData[prop.action].action_result_id,
            null,
            null,
            null,
            null,
            null
        ).then((res) => {
            setPlayData(
                res.map((item) => {
                    return {
                        tag_id: item.id,
                        start_time: item.player_tag_start_time,
                        end_time: item.player_tag_end_time,
                        player_name: item.player_names,
                        action_name: item.action_names,
                        action_type: item.action_type_names,
                        action_result: item.action_result_names,
                        game_id: item.game_id,
                        team_id: teamId,
                        court_area: item.court_area_id,
                        inside_pain: item.inside_the_pain,
                        period: getPeriod(item.period),
                        time: item.time_in_game,
                        home_team_image: item.home_team_logo,
                        away_team_image: item.away_team_logo,
                        home_team_goals: item.home_team_goal,
                        away_team_goals: item.away_team_goal
                    };
                })
            );
            setVideoURL(games.filter((item) => item.id === cell.game_id)[0].video_url);
            setVideoOpen(true);
        });
    };

    const handleExportTags = (cell, isOur, prop) => (e) => {
        e.preventDefault();

        const value = cell.team_id === cell.home_team_id ? (isOur ? cell[prop.id] : cell[`opp_${prop.id}`]) : isOur ? cell[`opp_${prop.id}`] : cell[prop.id];

        if (value <= 0) return;

        console.log("action", ActionData, prop.action);

        GameService.getGamePlayerTags(
            currentUser.id,
            isOur ? cell.home_team_id : cell.away_team_id,
            null,
            `${cell.game_id}`,
            ActionData[prop.action].action_id,
            ActionData[prop.action].action_type_id,
            ActionData[prop.action].action_result_id,
            null,
            null,
            null,
            null,
            null
        ).then((res) => {
            setPlayData(res);
            setExportOpen(true);
        });
    };

    const getGameResultImage = (game) => {
        const isOur = game.team_id === game.home_team_id ? 1 : game.team_id === game.away_team_id ? 2 : 0;

        if (isOur !== 0) {
            if (game['total_goal'] > game['opp_total_goal']) {
                if (isOur === 1) return { home: { text: 'W', color: '#52B030' }, away: { text: '', color: '#ffffff' } };
                else return { home: { text: '', color: '#ffffff' }, away: { text: 'W', color: '#52B030' } };
            } else if (game['total_goal'] < game['opp_total_goal']) {
                if (isOur === 1) return { home: { text: 'L', color: '#C1272D' }, away: { text: '', color: '#ffffff' } };
                else return { home: { text: '', color: '#ffffff' }, away: { text: 'L', color: '#C1272D' } };
            } else {
                if (isOur === 1) return { home: { text: 'D', color: '#A4AAB0' }, away: { text: '', color: '#ffffff' } };
                else return { home: { text: '', color: '#ffffff' }, away: { text: 'D', color: '#A4AAB0' } };
            }
        }

        return { home: { text: '', color: '#ffffff' }, away: { text: '', color: '#ffffff' } };
    };

    const getGameGoalsFontStyle = (game) => {
        if (game.team_id === game.home_team_id) return { home: 700, away: 400 };

        return { home: 400, away: 700 };
    };

    useEffect(() => {
        if (gameIds.length > 0) setLoading(false);
    }, [teamSummaryStatList]);

    useEffect(() => {
        if (gameIds.length > 0) {
            setLoading(true);
            GameService.getTeamsStatsGamebyGameSummary({
                seasonId: seasonId,
                leagueId: null,
                gameIds: gameIds.join(','),
                teamId: teamId,
                gameTime: null,
                courtAreaId: null,
                insidePaint: null,
                homeAway: null,
                gameResult: null
            }).then((res) => {
                setTeamSummaryStatList(stableSort(res, getComparator('desc', 'game_date')));
            });

            GameService.getTeamsStatsGamebyGame({
                seasonId: seasonId,
                leagueId: null,
                gameId: gameIds.join(','),
                teamId: teamId,
                gameTime: null,
                courtAreaId: null,
                insidePaint: null,
                homeAway: null,
                gameResult: null
            }).then((res) => {
                setTeamStatList(stableSort(res, getComparator('desc', 'game_date')));
            });
        } else setTeamSummaryStatList([]);
    }, [gameIds, refresh]);

    return (
        <Box sx={{ width: '100%', background: '#ffffff', height: '75vh', display: 'flex', padding: '20px 10px' }}>
            {loading && (
                <div style={{ width: '100%', height: '100%', zIndex: 9999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <>
                    <Box className="printable-content" sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <img className="headerGeniusballLogo" src={GeniusBallLogo} />
                        <img className="headerTeamLogo" src={teamLogo} />
                        <p className="headerTeamName">{teamName}</p>
                        <p className="headerReportName">Data Analysis Game By Game</p>
                        <img className="headerS4ULogo" src={s4uLogo} />
                        <TableContainer className="tableDataAnalysis" ref={tableDataAnalysis} >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow height="36px">
                                        <TableCell style={{ width: '120px' }} key="game" align="center">
                                            {t('Game')}
                                        </TableCell>
                                        <TableCell key="team" align="center" colSpan={2}>
                                            {t('Team')}
                                        </TableCell>
                                        <TableCell key="formattion" align="center">
                                            {t('Formation')}
                                        </TableCell>
                                        <TableCell key="goals" align="center">
                                            {t('Goals')}
                                        </TableCell>
                                        <TableCell key="passes" align="center">
                                            {t('Passes')}
                                        </TableCell>
                                        <TableCell key="successfull_passes" align="center">
                                            {t('Passes Completed')}
                                        </TableCell>
                                        <TableCell key="shots" align="center">
                                            {t('Shots')}
                                        </TableCell>
                                        <TableCell key="shots_on_target" align="center">
                                            {t('Shots On Target')}
                                        </TableCell>
                                        <TableCell key="crosses" align="center">
                                            {t('Crosses')}
                                        </TableCell>
                                        <TableCell key="successfull_crosses" align="center">
                                            {t('Crosses Completed')}
                                        </TableCell>
                                        <TableCell key="dribbles" align="center">
                                            {t('Dribbles')}
                                        </TableCell>
                                        <TableCell key="turnovers" align="center">
                                            {t('Turnovers')}
                                        </TableCell>
                                        <TableCell key="interceptions" align="center">
                                            {t('Interceptions')}
                                        </TableCell>
                                        <TableCell key="tackles" align="center">
                                            {t('Tackles')}
                                        </TableCell>
                                        <TableCell key="fouls" align="center">
                                            {t('Fouls Given')}
                                        </TableCell>
                                        <TableCell key="corners" align="center">
                                            {t('Corners')}
                                        </TableCell>
                                        <TableCell key="free_kicks" align="center">
                                            {t('Free Kicks')}
                                        </TableCell>
                                       </TableRow>
                                </TableHead>
                                <TableBody>
                                    {teamStatList.map((item, index) => (
                                        <React.Fragment key={`teamStat-${index}`}>
                                            <TableRow key={`${index}-team`} height="36px" onMouseEnter={() => setHoverIndex(index)} onMouseLeave={() => setHoverIndex(-1)}>
                                                <TableCell key={`${index}-game`} align="center" rowSpan={2} sx={{ borderBottom: '1px solid #17375E' }}>
                                                    <div>
                                                        <p className="normal-text">{item.league_name}</p>
                                                        <p className="normal-text">
                                                           {getFormattedDate(item.game_date)}
                                                        </p>
                                                    </div>
                                                </TableCell>
                                                <TableCell style={{ width: '30px' }} key={`${index}-result`} align="center">
                                                    <div
                                                        style={{
                                                            background: `${getGameResultImage(item)['home'].color}`,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            width: '24px',
                                                            height: '24px'
                                                        }}
                                                    >
                                                        <p className="normal-text-white">{getGameResultImage(item)['home'].text}</p>
                                                    </div>
                                                </TableCell>
                                                <TableCell 
                                                    key={`${index}-home`}
                                                    align="center"
                                                    sx={{ fontWeight: getGameGoalsFontStyle(item).home, background: hoverIndex === index ? '#F8F8F8' : '#ffffff' }}
                                                >
                                                    {item.home_team_name}
                                                </TableCell>
                                                <TableCell
                                                    key={`${index}-format`}
                                                    align="center"
                                                    sx={{ fontWeight: getGameGoalsFontStyle(item).home, background: hoverIndex === index ? '#F8F8F8' : '#ffffff' }}
                                                >
                                                    {item.team_formation_name.toLowerCase().includes('unknown') ? '' : item.team_formation_name}
                                                </TableCell>
                                                {properties.map((prop) => (
                                                    <TableCell
                                                        key={`${index}-prop-${prop.id}`}
                                                        align="center"
                                                        sx={{ cursor: 'pointer', fontWeight: getGameGoalsFontStyle(item).home, background: hoverIndex === index ? '#F8F8F8' : '#ffffff' }}
                                                        onClick={() => handleDisplayVideo(item, true, prop)}
                                                        onContextMenu={handleExportTags(item, true, prop)}
                                                    >
                                                        {item.team_id === item.home_team_id ? item[prop.id] : item[`opp_${prop.id}`]}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                            <TableRow key={teamStatList.length + index} height="36px" hover>
                                                <TableCell key={`${teamStatList.length + index}-result`} align="center" sx={{ borderBottom: '1px solid #0A7304' }}>
                                                    <div
                                                        style={{
                                                            background: `${getGameResultImage(item)['away'].color}`,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            width: '24px',
                                                            height: '24px'
                                                        }}
                                                    >
                                                        <p className="normal-text-white">{getGameResultImage(item)['away'].text}</p>
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    key={`${teamStatList.length + index}-away`}
                                                    align="center"
                                                    sx={{ borderBottom: '1px solid #0A7304', fontWeight: getGameGoalsFontStyle(item).away }}
                                                >
                                                    {item.away_team_name}
                                                </TableCell>
                                                <TableCell
                                                    key={`${teamStatList.length + index}-format`}
                                                    align="center"
                                                    sx={{ borderBottom: '1px solid #0A7304', fontWeight: getGameGoalsFontStyle(item).away }}
                                                >
                                                    {item.opponent_formation_name.toLowerCase().includes('unknown') ? '' : item.opponent_formation_name}
                                                </TableCell>
                                                {properties.map((prop) => (
                                                    <TableCell
                                                        key={`${teamStatList.length + index}-opp_${prop.id}`}
                                                        align="center"
                                                        sx={{ borderBottom: '1px solid #0A7304', cursor: 'pointer', fontWeight: getGameGoalsFontStyle(item).away }}
                                                        onClick={() => handleDisplayVideo(item, false, prop)}
                                                        onContextMenu={handleExportTags(item, false, prop)}
                                                    >
                                                        {item.team_id === item.home_team_id ? item[`opp_${prop.id}`] : item[prop.id]}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </React.Fragment>
                                    ))}

                                    {teamSummaryStatList.map((item, index) => (
                                        <React.Fragment key={`teamSummaryStat-${index}`}>
                                            <TableRow key={`${index}-summary`} height="36px" onMouseEnter={() => setHoverIndex(index)} onMouseLeave={() => setHoverIndex(-1)}>
                                                <TableCell key={`${index}-game-summary`} align="center" sx={{ borderBottom: '1px solid #17375E', fontWeight: 800 }}>
                                                    {item.all_type.toUpperCase()}
                                                </TableCell>
                                                <TableCell sx={{ borderBottom: '1px solid #17375E' }}></TableCell>
                                                <TableCell sx={{ borderBottom: '1px solid #17375E', fontWeight: 800 }} align="center">
                                                    {item.all_type === 'summary' ? 'Game count: ' + item.total_game : ''}
                                                </TableCell>
                                                <TableCell sx={{ borderBottom: '1px solid #17375E' }}></TableCell>
                                                {properties.map((prop) => (
                                                    <TableCell
                                                        key={`${index}-${prop.id}-summary`}
                                                        align="center"
                                                        sx={{
                                                            cursor: 'pointer',
                                                            borderBottom: '1px solid #17375E',
                                                            fontWeight: 900,
                                                            background: hoverIndex === index ? '#F8F8F8' : '#ffffff'
                                                        }}
                                                        onContextMenu={handleExportTags(item, true, prop)}
                                                    >
                                                        {item.team_id === item.home_team_id ? item[prop.id] : item[`opp_${prop.id}`]}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </>
            )}
            <TeamGamesVideoPlayer
                t={t}
                open={videoOpen}
                onClose={(flag) => {
                    setVideoOpen(false);

                    if (flag) setRefresh((r) => !r);
                }}
                video_url={videoURL}
                tagList={playData}
            />
            <GameExportToEdits t={t} open={exportOpen} onClose={() => setExportOpen(false)} tagList={playData} isTeams={false} />
        </Box>
    );
};

export default TeamGames;
