import { Box } from '@mui/material';
import React from 'react';
import Sidebar from './sidebar';
import { useTranslation } from 'react-i18next';
import './coach_style.css';

const NewCoach = ({ children }) => {
    const { t } = useTranslation();

    return (
        <Box style={{ display: 'flex', minHeight: '100vh' }}>
            <Sidebar t={t} />
            <Box style={{ backgroundColor: '#F8F8F8', flex: 1, display: 'flex', justifyContent: 'center' }}>{children}</Box>
        </Box>
    );
};

export default NewCoach;
