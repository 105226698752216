import React from 'react';

import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import TeamsIcon from '@mui/icons-material/GroupsOutlined';
import LeadersIcon from '@mui/icons-material/PeopleAltOutlined';
import TheatersIcon from '@mui/icons-material/Theaters';
import CheckIcon from '@mui/icons-material/Check';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import PanToolIcon from '@mui/icons-material/PanTool';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import GroupIcon from '@mui/icons-material/Group';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import SummarizeIcon from '@mui/icons-material/Summarize';

export const menuData = [
    [
        {
            key: 0,
            id: 'leaders',
            path: '/new_coach/leaders',
            icon: <LeadersIcon />,
            title: 'Rankings'
        }
    ],
    [
        {
            key: 1,
            id: 'games',
            path: '/new_coach/games',
            icon: <SportsSoccerIcon />,
            title: 'Games'
        },
        {
            key: 2,
            id: 'teams',
            path: '/new_coach/teams',
            icon: <TeamsIcon />,
            title: 'Teams'
        },
        {
            key: 3,
            id: 'players',
            path: '/new_coach/players',
            icon: <GroupIcon />,
            title: 'Players'
        },
        {
            key: 4,
            id: 'goalkeepers',
            path: '/new_coach/goalkeepers',
            icon: <PanToolIcon />,
            title: 'Goalkeepers'
        }
    ],
    [
        {
            key: 5,
            id: 'reports',
            path: '/new_coach/reports',
            icon: <SummarizeIcon />,
            title: 'Reports'
        },
    ],
    [

        {
            key: 6,
            id: 'video',
            path: '/new_coach/video_cutter',
            icon: <TheatersIcon />,
            title: 'Video Editor'
        },
        {
            key: 7,
            id: 'edits',
            path: '/new_coach/edits',
            icon: <PersonalVideoIcon />,
            title: 'My Edits'
        }
    ],
    [
        {
            key: 8,
            id: 'corrections',
            path: '/new_coach/corrections',
            icon: <CheckIcon />,
            title: 'Corrections'
        }
    ],
    [
        {
            key: 9,
            id: 'renders',
            path: '/new_coach/renders',
            icon: <CloudDownloadIcon />,
            title: 'Renders'
        },
        {
            key: 10,
            id: 'settings',
            path: '/new_coach/settings',
            icon: <SettingsIcon />,
            title: 'Settings'
        }
    ]
    // {
    //     id: 'opponents',
    //     path: '/new_coach/opponents',
    //     icon: <SportsSoccerIcon />,
    //     title: 'Opponents'
    // },
];
