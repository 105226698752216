import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';

import '../../../coach_style.css';
import GameExportToEdits from '../../../games/tabs/overview/exportEdits';
import { getPeriod } from '../../../games/tabs/overview/tagListItem';
import TeamStatsVideoPlayer from './videoDialog';

let boxList = [
    [
       { id: 'Goal', title: 'Goals Scored', total: 0, data: [] }, //action_names = Shot, action_result=Goal
        { id: 'Goal Received', title: 'Goals Gained', total: 0, data: [] }, // action_names = Shot, action_type_names=Penalty
        { id: 'Pass', title: 'Passes', total: 0, data: [] }, //action_names = Pass
        { id: 'Cross', title: 'Crosses', total: 0, data: [] }, //action_names = Pass
        { id: 'Shot', title: 'Goal Kicks', total: 0, data: [] }, // action_names = Shot
        { id: 'Dribble', title: 'Dribbles', total: 0, data: [] }, // action_names = Super Saved + Saved
        { id: 'Interception', title: 'Interceptions', total: 0, data: [] }, //action_names = Interception
        { id: 'Tackle', title: 'Tackles', total: 0, data: [] }, //action_names = Tackle
        { id: 'Blocked', title: 'Blocked', total: 0, data: [] }, //action_names = Blocked
        { id: 'Clearance', title: 'Clearance', total: 0, data: [] }, //action_names = Clearance
        { id: 'Draw Foul', title: 'Draw Fouls', total: 0, data: [] }, //action_names = Draw Foul
        { id: 'Corner', title: 'Corners', total: 0, data: [] }, //action_names = Cross, action_type_names= Corner or action_names = Pass, action_type_names= Corner
        { id: 'Free Kick', title: 'Free Kicks', total: 0, data: [] }, //action_names = Shot, action_type_names= Free Kick
        { id: 'Throw-In', title: 'Throw-Ins', total: 0, data: [] } //action_names = Pass, action_type_names= Throw-In
    ]
];

const TeamStatsBoxList = ({ games, list, teamId, refreshPage, t }) => {
    const [actionList, setActionList] = useState([]);
    const [videoOpen, setVideoOpen] = useState(false);
    const [playData, setPlayData] = useState([]);
    const [exportOpen, setExportOpen] = useState(false);

    const handleDisplayVideo = (player) => {
        if (player.total > 0) {
            setPlayData(
                player.data.map((item) => {
                    return {
                        tag_id: item.id,
                        start_time: item.player_tag_start_time,
                        end_time: item.player_tag_end_time,
                        player_name: item.player_names,
                        action_name: item.action_names,
                        action_type: item.action_type_names,
                        action_result: item.action_result_names,
                        game_id: item.game_id,
                        team_id: teamId,
                        court_area: item.court_area_id,
                        inside_pain: item.inside_the_pain,
                        period: getPeriod(item.period),
                        time: item.time_in_game,
                        home_team_image: item.home_team_logo,
                        away_team_image: item.away_team_logo,
                        home_team_goals: item.home_team_goal,
                        away_team_goals: item.away_team_goal
                    };
                })
            );
            setVideoOpen(true);
        }
    };

    const handleExportTags = (player) => (e) => {
        e.preventDefault();

        if (player.total > 0) {
            setPlayData(player.data);
            setExportOpen(true);
        }
    };

    useEffect(() => {
        let temp = [];

       boxList.map((row, rId) => {
            return row.map((item, cId) => {
                boxList[rId][cId].total = 0;
                boxList[rId][cId].data = [];

                return boxList;
            });
        });

        if (list.length > 0) {
            console.log('list',list);
                
            boxList.map((row, rId) => {
                console.log('boxList[rId]',boxList[rId]);
                return row.map((item, cId) => {
                    boxList[rId][cId].total =
                        item.id === 'Saved'
                            ? list.filter((stat) => stat.action_names === 'Saved' || stat.action_names === 'Super Saved').length
                            : list.filter((stat) => stat.action_names === item.id || stat.action_type_names === item.id).length;
                    boxList[rId][cId].data =
                        item.id === 'Saved'
                            ? list.filter((stat) => stat.action_names === 'Saved' || stat.action_names === 'Super Saved')
                            : list.filter((stat) => stat.action_names === item.id || stat.action_type_names === item.id);

                    return boxList;
                });
            });
            list.map((item) => {
                const filtered = temp.filter((data) => data === item.action_names);

                if (filtered.length === 0) temp = [...temp, item.action_names];

                return temp;
            });
            boxList[0][0].total += list.filter((item) => item.action_names === 'Shot' && item.action_result_names === 'Goal').length;
            boxList[0][0].data = list.filter((item) => item.action_names === 'Shot' && item.action_result_names === 'Goal');
        }

        setActionList(temp);
    }, [list]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingTop: '0px', paddingBottom: '14px', overflowY: 'auto' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                {/*boxList[0].map((item) => (
                    <Box
                        key={item.id}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            gap: '4px',
                            borderRadius: '16px',
                            border: '1px solid #1A1B1D',
                            background: '#17375E',
                            width: '6.6%',
                            minWidth: '90px',
                            height: '45px',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleDisplayVideo(item)}
                        onContextMenu={handleExportTags(item)}
                    >
                        <p className="normal-text-white">{t(item.title)}</p>
                        <p className="normal-text-white">{item.total}</p>
                    </Box>
                ))*/}
            </Box>
            <TeamStatsVideoPlayer
                t={t}
                open={videoOpen}
                onClose={(flag) => {
                    setVideoOpen(false);

                    if (flag) refreshPage((r) => !r);
                }}
                video_url={games}
                tagList={playData}
            />
            <GameExportToEdits t={t} open={exportOpen} onClose={() => setExportOpen(false)} tagList={playData} isTeams={false} />
        </Box>
    );
};

export default TeamStatsBoxList;
