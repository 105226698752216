import React, { useEffect, useState, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, MenuItem, Typography, Select, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined';
import GameListItem from './gameListItem';
import { MenuProps } from '../components/common';
import SeasonContext from '../../../common/Context';
import { setGamesList } from '../../../helpers/coachSlice';
import { getFilteredArray } from '../../../helpers/coach';
import gameService from '../../../services/game.service';
// import { getComparator, stableSort } from '../components/utilities';
// import GameService from '../../../services/game.service';

const Tabs = ['Processed'];

const Games = ({ t }) => {
    const { seasonFilter } = React.useContext(SeasonContext);

    const [loading, setLoading] = useState(false);
    const [state, setState] = useReducer((old, action) => ({ ...old, ...action }), {
        searchText: '',
        teamFilter: 'none',
        leagueFilter: 'none',
        periodFilter: '0',
        hoverIndex: -1,
        curTab: 0
    });

    const dispatch = useDispatch();
    const standingList = useSelector((state) => state.coach.standingList);
    const gamesList = useSelector((state) => state.coach.gamesList);
    const leagueList = useSelector((state) => state.coach.leagueList);
    const teamsList = useSelector((state) => state.coach.teamsList);

    const { teamFilter, leagueFilter, periodFilter, curTab, hoverIndex } = state;

    const [selectedGames, setSelectedGames] = useState([]);
    const [seasonTeamsList, setSeasonTeamsList] = useState([]);
    const [seasonLeaguesList, setSeasonLeaguesList] = useState([]);

    const handleTabClick = (index) => {
        setState({ ...state, curTab: index });
    };

    const handleChange = (prop) => (e) => {
        setState({ [prop]: e.target.value });
    };

    const handleMouseEnter = (idx) => {
        setState({ ...state, hoverIndex: idx });
    };

    const handleMouseLeave = () => {
        setState({ ...state, hoverIndex: -1 });
    };

    const updateGameList = (item, isEdit) => {
        // Create a new array with updated objects
        gameService.getAllGamesByCoach(null, null, null, null).then((res) => {
            dispatch(setGamesList(res));
        })

        // Dispatch action to update gamesList state
    };

    const getBelongedGameList = (oldList) => {
        const today = new Date();
        let newList = [];

        if (periodFilter === '1') newList = oldList.filter((game) => new Date(game.date) >= new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000) && new Date(game.date) <= today);
        else if (periodFilter === '2') newList = oldList.filter((game) => new Date(game.date) >= new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000) && new Date(game.date) <= today);

        return periodFilter === '0' ? oldList : newList;
    };

    const getAllGamesList = () => {
        const newList = curTab === 0 ? gamesList.filter((game) => game.video_url.toLowerCase() !== 'no video') : gamesList.filter((game) => game.video_url.toLowerCase() === 'no video');

        const filtered_arr = getFilteredArray(getBelongedGameList(newList), seasonFilter, leagueFilter, null, null, null);
        if (teamFilter === 'none') return filtered_arr;
        return filtered_arr.filter((game) => game.home_team_id === teamFilter.team_id || game.away_team_id === teamFilter.team_id);
    };

    useEffect(() => {
        if (gamesList.length) setLoading(false);
    }, [selectedGames]);

    useEffect(() => {
        if (gamesList.length) setSelectedGames(getAllGamesList());
    }, [gamesList, seasonFilter, leagueFilter, teamFilter, periodFilter]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            if (teamsList.length) {
                const filtered_arr = getFilteredArray(teamsList, seasonFilter, leagueFilter, null, null);
                setSeasonTeamsList(filtered_arr);
                setState({ teamFilter: 'none' });
            }
        })();
    }, [seasonFilter, leagueFilter, teamsList]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            if (leagueList.length) {
                const filteredLeagues = leagueList.filter((league) => league.season_id === seasonFilter.id);
                setSeasonLeaguesList(filteredLeagues);
                setState({ leagueFilter: 'none' });
            }
            if (teamsList.length) {
                const filtered_arr = getFilteredArray(teamsList, seasonFilter, null, null, null);
                setSeasonTeamsList(filtered_arr);
                setState({ teamFilter: 'none' });
            }
        })();
    }, [seasonFilter, leagueList]);

    return (
        <Box sx={{ width: '98%', margin: '0 auto' }}>
            {loading && (
                <div style={{ width: '100%', height: '100%', zIndex: 9999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <>
                    <Box sx={{ padding: '24px 24px 24px 24px', display: 'flex', flexDirection: 'column', gap: '16px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                            <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '1.4rem', fontWeight: 700, color: '#1a1b1d' }}>{t('All Games')}</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '0.8rem', fontWeight: 500, color: '#1a1b1d' }}>{t('Period')}</Typography>
                                    <Select
                                        value={periodFilter}
                                        onChange={handleChange('periodFilter')}
                                        label=""
                                        variant="outlined"
                                        IconComponent={ExpandMoreIcon}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        MenuProps={MenuProps}
                                        sx={{ outline: 'none', height: '36px', width: '250px', fontSize: '0.8rem', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                    >
                                        <MenuItem value="0">{t('All')}</MenuItem>
                                        <MenuItem value="1">{t('Last week')}</MenuItem>
                                        <MenuItem value="2">{t('Last month')}</MenuItem>
                                    </Select>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '0.8rem', fontWeight: 500, color: '#1a1b1d' }}>{t('League')}</Typography>
                                    <Select
                                        value={leagueFilter}
                                        onChange={handleChange('leagueFilter')}
                                        label=""
                                        variant="outlined"
                                        IconComponent={ExpandMoreIcon}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        MenuProps={MenuProps}
                                        sx={{ outline: 'none', height: '36px', width: '250px', fontSize: '0.8rem', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                    >
                                        <MenuItem key="0" value="none">
                                            {t('All')}
                                        </MenuItem>
                                        {seasonLeaguesList.map((league, index) => (
                                            <MenuItem key={index + 1} value={league}>
                                                {league.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '0.8rem', fontWeight: 500, color: '#1a1b1d' }}>{t('Team')}</Typography>
                                    <Select
                                        value={teamFilter}
                                        onChange={handleChange('teamFilter')}
                                        label=""
                                        variant="outlined"
                                        IconComponent={ExpandMoreIcon}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        MenuProps={MenuProps}
                                        sx={{ outline: 'none', height: '36px', width: '300px', fontSize: '0.8rem', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                    >
                                        <MenuItem key="0" value="none">
                                            {t('All')}
                                        </MenuItem>
                                        {teamsList &&
                                            seasonTeamsList.map((team, index) => (
                                                <MenuItem key={team.id + '_' + index} value={team}>
                                                    {team.team_name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '18px' }}>
                            {Tabs.map((tab, index) => (
                                <Box
                                    key={index}
                                    onClick={() => handleTabClick(index)}
                                    sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: '4px', width: 'fit-content', cursor: 'pointer' }}
                                >
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '0.9rem', fontWeight: 700, color: '#1a1b1d' }}>{t(tab)}</Typography>
                                    <Box sx={{ width: '100%', height: '2px', backgroundColor: curTab === index ? '#17375E' : '#F8F8F8' }} />
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Box sx={{ overflowY: 'auto', maxHeight: '80vh' }}>
                        <Box sx={{ marginRight: '4px' }}>
                            {selectedGames.map((game, index) => (
                                <Box key={index} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave}>
                                    <GameListItem t={t} row={game} isHover={hoverIndex === index} isPending={curTab === 1} updateList={updateGameList} team={teamFilter} standing={standingList} />
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default Games;
