import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import { Dialog, DialogTitle, DialogContent, Box, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import GameService from '../../../services/game.service';
import { Input, Select, MenuItem, TableRow, FormControlLabel, Switch } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { PLAYER_ICON_DEFAULT } from '../../../common/staticData';
import ForwardIcon from '@mui/icons-material/ForwardTwoTone';

const columns = [
    { id: 'index', label: 'Jersey', minWidth: 30 },
    { id: 'photo', label: 'Photo', minWidth: 40 },
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'start_time', label: 'Start', minWidth: 100 },
    { id: 'end_time', label: 'End', minWidth: 100 },
    { id: 'totaltime', label: 'Total', minWidth: 100 },
    { id: 'startedstatus', label: 'Started', minWidth: 100 },
    { id: 'substituted', label: 'Substituted Player', minWidth: 100 }
];

const GameEditMinutesPage = ({ open, onClose, game, updateGameList, standingList, bTagging = false }) => {
    const [rows, setRows] = useState([]);
    const [editRowId, setEditRowId] = useState(null);
    const [editValues, setEditValues] = useState({});
    const [isFirstTeam, setIsHomeTeam] = useState(true);
    const [filteredTeams, setFilteredTeams] = useState([]);

    const teamsList = useSelector((state) => state.coach.teamsList);
    const playersList = useSelector((state) => state.coach.playersList);
    const [gamePlayers, setGamePlayers] = useState([]);
    const [teamType, setTeamType] = useState('home');
    const [tagTeamsList, setTagTeamsList] = useState([]);



    const loadPlayersMinutes = async () => {
        if (bTagging) {
            var temp_rows = [];
            var tmpList = [];
            await GameService.getTeamById(game.home_team_id).then((res) => {
                tmpList = [res];
            }).catch(() => { });

            await GameService.getTeamById(game.away_team_id).then((res) => {
                tmpList = [...tmpList, res];
            }).catch(() => { });
            setTagTeamsList(tmpList);

            var game_id = game.id;
            await GameService.getGameTeamPlayers({ game_id }).then((res) => {
                console.log(res);
                var tmp_arr = [];
                tmp_arr = res.home_team.map((p) => {
                    return {
                        ...p, player_full_name: p.player_first_name + " " + p.player_last_name, checked: true
                    };
                });
                tmp_arr = [...tmp_arr, ...res.away_team.map((p) => {
                    return {
                        ...p, player_full_name: p.player_first_name + " " + p.player_last_name, checked: true
                    };
                })];
                setGamePlayers(tmp_arr);
            });

            // await GameService.getAllPlayers().then((res) => {
            //     setGamePlayers(res);
            // });
            if (!isFirstTeam) {
                await GameService.getPlayersMinutes(game.id, game.home_team_id).then((res) => {
                    temp_rows = res;
                });

            }
            else {
                await GameService.getPlayersMinutes(game.id, game.away_team_id).then((res) => {
                    // temp_rows = [...temp_rows, ...res];
                    temp_rows = res;
                });
            }
            setRows(temp_rows);
            console.log("row:------------", temp_rows);
            // console.log(rows);

        }
        else {
            await GameService.getPlayersMinutes(game.id, filteredTeams[isFirstTeam ? 0 : 1].team_id).then((res) => {
                setRows(res);
            });

        }

    }


    useEffect(() => {
        // if (bTagging) {
        //     // console.log("filtered: ", tagTeamsList);
        //     // const filtered = tagTeamsList.filter(
        //     //     (item) => item.season_name === game.season_name && item.league_name === game.league_name && (item.team_id === game.home_team_id || item.team_id === game.away_team_id)
        //     // );
        //     // setFilteredTeams(filtered)

        // }
        // else {
        const filtered = teamsList.filter(
            (item) => item.season_name === game.season_name && item.league_name === game.league_name && (item.team_id === game.home_team_id || item.team_id === game.away_team_id)
        );
        setFilteredTeams(filtered)

        // }


    }, [game.away_team_id, game.home_team_id, game.league_name, game.season_name, teamsList, tagTeamsList])

    useEffect(() => {
        if (!open) return;
        (async () => {
            await loadPlayersMinutes()
        })();
    }, [game, open, teamsList, isFirstTeam, bTagging]);

    const update = async (value) => {
        const rowList = rows
        rowList[editRowId] = value
        setRows(() => rowList)
        setEditRowId(null)
        setEditValues({})
        await GameService.editPlayersMinutes(value.id, value)
        await loadPlayersMinutes()
        updateGameList()
    }

    const getPlayer = useCallback(
        (id) => {
            if (bTagging) {
                // return game.players.find((item) => item.player_id == id);

                return gamePlayers.find((item) => item.player_id == id);
            }
            else {
                return playersList.find((item) => item.player_id == id);
            }
        },
        [playersList, gamePlayers]
    );

    const handleEditClick = (rowId) => {
        if (rowId === editRowId) return
        setEditRowId(rowId);
        const row = rows.find((r, idx) => idx === rowId);
        setEditValues({ ...row });
    };

    const handleInputChange = (event, index) => {
        const { name, value } = event.target;
        const started = name === "start_time" && value === 0
        const player_substitution_id = name === "player_substitution_id" && !started ? value : null
        // Update your state with the new input value
        setEditValues((prevValues) => ({
            ...prevValues,
            [name]: value,
            started,
            player_substitution_id
        }));

        if (name === "player_substitution_id") update({
            ...editValues,
            [name]: value,
            started,
            player_substitution_id,
        })
    };

    const handleSwitch = (bChanged) => {
        if (bChanged !== isFirstTeam) {
            setIsHomeTeam(bChanged);
        }

    }

    const handleClose = (event, reason) => {
        console.log("reason", reason)
        if (reason === "escapeKeyDown" && editRowId !== null) {
            setEditRowId(null)
            setEditValues({})
            return
        }
        onClose();
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
            <DialogTitle>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    {filteredTeams.length > 1 && <FormControlLabel
                        sx={{ mt: 1 }}
                        control={
                            <>
                                <Typography component="div" sx={{ flexGrow: 1, fontSize: 20, color: !isFirstTeam ? "black" : "lightgray" }}>{filteredTeams[1].team_name}</Typography>
                                <Switch checked={isFirstTeam} onChange={handleSwitch} inputProps={{ 'aria-label': 'controlled' }} />
                                <Typography component="div" sx={{ flexGrow: 1, fontSize: 20, color: isFirstTeam ? "black" : "lightgray" }}>{filteredTeams[0].team_name}</Typography>
                            </>}
                        label={""}
                    />}
                    {(bTagging && tagTeamsList.length > 1) && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '4px', marginBottom: "8px" }}>
                        <p style={{ color: !isFirstTeam ? 'black' : 'white' }}>{tagTeamsList[0].name}</p>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box
                                sx={{ 'svg path:last-of-type': { fill: 'black' }, 'svg path:first-of-type': { fill: isFirstTeam ? '#ffffff' : '#17375E', opacity: 1 }, cursor: 'pointer' }}
                                onClick={() => handleSwitch(true)}
                            >
                                <ForwardIcon sx={{ transform: 'rotate(180deg)' }} fontSize="large" />
                            </Box>
                            <Box
                                sx={{ 'svg path:last-of-type': { fill: 'black' }, 'svg path:first-of-type': { fill: isFirstTeam ? '#17375E' : '#ffffff', opacity: 1 }, cursor: 'pointer' }}
                                onClick={() => handleSwitch(false)}
                            >
                                <ForwardIcon fontSize="large" />
                            </Box>
                        </Box>
                        <p style={{ color: isFirstTeam ? 'black' : 'white' }}>{tagTeamsList[1].name}</p>
                    </Box>

                    }
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '4px', cursor: 'pointer' }} onClick={onClose}>
                        <CloseIcon sx={{ width: '18px', height: '18px' }} />
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', overflowY: 'unset' }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ overflowY: 'auto' }}>
                        {rows.map((row, index) => (
                            <TableRow hover role="checkbox" tabIndex={-1} key={index} onClick={() => handleEditClick(index)} onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    update(editValues); // Replace updateFunction with the actual function you want to call
                                }
                            }}>
                                <TableCell sx={{ height: 30 }}>
                                    {editRowId === index ? (
                                        <Input sx={{ height: 30 }} name="jersey_number" type="number" value={editValues.jersey_number} onChange={handleInputChange} fullWidth />
                                    ) : (
                                        row.jersey_number
                                    )}
                                </TableCell>
                                <TableCell sx={{ height: 40 }}>
                                    <img
                                        width={40}
                                        src={
                                            getPlayer(row.player_id)?.player_image
                                                ? getPlayer(row.player_id)?.player_image.includes(process.env.REACT_APP_S3_URI)
                                                    ? getPlayer(row.player_id)?.player_image
                                                    : process.env.REACT_APP_S3_URI + getPlayer(row.player_id)?.player_image
                                                : PLAYER_ICON_DEFAULT
                                        }
                                        alt="Player"
                                    />
                                </TableCell>
                                {/* <TableCell sx={{ height: 30 }}>{JSON.stringify(getPlayer(row.player_id))}</TableCell> */}
                                <TableCell sx={{ height: 30 }}>{getPlayer(row.player_id)?.player_full_name} ({getPlayer(row.player_id)?.player_position_short_name})</TableCell>
                                <TableCell sx={{ height: 30 }}>
                                    {editRowId === index ? (
                                        <Input sx={{ height: 30 }} name="start_time" type="number" value={editValues.start_time} onChange={handleInputChange} fullWidth />
                                    ) : (
                                        row.start_time
                                    )}
                                </TableCell>
                                <TableCell sx={{ height: 30 }}>
                                    {editRowId === index ? (
                                        <Input sx={{ height: 30 }} name="end_time" type="number" value={editValues.end_time} onChange={handleInputChange} fullWidth />
                                    ) : (
                                        row.end_time
                                    )}
                                </TableCell>
                                <TableCell sx={{ height: 30 }}>{row.end_time - row.start_time}</TableCell>
                                <TableCell sx={{ height: 30 }}>
                                    <CircleIcon fontSize="small" sx={{ color: row.started ? 'green' : 'red' }} />
                                </TableCell>
                                <TableCell sx={{ height: 30 }}>
                                    {editRowId === index && !editValues.started ? (
                                        <Select sx={{ height: 30 }} name="player_substitution_id" value={editValues.player_substitution_id} onChange={handleInputChange} fullWidth>
                                            {
                                                row.player_substitution_id &&
                                                <MenuItem value={row.player_substitution_id}>{getPlayer(row.player_substitution_id)?.player_full_name} #{getPlayer(row.player_substitution_id)?.player_jersey_number}</MenuItem>
                                            }
                                            {rows.filter(row => !rows.map(r => r.player_substitution_id).includes(row.player_id) && row.player_id !== editValues.player_id).map((row, index) => (
                                                <MenuItem value={row.player_id}>{getPlayer(row.player_id)?.player_full_name} #{getPlayer(row.player_id)?.player_jersey_number}</MenuItem>
                                            ))}
                                        </Select>
                                    ) : (
                                        row.player_substitution_id ? `${getPlayer(row.player_substitution_id)?.player_full_name}  #${getPlayer(row.player_substitution_id)?.player_jersey_number} (${getPlayer(row.player_substitution_id)?.player_position_short_name})` : ""
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
        </Dialog >
    );
};

export default GameEditMinutesPage;
