import React, { useEffect, useState } from 'react';
import './PlayerDetails.css';
import { Link } from 'react-router-dom';
import GameService from '../../services/game.service';
import { CircularProgress, Grid } from '@mui/material';
import './PlayerDetails.css';
import './responsive.css';
import './lightslider.css';
import { format, getWeek } from 'date-fns';
import { AreaChart, XAxis, YAxis, ResponsiveContainer, Area } from 'recharts';
import PlayerStatsChart from './statChart'
import { getSeriesData } from '../../common/utilities';

const CustomizedLabel = ({ x, y, stroke, value }) => {
    if (Number(value) === 0) return null;
    return (
        <text x={x} y={y} dy={-4} fill={'white'} fontSize={13} textAnchor="middle">
            {value}
        </text>
    );
};
const SkillData = [
    { id: 'total_air_challenge', title: 'Air Challenges' },
    { id: 'total_blocked', title: 'Blocked' },
    { id: 'total_clearance', title: 'Clearance' },
    { id: 'total_corner', title: 'Corners' },
    { id: 'total_crosses', title: 'Crosses' },
    { id: 'total_draw_fouls', title: 'Draw Fouls' },
    { id: 'total_dribble', title: 'Dribbles' },
    { id: 'total_dribble_successful', title: 'Successful Dribbles' },
    { id: 'total_fouls', title: 'Fouls' },
    { id: 'total_free_kick', title: 'Free Kicks' },
    // { id: 'total_game', title: 'Games' },
    { id: 'total_goal', title: 'Goals' },
    { id: 'total_goal_received', title: 'Goals Received' },
    // { id: 'total_goalkeeper_exits', title: 'Goalkeeper Exits' },
    { id: 'total_ground_challenge', title: 'Ground Challenges' },
    { id: 'total_interception', title: 'Interceptions' },
    { id: 'total_interception_on_defensive_court', title: 'Interceptions on Defensive Court' },
    { id: 'total_interception_on_offensive_court', title: 'Interceptions on Offensive Court' },
    { id: 'total_key_passes', title: 'Key Passes' },
    { id: 'total_key_passes_successful', title: 'Successful Key Passes' },
    { id: 'total_long_passes', title: 'Long Passes' },
    { id: 'total_offside', title: 'Offsides' },
    { id: 'total_one_vs_one', title: '1v1 Challenges' },
    { id: 'total_own_goals', title: 'Own Goals' },
    { id: 'total_passes', title: 'Passes' },
    { id: 'total_passes_for_goals', title: 'Passes for Goals' },
    { id: 'total_passes_for_shots', title: 'Passes for Shots' },
    { id: 'total_penalties', title: 'Penalties' },
    { id: 'total_penalties_missed', title: 'Missed Penalties' },
    { id: 'total_red_cards', title: 'Red Cards' },
    { id: 'total_saved', title: 'Saves' },
    { id: 'total_short_passes', title: 'Short Passes' },
    { id: 'total_shot', title: 'Shots' },
    { id: 'total_shot_off_target', title: 'Shots Off Target' },
    { id: 'total_shot_on_box', title: 'Shots On Box' },
    { id: 'total_shot_on_target', title: 'Shots On Target' },
    { id: 'total_shot_out_of_box', title: 'Long Shots' },
    { id: 'total_successful_passes', title: 'Successful Passes' },
    { id: 'total_super_save', title: 'Super Saves' },
    { id: 'total_tackle', title: 'Tackles' },
    { id: 'total_tackle_on_defensive_court', title: 'Tackles on Defensive Court' },
    { id: 'total_tackle_on_offensive_court', title: 'Tackles on Offensive Court' },
    { id: 'total_through_passes', title: 'Through Passes' },
    { id: 'total_through_passes_successful', title: 'Successful Through Passes' },
    { id: 'total_turnover', title: 'Turnovers' },
    { id: 'total_turnover_on_defensive_court', title: 'Turnovers on Defensive Court' },
    { id: 'total_turnover_on_offensive_court', title: 'Turnovers on Offensive Court' },
    // { id: 'total_yellow_cards', title: 'Yellow Cards' }
];

const styles = {
    loader: {
        position: 'fixed',
        left: '0px',
        top: '0px',
        width: '100%',
        height: '100%',
        backgroundColor: '#fff',
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    play: {
        color: '#07863d'
    },
    paper: { minWidth: '98%', maxHeight: 'none', backgroundColor: 'transparent' }
};
const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="end" fill="#fff" transform="rotate(-35)">
                {payload.value}
            </text>
        </g>
    );
};

const PlayerSkills = ({ player, gameList, onMenuChange, seasonFilter, playVideoTag, t }) => {
    const [loading, setLoading] = useState(true);
    const [gameIds, setGameIds] = useState(gameList.map((game) => game.game_id));
    const [playerState, setPlayerState] = useState(null);
    const [datas, setDatas] = useState([]);

    const [avgSkill, setAvgSkill] = useState([]);

    useEffect(() => {
        setGameIds(gameList.map((game) => game.game_id))
        setLoading(true);
        getData();
        setLoading(false);
    }, [seasonFilter]);


    const getData = () => {
        GameService.getPlayersStatsAdvanceSummary({
            seasonId: seasonFilter === 'none' ? null : seasonFilter.id,
            leagueId: null,
            gameId: gameList.length > 0 ? gameList.map((game) => game.game_id).join(',') : null,
            teamId: null,
            playerId: player.player_id,
            gameTime: null,
            courtAreaId: null,
            insidePaint: null,
            homeAway: null,
            gameResult: null
        }).then((res) => {
            setPlayerState(res[0]);
        });
    };
    const switchTo = () => {
        onMenuChange('details');
    };

    useEffect(() => {
        setLoading(true);
        if (gameList.length > 0) {
            GameService.getGamePlayerTags(
                2,
                null,
                player.player_id,
                gameIds.join(','),
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
            ).then((res) => {
                setDatas(getSeriesData(res));
                setLoading(false);
            });
        }

        GameService.getPlayersStatsGamebyGameStandAlone({
            seasonId: seasonFilter.id,
            leagueId: null,
            gameId: gameIds.length === 0 ? null : gameIds.join(','),
            teamId: null,
            playerId: player.player_id,
            gameTime: null,
            courtAreaId: null,
            insidePaint: null,
            homeAway: null,
            gameResult: null
        })
            .then((res) => {
                // Initialize an object to hold the sum and count of scores for each month and score type
                const scoresByMonth = {};
                // Iterate over the array to populate scoresByMonth
                res.forEach((item) => {
                    const length = res.length;
                    let gameDate = '';
                    if (length >= 6 && length <= 8) {
                        // Format as the start of the week
                        gameDate = `Week of ${getWeek(new Date(item.game_date))}`;
                    } else if (length > 8) {
                        // Format as month and year
                        gameDate = format(new Date(item.game_date), 'MMM yyyy');
                    } else {
                        gameDate = format(new Date(item.game_date), 'dd-MM-yyyy');
                    }
                    if (!scoresByMonth[gameDate]) {
                        scoresByMonth[gameDate] = {};
                    }
                    // For each score type (e.g., score, math_score, physics_score), update sum and count
                    Object.keys(item).forEach((key) => {
                        if (!key.startsWith('total_')) return;
                        if (!scoresByMonth[gameDate][key]) {
                            scoresByMonth[gameDate][key] = { sum: 0, count: 0, avg: 0 };
                        }
                        if (item[key] !== undefined) {
                            scoresByMonth[gameDate][key].sum += item[key];
                            scoresByMonth[gameDate][key].count += 1;
                            // scoresByMonth[gameDate][key].avg = (scoresByMonth[gameDate][key].sum / scoresByMonth[gameDate][key].count).toFixed(2);
                            scoresByMonth[gameDate][key].avg = scoresByMonth[gameDate][key].sum
                        }
                    });
                });
                // Convert scoresByMonth into the desired format with averages
                const new_array = Object.keys(scoresByMonth).map((date, index) => {
                    const monthData = { game_date: date };
                    Object.keys(scoresByMonth[date]).forEach((key) => {
                        // Here, you could directly assign the average or any other property
                        monthData[key] = scoresByMonth[date][key]?.avg;
                    });
                    return monthData;
                });
                setAvgSkill(new_array);
            })
            .catch((error) => {
                console.error('An error occurred:', error);
            });
        setLoading(false);
    }, [gameIds]);

    const fixedToOne = (num) => {
        // return '100.0%';
        num = parseFloat(num)
        return num > 0 ? `${num.toFixed(1)}%` : ''
    }



    const getList = (series) => {

        if (series) {
            const llst = []
            const slst = []
            const clst = []
            series.forEach(element => {
                if (element?.data > 0) {
                    llst.push(element.name)
                    slst.push(element.data)
                    clst.push(element.color)
                }
            });
            return { seriesDonut: slst, labelsLst: llst, colorLst: clst }
        }
    }

    function getPeriod(id) {
        return id === 1 ? 'H1' : id === 2 ? 'H2' : 'OT';
    }

    const handleClick = (data) => {
        try {
            console.log('handleClick', data);
            const newData = data.map((item) => ({
                tag_id: item.id,
                start_time: item.player_tag_start_time,
                end_time: item.player_tag_end_time,
                player_name: item.player_names,
                action_name: item.action_names,
                action_type: item.action_type_names,
                action_result: item.action_result_names,
                game_id: item.game_id,
                team_id: player.team_id,
                court_area: item.court_area_id,
                inside_pain: item.inside_the_pain,
                period: getPeriod(item.period),
                time: item.time_in_game,
                home_team_image: item.home_team_logo,
                away_team_image: item.away_team_logo,
                home_team_goals: item.home_team_goal,
                away_team_goals: item.away_team_goal
            }));
            if (newData.length > 0) {
                playVideoTag({ game: gameList[0], tagList: newData })
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <section className="container">
            {loading && (
                <div style={styles.loader}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <div className="player-row-outer">
                    <div className="back-button w-100">
                        <h3>Skills</h3>
                        <Link onClick={() => switchTo()}>
                            <span className="bi bi-arrow-left-circle"></span> Back
                        </Link>
                    </div>
                    <div className="skill-row-outer">
                        <Grid container spacing={2}>
                            {datas.map((stat, index) => (
                                stat.total > 0 && (
                                    <Grid item xs={12} sm={6} md={4}>
                                        <PlayerStatsChart
                                            className="mb-3"
                                            title={stat.title}
                                            seriesBar={stat.seriesBar}
                                            seriesDonut={getList(stat.seriesBar)}
                                            total={stat.total}
                                            tags={stat.tags}
                                            handleClick={handleClick}
                                            key={index}
                                            t={t}
                                        />
                                    </Grid>
                                )
                            ))}
                            {!!playerState && <Grid item xs={12} sm={6} md={4} >
                                <div className="custom-column player-profile" style={{ height: '100%' }}>
                                    <div className="match-period">
                                        <h3>Court Activity</h3>
                                    </div>

                                    <div className="ground-option" style={{ margin: "auto", height: '229px' }} >
                                        <span style={{ position: "absolute", bottom: "43%", left: '2%', fontSize: 23 }}>{fixedToOne(playerState?.percentage_action_in_defensive_box_area)}</span>
                                        <input type="checkbox" className="input-hide-checkbox" id="gr01" />
                                        <span style={{ position: "absolute", bottom: '4%', left: '4%', fontSize: 23 }}>{fixedToOne(playerState?.percentage_action_area_4)}</span>
                                        <label className="ground-label-skill" htmlFor="gr01"></label>
                                        <input type="checkbox" className="input-hide-checkbox" id="gr02" />
                                        <span style={{ position: "absolute", bottom: '4%', left: '28%', fontSize: 23 }}>{fixedToOne(playerState?.percentage_action_area_3)}</span>
                                        <label className="ground-label-skill" htmlFor="gr02"></label>
                                        <input type="checkbox" className="input-hide-checkbox" id="gr03" />
                                        <span style={{ position: "absolute", bottom: '4%', right: '28%', fontSize: 23 }}>{fixedToOne(playerState?.percentage_action_area_2)}</span>
                                        <label className="ground-label-skill" htmlFor="gr03"></label>
                                        <input type="checkbox" className="input-hide-checkbox" id="gr04" />
                                        <span style={{ position: "absolute", bottom: "4%", right: '4%', fontSize: 23 }}>{fixedToOne(playerState?.percentage_action_area_1)}</span>
                                        <label className="ground-label-skill" htmlFor="gr04"></label>
                                        <span style={{ position: "absolute", bottom: "43%", right: '2%', fontSize: 23 }}>{fixedToOne(playerState?.percentage_action_in_offensive_box_area)}</span>
                                    </div>
                                </div>
                            </Grid>}
                        </Grid>
                    </div>

                    <Grid container spacing={2}>
                        {SkillData.map((data, index) => {
                            if (avgSkill.find(item => item[data.id]) === undefined) return null;
                            return (
                                <Grid item xs={12} sm={6} md={4} key={data.id}>
                                    <div className="custom-column player-profile">
                                        <div className="match-period">
                                            <h3>{data.title}</h3>
                                        </div>
                                        <ResponsiveContainer width="95%" height={240}>
                                            <AreaChart
                                                data={avgSkill}
                                                margin={{
                                                    top: 5,
                                                    right: 10,
                                                    left: -10,
                                                    bottom: 0
                                                }}
                                            >
                                                <defs>
                                                    <linearGradient id={`colorArea`} x1="0" y1="0" x2="0" y2="1">
                                                        <stop offset="0%" stopColor="#0685e4" stopOpacity={0.8} />
                                                        <stop offset="100%" stopColor="#00e396" stopOpacity={0} />
                                                    </linearGradient>
                                                </defs>
                                                <XAxis dataKey={'game_date'} height={60} tick={<CustomizedAxisTick />} interval={0} />
                                                <YAxis tick={{ stroke: 'white' }} allowDecimals={false} domain={[0, dataMax => (dataMax * 1.5).toFixed(0)]} />
                                                <Area type="monotone" dataKey={data.id} stroke="#0685e4" fill={`url(#colorArea)`} strokeWidth={2} label={<CustomizedLabel />} />
                                            </AreaChart>
                                        </ResponsiveContainer>
                                    </div>
                                </Grid>
                            )
                        })}
                    </Grid>
                </div>
            )}
        </section>
    );
};

export default PlayerSkills;
