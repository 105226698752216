import React from 'react';
import { Box, Typography } from '@mui/material';

import EditIcon from '../../../../../assets/editicon_white.png';

import { PLAYER_ICON_DEFAULT } from '../../../../../common/staticData';

const TeamPlayerLogo = ({ player, onShow }) => {
    const getImage = () => {
        return player.player_image && player.player_image.length > 0 ? player.player_image : PLAYER_ICON_DEFAULT;
    };

    const getDisplayName = () => {
        if (player.player_first_name === null && player.player_last_name !== null) return player.player_last_name;
        else if (player.player_first_name !== null && player.player_last_name === null) return player.player_first_name;
        else if (player.player_first_name === null && player.player_last_name === null) return 'No Name';

        let first = player.player_first_name.length > 0 ? player.player_first_name.slice(0, 1) : '';
        let last = player.player_last_name.length > 0 ? player.player_last_name.slice(0, 1) : '';

        first = first === ' ' ? player.player_first_name.slice(1, 2) : first;
        last = last === ' ' ? player.player_last_name.slice(1, 2) : last;

        return player.player_last_name.length >= 13 ? `${first}. ${last}.` : `${first}. ${player.player_last_name}`;
    };

    return (
        <Box sx={{alignItems: 'center',  width: '95px', height: '110px', borderRadius: '8px', background: `url(${getImage()}) center center / cover no-repeat silver`, position: 'relative' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: player.player_jersey_number >= 999 ? 'flex-end' : 'space-between', width: '100%' }}>
                {player.player_jersey_number < 999 && (
                    <Box sx={{ borderRadius: '8px 0', width: '18px', height: '18px', background: '#5C738E', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '12px', fontWeight: 600, color: '#ffffff' }}>{player.player_jersey_number}</Typography>
                    </Box>
                )}
                {player.player_position_short_name && (  
                    <Box  
                        className="px-1"  
                        sx={{  
                            cursor: 'pointer',  
                            background: '#5C738E',  
                            borderRadius: '4px 4px',  
                            display: 'flex',  
                            alignItems: 'center',  
                            justifyContent: 'center',  
                            width: 'auto',  
                            marginTop: '-15px',
                            height: '18px',  
                        }}  
                    >  
                        <Typography  
                            sx={{  
                                fontFamily: "'DM Sans', sans-serif",  
                                fontSize: '14px',  
                                fontWeight: 600,  
                                color: '#ffffff',  
                            }}  
                        >  
                            {player.player_position_short_name}  
                        </Typography>  
                    </Box>  
                )}  

                <Box
                    sx={{ cursor: 'pointer', background: '#5C738E', borderRadius: '0 8px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '18px', height: '18px' }}
                    onClick={() => onShow(player)}
                >
                    <img src={EditIcon} style={{ height: '14px', position: 'absolute' }} />
                </Box>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    height: '20px',
                    backgroundColor: '#ececec',
                    borderRadius: '0 0 8px 8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bottom: 0,
                    position: 'absolute'
                }}
            >
                <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '0.6rem', fontWeight: 700, color: '#1a1b1d' }}>{getDisplayName()}</Typography>
            </Box>
        </Box>
    );
};

export default TeamPlayerLogo;
