import GameService from '../services/game.service';

// Action Types
export const SET_USER_SUBSCRIPTION = 'SET_USER_SUBSCRIPTION';

// Action Creator to set user subscription
export const setUserSubscription = (subscription) => ({
    type: SET_USER_SUBSCRIPTION,
    payload: subscription,
});

// Thunk to fetch and dispatch subscription data
export const fetchUserSubscription = (userId) => async (dispatch) => {
    try {
        const userSubscriptions = await GameService.getAllUserSubscriptions();
        const subscriptions = await GameService.getAllSubscriptions();

        // Find the current user's subscription
        const userSubscriptionData = userSubscriptions.find((sub) => sub.user_id === userId);

        if (userSubscriptionData) {
            const subscriptionDetail = subscriptions.find((sub) => sub.id === userSubscriptionData.subscription_id);

            if (subscriptionDetail) {
                const endDate = new Date(userSubscriptionData.end_date);
                const now = new Date();

                // Check if the subscription is still valid
                if (endDate > now) {
                    const validSubscription = {
                        id: userSubscriptionData.id,
                        name: subscriptionDetail.name,
                        start: userSubscriptionData.start_date,
                        end: userSubscriptionData.end_date,
                    };
                    // Dispatch the subscription to the Redux store
                    dispatch(setUserSubscription(validSubscription));
                } else {
                    // Subscription expired
                    dispatch(setUserSubscription(null));
                }
            }
        }
    } catch (error) {
        console.error('Error fetching subscription:', error);
        dispatch(setUserSubscription(null)); // In case of error, set subscription to null
    }
};
