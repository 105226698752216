import { Button } from '@mui/material';
import React, { useState } from 'react';

import AddIcon from '@mui/icons-material/AddOutlined';

import HideTeamControl from './teamControl';
import AddHideGameDialog from './addHideGame';
import HideGameControl from './gameControl';

const AdminHideGames = ({ t }) => {
    const [addOpen, setAddOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState(null);

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '48px' }}>
                <Button variant="outlined" startIcon={<AddIcon />} onClick={() => setAddOpen(true)}>
                    Add Games
                </Button>
                <HideTeamControl refresh={refresh} setTeam={setSelectedTeam} />
                <HideGameControl team={selectedTeam} reload={setRefresh} />
                <AddHideGameDialog open={addOpen} onClose={() => setAddOpen(false)} setRefresh={setRefresh} />
            </div>
        </div>
    );
};

export default AdminHideGames;
