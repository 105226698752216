import React, { useState } from "react";
import "./PlayerDetails.css";
import "./responsive.css";
import "./lightslider.css";
import {  TEAM_ICON_DEFAULT } from '../../common/staticData';
import { format } from "date-fns"
import { Link } from "react-router-dom"

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const responsive = {
  superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
  },
  desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
  },
  tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
  },
  mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
  }
};


const GameDetails = ({gameList ,onMenuUpdate ,playVideoClick}) => {
    const games = gameList;
    const sz=getSizeByWidth()
    const g2 = makeGroups(games,sz)
    return (
      <div className="seeMoreEffect" style={{ marginTop: "20px" }}>
        <Carousel 
          className='owl-theme game-details-row' 
          swipeable={false}
          draggable={true}
          showDots={false}
          infinite={g2.length > 1}
          responsive={responsive}
        >
           {g2.map((games, idx) => (  
          <div className="game-details-row" key={idx}>
            {games.map((game, index) => (
              <div className="game-col" key={index}>
                <h3>{game.league_name}<span>{format(new Date(game.game_date), "dd/MM/yyyy")}</span></h3>
                <div className="gameData">
                    <div className="team-logo" >
                      <img src={game.home_team_image ? game.home_team_image.includes(process.env.REACT_APP_S3_URI) ? game.home_team_image : process.env.REACT_APP_S3_URI + game.home_team_image : TEAM_ICON_DEFAULT} alt=""/>
                      <span className="team-name">{game.home_team_name}</span>
                      <span className="team-score">{game.home_team_goal}</span>
                    </div>
                    <div className="team-logo" >
                      <img src={game.away_team_image ? game.away_team_image.includes(process.env.REACT_APP_S3_URI) ? game.away_team_image : process.env.REACT_APP_S3_URI + game.away_team_image : TEAM_ICON_DEFAULT} alt=""/>
                      <span className="team-name">{game.away_team_name}</span>
                      <span className="team-score">{game.away_team_goal}</span>
                    </div>
                </div>
                <div className="stats-row">
                  <Link title="Play Video"  onClick={() => playVideoClick(game)}  ><i className="bi bi-play-circle"></i> Game Video</Link>
                  <Link title="Stats" onClick={() => onMenuUpdate(game)}><i className="bi bi-graph-up-arrow"></i> Game Stats</Link> 
                </div>
              </div>
            ))}
          </div>
         ))} 
        </Carousel>
      </div>
    );
  };
  const makeGroups=(games,size)=>{
    const slides = [];
    var slides2 = [];
    for (let i = 0; i < games.length; i ++) {
      slides2.push(games[i])
      if((i+1) % size == 0){
        slides.push(slides2)
        slides2 = []
      }else if(i == (games.length -1) ){
        slides.push(slides2)
      }
    }
    return slides
  }

  const getSizeByWidth=()=>{
    const windowWidth = window.innerWidth;
    if(windowWidth >= 900){
        return 6;
    }else{
        return 4;
    }
  }
  
  export default GameDetails ;