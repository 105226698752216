import React, { useState, useEffect } from 'react';
import { Box, MenuItem, Typography, Select, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined';
import { MenuProps, SaveButton } from '../components/common';
import GameService from '../../../services/game.service';
import { getComparator, stableSort } from '../components/utilities';
import Checkbox from '@mui/material/Checkbox';
import { downloadJsonFile, getFormattedDate } from '../components/utilities';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
// import XLSX from 'xlsx';
import * as XLSX from 'xlsx';

const ReportPossession = ({ t, seasonFilter }) => {
    const [values, setValues] = useState({
        loading: false,
        hoverIndex: -1,
        curTab: 0,
        teamFilter: 'none',
        exportFilter: -1,
        destFilter: -1,
        gameFilter: 'none',
        teamList: [],
        gamesList: [],
        offensiveFilter: 'none',
        offensiveList: [],
        videoUrl: '',
        teamIds: [],
        startActionFilter: 'none',
        startActionList: [],
        endActionFilter: 'none',
        endActionList: []
    });
    const [standingList, setStandingList] = useState([]);
    const exportList = ['Export to Excel', 'Export to JSON'];
    const destList = ['Download to PC', 'Upload to Gooogle drive'];

    const getUniqueValuesForKey = (dataArray, key) => {
        // Map the values of the key from each object, then create a Set to remove duplicates
        const uniqueValues = new Set(dataArray.map((item) => item[key]));
        // Convert the Set back to an array
        return Array.from(uniqueValues);
    };

    const handleChange = (prop) => (e) => {
        if (prop === 'gameFilter') {
            const game = e.target.value;

            GameService.getGamePosessions(game.id).then((res) => {
                const arr_1 = getUniqueValuesForKey(res, 'started_action_name');
                const arr_2 = getUniqueValuesForKey(res, 'ended_action_name');
                setValues({
                    ...values,
                    [prop]: e.target.value,
                    videoUrl: game.video_url,
                    offensiveList: [game.away_team_name, game.home_team_name],
                    teamIds: [game.away_team_id, game.home_team_id],
                    startActionList: arr_1,
                    endActionList: arr_2,
                    offensiveFilter: 'none'
                });
            });
        } else {
            setValues({ ...values, [prop]: e.target.value });
        }
    };

    const getDescGamesList = (array) => {
        let descList = array.length > 0 ? stableSort(array, getComparator('desc', 'date')) : [];
        return descList;
    };

    const getTeamList = (array) => {
        if (array.length > 0) {
            let result = [];
            let filtered_result = [];

            if (seasonFilter !== 'none') filtered_result = array.filter((game) => game.season_name === seasonFilter.name);
            else filtered_result = array;

            filtered_result.map((item) => {
                const filter = result.filter((team) => team === item.team_name);
                // const filter1 = result.filter((team) => team === item.away_team_name);

                if (filter.length === 0) result = [...result, item.team_name];

                // if (filter1.length === 0) result = [...result, item.away_team_name];

                return result;
            });

            return result;
        }

        return [];
    };

    const getFilteredGamesList = (newList) => {
        let array = [];
        if (seasonFilter !== 'none' && values.teamFilter === 'none')
            array = newList.filter((game) => game.season_name === seasonFilter.name && (values.teamList.includes(game.home_team_name) || values.teamList.includes(game.away_team_name)));
        else if (values.teamFilter !== 'none' && seasonFilter === 'none') array = newList.filter((game) => game.home_team_name === values.teamFilter || game.away_team_name === values.teamFilter);
        else if (seasonFilter !== 'none' && values.teamFilter !== 'none')
            array = newList.filter((game) => game.season_name === seasonFilter.name && (game.home_team_name === values.teamFilter || game.away_team_name === values.teamFilter));

        return seasonFilter === 'none' && values.teamFilter === 'none' ? newList : array;
    };

    // const getBelongedGameList = (oldList) => {
    //     const today = new Date();
    //     let newList = [];

    //     if (values.periodFilter === '1') newList = oldList.filter((game) => new Date(game.date) >= new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000) && new Date(game.date) <= today);
    //     else if (values.periodFilter === '2') newList = oldList.filter((game) => new Date(game.date) >= new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000) && new Date(game.date) <= today);

    //     return values.periodFilter === '0' ? oldList : newList;
    // };

    const getGamesList = () => {
        const newList = values.gamesList.filter((game) => game.video_url.toLowerCase() !== 'no video');
        // const gameList = getFilteredGamesList(getBelongedGameList(newList));
        const gameList = getFilteredGamesList(newList);
        return gameList;
    };

    useEffect(() => {
        setValues({ ...values, loading: true });

        let seasonId = seasonFilter == 'none' ? null : seasonFilter.id;

        Promise.all([GameService.getTeamInitialStanding(), GameService.getAllGamesByCoach(seasonId, null, null, null), GameService.getAllMyCoachTeam()]).then(([standing, games, teams]) => {
            if (teams.length > 0) {
                setValues({
                    ...values,
                    standingList: standing,
                    gamesList: getDescGamesList(games),
                    teamList: getTeamList(teams),
                    loading: false
                });
            } else {
                setValues({ ...values, loading: false });
            }
        });
    }, [seasonFilter]);

    const getAllInfosByGame = async (game) => {
        let team_id = null;

        // setValues({ ...values, loading: true });

        if (values.teamFilter === 'none') team_id = game.home_team_id;
        else team_id = game.home_team_name === values.teamFilter ? game.home_team_id : game.away_team_id;

        let playerTagList = [];
        if (team_id !== null) {
            await GameService.getAllPlayerTagsByTeam(parseInt(team_id), parseInt(game.id)).then((res) => {
                playerTagList = res;
            });
        }

        // setValues({ ...values, loading: false });
        return playerTagList;
    };

    const exportGames = async () => {
        setValues({ ...values, loading: true });
        if (values.exportFilter === 0) {
            // export to excel
            const zip = new JSZip();

            const game = values.gameFilter;
            const playerTagList = await getAllInfosByGame(game);
            const ws = XLSX.utils.json_to_sheet(playerTagList);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
            zip.file(`${game.id}.xlsx`, excelBuffer, { binary: true });

            zip.generateAsync({ type: 'blob' }).then(function (content) {
                saveAs(content, 'games_excel.zip');
            });
        } else if (values.exportFilter === 1) {
            // export to JSON
            const zip = new JSZip();
            const game = values.gameFilter;
            const playerTagList = await getAllInfosByGame(game);
            zip.file(`${game.id}.json`, JSON.stringify(playerTagList));
            zip.generateAsync({ type: 'blob' }).then(function (content) {
                saveAs(content, 'games_JSON.zip');
            });
        }
        setValues({ ...values, loading: false });
    };

    return (
        <Box sx={{ width: '98%', margin: '0 auto' }}>
            {values.loading && (
                <div style={{ width: '100%', height: '100%', zIndex: 9999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </div>
            )}
            {!values.loading && (
                <>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '24px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '0.8rem', fontWeight: 500, color: '#1a1b1d' }}>{t('Team')}</Typography>
                            <Select
                                value={values.teamFilter}
                                onChange={handleChange('teamFilter')}
                                label=""
                                variant="outlined"
                                IconComponent={ExpandMoreIcon}
                                inputProps={{ 'aria-label': 'Without label' }}
                                MenuProps={MenuProps}
                                sx={{ outline: 'none', height: '36px', width: '240px', fontSize: '0.8rem', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                            >
                                <MenuItem key="0" value="none">
                                    {t('All')}
                                </MenuItem>
                                {values.teamList.map((team, index) => (
                                    <MenuItem key={index + 1} value={team}>
                                        {team}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '0.8rem', fontWeight: 500, color: '#1a1b1d' }}>{t('Game')}</Typography>
                            <Select
                                value={values.gameFilter}
                                onChange={handleChange('gameFilter')}
                                label=""
                                variant="outlined"
                                IconComponent={ExpandMoreIcon}
                                inputProps={{ 'aria-label': 'Without label' }}
                                MenuProps={MenuProps}
                                sx={{ outline: 'none', height: '36px', width: '320px', fontSize: '0.8rem', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                            >
                                {getGamesList().map((game, index) => (
                                    <MenuItem key={index + 1} value={game} sx={{ fontSize: '11px' }}>
                                        {`${getFormattedDate(game.date)} ${game.home_team_name} ${game.home_team_goals} : ${game.away_team_goals} ${game.away_team_name}`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '0.8rem', fontWeight: 500, color: '#1a1b1d' }}>{t('Offensive')}</Typography>
                            <Select
                                value={values.offensiveFilter}
                                onChange={handleChange('offensiveFilter')}
                                label=""
                                variant="outlined"
                                IconComponent={ExpandMoreIcon}
                                inputProps={{ 'aria-label': 'Without label' }}
                                MenuProps={MenuProps}
                                sx={{ outline: 'none', height: '36px', width: '240px', fontSize: '0.8rem', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                            >
                                {values.offensiveList.map((team, index) => (
                                    <MenuItem key={index} value={index}>
                                        {team}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '0.8rem', fontWeight: 500, color: '#1a1b1d' }}>{t('StartedFrom')}</Typography>
                            <Select
                                value={values.startActionFilter}
                                onChange={handleChange('startActionFilter')}
                                label=""
                                variant="outlined"
                                IconComponent={ExpandMoreIcon}
                                inputProps={{ 'aria-label': 'Without label' }}
                                MenuProps={MenuProps}
                                sx={{ outline: 'none', height: '36px', width: '240px', fontSize: '0.8rem', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                            >
                                {values.startActionList.map((action, index) => (
                                    <MenuItem key={index} value={index}>
                                        {action}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '0.8rem', fontWeight: 500, color: '#1a1b1d' }}>{t('EndedResult')}</Typography>
                            <Select
                                value={values.endActionFilter}
                                onChange={handleChange('endActionFilter')}
                                label=""
                                variant="outlined"
                                IconComponent={ExpandMoreIcon}
                                inputProps={{ 'aria-label': 'Without label' }}
                                MenuProps={MenuProps}
                                sx={{ outline: 'none', height: '36px', width: '240px', fontSize: '0.8rem', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                            >
                                {values.endActionList.map((action, index) => (
                                    <MenuItem key={index} value={index}>
                                        {action}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '250px' }}>
                        <SaveButton
                            // disabled={!(values.gameFilter.length > 0 && values.exportFilter >= 0 && values.destFilter >= 0)}
                            disabled={!(values.gameFilter != 'none' && values.exportFilter >= 0)}
                            onClick={exportGames}
                            sx={{ width: '200px', fontSize: '0.7rem' }}
                        >
                            {t('Run')}
                        </SaveButton>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default ReportPossession;
