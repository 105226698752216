import axios from 'axios';
import { useState, useEffect } from 'react';
import cookie from 'react-cookies';
import * as settings from '../config/settings';

const API_URL = `${settings.APIBASEURL}`;

export default function useDeviceToken() {
    const [deviceToken, setDeviceToken] = useState('');

    useEffect(() => {
        (async () => {
            const setCookie = async () => {
                try {
                    const res = await axios.get(API_URL + '/set-cookie', {
                        withCredentials: false // Include credentials (cookies) in the request
                    });

                    setDeviceToken(cookie.load('deviceToken'));
                } catch (error) {}
            };
            const getCookie = async () => {
                axios
                    .get(`${API_URL}/get-cookie`, { withCredentials: true })
                    .then((res) => {
                        if (res && res.data !== '') {
                            setDeviceToken(res.data);
                            return true;
                        } else {
                            throw new Error('deviceToken not found');
                        }
                    })
                    .catch((error) => {
                        console.error('Error fetching cookie:', error);
                        return false;
                    });
            };
            const isSetToken = await getCookie().then((res) => {
                if (!res) {
                    getCookie();
                }
            });
        })();
    }, []);

    return deviceToken;
}
