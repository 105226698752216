import React, { useEffect } from 'react';  
import { Toast } from 'react-bootstrap';  
  
const Alert = ({text}) => {  
  
    // useEffect(() => {  
    //     if (showAlert) {  
    //         const timer = setTimeout(() => {  
    //             handleAlert(false);  
    //         }, 3000);  
    //         return () => clearTimeout(timer);  
    //     }  
    // }, [showAlert, handleAlert]);  
  
    return (  
      <Toast   
          show={true}   
          style={{   
              position: 'absolute',   
              top: 20,   
              right: 0,   
              width: 300,   
              color: '#28a745', // Changed the color to black for better visibility  
              backgroundColor: '#d4edda', // Lighter green color  
              fontSize: '1.25rem', // Increased the font size  
          }}  
      >    
          <Toast.Body>{text}</Toast.Body>    
      </Toast>  
    );  
}  
  
export default Alert;  
