import { CircularProgress, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { TEAM_ICON_DEFAULT } from '../../../common/staticData';
import GameImage from '../../../assets/game_image.jpg';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import DeleteIcon from '@mui/icons-material/Delete';

import GameService from '../../../services/game.service';

const HideGameControl = ({ team, reload }) => {
    const [loading, setLoading] = useState(false);
    const [gameList, setGameList] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [selectedGame, setSelectedGame] = useState(null);
    const [refresh, setRefresh] = useState(false);

    const handleDeleteGame = (e) => {
        GameService.deleteGameFromHideGame(selectedGame.id).then((res) => {
            setRefresh((old) => !old);

            if (gameList.length === 1) reload((old) => !old);
        });
    };

    useEffect(() => {
        setGameList([]);
        setSearchText('');
        setSelectedGame(null);
        setSelectedIndex(-1);

        if (team === null) return;

        setLoading(true);
        GameService.getHidGamesByTeam(team.id).then((res) => {
            setGameList(res);
            setLoading(false);
        });
    }, [team, refresh]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            <p style={{ fontFamily: "'DM Sans', sans-serif", fontWeight: 600, fontSize: '1rem', color: '#ffffff', textAlign: 'center', margin: 0 }}>Games</p>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '24px', border: '1px solid #7c7d7d', borderRadius: '8px', width: '472px', height: '65vh', padding: '16px 12px' }}>
                <TextField
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    placeholder="Search"
                    label=""
                    inputProps={{ 'aria-label': 'Without label' }}
                    variant="outlined"
                    autoFocus
                    sx={{
                        width: '100%',
                        fontSize: '0.8rem',
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                        '& .MuiOutlinedInput-root': { borderRadius: '10px' },
                        '& .MuiOutlinedInput-input': { padding: 0, height: '36px' }
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconButton>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                {loading ? (
                    <div style={{ width: '100%', height: '80%', zIndex: 9999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', maxHeight: '58vh', overflowY: 'auto' }}>
                        {gameList
                            .filter(
                                (item) =>
                                    item.home_team_name.toLowerCase().includes(searchText.toLowerCase()) ||
                                    item.away_team_name.toLowerCase().includes(searchText.toLowerCase()) ||
                                    item.league_name.toLowerCase().includes(searchText.toLowerCase()) ||
                                    item.season_name.toLowerCase().includes(searchText.toLowerCase())
                            )
                            .map((item, index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        border: selectedIndex === index ? '2px solid #ffffff' : '1px solid #7c7d7d',
                                        borderRadius: '8px',
                                        padding: '4px 8px',
                                        cursor: 'pointer',
                                        background: selectedIndex === index ? '#6a6d6d' : 'transparent'
                                    }}
                                    onClick={() => {
                                        setSelectedIndex(index);
                                        setSelectedGame(item);
                                    }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '12px', flex: 1 }}>
                                        <img style={{ height: '45px', width: '75px', borderRadius: '6px' }} src={item.image ? item.image : GameImage} />
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '180px' }}>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '20px' }}>
                                                    <img src={item.home_team_image ? item.home_team_image : TEAM_ICON_DEFAULT} style={{ height: '20px' }} />
                                                </div>
                                                <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontWeight: 500, fontSize: '0.7rem', color: '#ffffff' }}>{item.home_team_name}</Typography>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '20px' }}>
                                                    <img src={item.away_team_image ? item.away_team_image : TEAM_ICON_DEFAULT} style={{ height: '20px' }} />
                                                </div>
                                                <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontWeight: 500, fontSize: '0.7rem', color: '#ffffff' }}>{item.away_team_name}</Typography>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '108px' }}>
                                            <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontWeight: 500, fontSize: '0.7rem', color: '#ffffff' }}>{item.league_name}</Typography>
                                            <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontWeight: 500, fontSize: '0.7rem', color: '#ffffff' }}>{item.season_name}</Typography>
                                        </div>
                                    </div>
                                    {selectedIndex === index && (
                                        <IconButton onClick={handleDeleteGame}>
                                            <DeleteIcon />
                                        </IconButton>
                                    )}
                                </div>
                            ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default HideGameControl;
