import * as React from 'react';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// const style = {
//     position: 'absolute',
//     top: '45%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: '100%',
//     display: 'flex',
//     justifyContent: 'center',
//     p: 4
// };

const SubBox = styled(Box)`
    margin: 6px;
    padding: 6px 2px 2px 2px;
    background-color: #343434;
    border-radius: 6px;
    & .MuiPaper-root {
        padding: 6px;
        border-radius: 6px;
        max-height: 90vh;
        overflow-y: auto;
    }
    & .title {
        text-align: center;
    }
`;

export default function PlayerList({ title, playerList, game, posList = [], editable = true, onSelect, setGamePlayerRefresh }) {
    // const [addPlayerModalOpen, setAddPlayerModalOpen] = React.useState(false);
    // const [playerOpen, setPlayerOpen] = React.useState(false);
    const [, setSelected] = React.useState(null);

    // const update = (data) => {
    //     gameService
    //         .updatePlayer(data)
    //         .then((res) => {})
    //         .catch((error) => {});
    // };

    return (
        <SubBox sx={{ marginTop: '5rem', height: '100%' }}>
            <div className="title">{title}</div>
            <TableContainer component={Paper} sx={{ height: '600px' }}>
                <Table aria-label="a dense table">
                    <TableBody>
                        {playerList.map((player, i) =>
                            !editable && !player.checked ? (
                                <></>
                            ) : (
                                <TableRow
                                    key={i}
                                    onClick={() => {
                                        onSelect(player);
                                        setSelected(player);
                                    }}
                                    sx={{
                                        border: 0,
                                        height: 35,
                                        background: 'transparent',
                                        '&:hover': {
                                            background: 'darkblue'
                                        }
                                    }}
                                >
                                    <TableCell align="center" sx={{ width: 50 }}>
                                        {player.player_jersey_number}
                                    </TableCell>
                                    <TableCell sx={{ width: '120px' }} align="left">
                                        {player.player_first_name} {player.player_last_name}
                                    </TableCell>
                                    <TableCell align="left">{player.player_position_name}</TableCell>
                                </TableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </SubBox>
    );
}
