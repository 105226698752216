import {
    SETTINGS_LOGIN_PANEL,
    SETTINGS_LOGIN_PANEL_CLOSE,
    SETTINGS_FORGOT_PASSWORD_PANEL,
    SETTINGS_FORGOT_PASSWORD_PANEL_CLOSE,
    SETTINGS_REGISTER_PANEL,
    SETTINGS_REGISTER_PANEL_CLOSE
} from '../actions/types';

const initialState = {
    isLoginPanel: false,
    isForgotPasswordPanel: false,
    isRegisterPanel: false
};

export default function settingReducer(state = initialState, action) {
    const { type } = action;

    switch (type) {
        case SETTINGS_LOGIN_PANEL:
            return {
                ...state,
                isLoginPanel: true
            };
        case SETTINGS_LOGIN_PANEL_CLOSE:
            return {
                ...state,
                isLoginPanel: false
            };
        case SETTINGS_FORGOT_PASSWORD_PANEL:
            return {
                ...state,
                isForgotPasswordPanel: true
            };
        case SETTINGS_FORGOT_PASSWORD_PANEL_CLOSE:
            return {
                ...state,
                isForgotPasswordPanel: false
            };
        case SETTINGS_REGISTER_PANEL:
            return {
                ...state,
                isRegisterPanel: true
            };
        case SETTINGS_REGISTER_PANEL_CLOSE:
            return {
                ...state,
                isRegisterPanel: false
            };
        default:
            return state;
    }
}
