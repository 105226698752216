import React from 'react';
import { useTranslation } from 'react-i18next';
import Banner from './Banner';
// import NextMatch from './NextMatch';
// import UpcomingMatches from './UpcomingMatches';
// import MatcheHighlights from './MatcheHighlights';
// import Products from './Products';
// import Partners from './Partners';
// import Gallery from './Gallery';
// import Subscribe from './Subscribe';
// import BlogPost from './BlogPost';
import { Box, IconButton, Drawer } from '@mui/material';
import Login from '../auth/Login';
import Lock from '@mui/icons-material/Lock';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../actions/auth';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { openLoginPanel, closeLoginPanel, closeForgotPanel, closeRegisterPanel } from '../../actions/settings.action';
import ForgetPassword from '../auth/ForgetPassword';
import Register from '../auth/Register';

const Home = () => {
    const dispatch = useDispatch();

    const isLoginPanelopend = useSelector((state) => state.setting.isLoginPanel);
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const isForgotPasswordPanel = useSelector((state) => state.setting.isForgotPasswordPanel);
    const isRegisterPanel = useSelector((state) => state.setting.isRegisterPanel);

    const openDrawer = () => () => {
        dispatch(openLoginPanel());
    };

    const closeDrawer = () => () => {
        dispatch(closeLoginPanel());
        dispatch(closeForgotPanel());
        dispatch(closeRegisterPanel());
    };

    const DrawerList = (
        <Box sx={{ width: 360 }} role="presentation">
            {isForgotPasswordPanel ? <ForgetPassword /> : isRegisterPanel ? <Register /> : <Login />}
        </Box>
    );

    // const { t } = useTranslation();
    return (
        <>
            <Banner t={isLoginPanelopend} />
            {/* <NextMatch /> */}

            {/* <UpcomingMatches /> */}

            {/* <MatcheHighlights /> */}

            {/* <Products /> */}

            {/* <Partners /> */}

            {/* <Gallery /> */}

            {/* <Subscribe /> */}

            {/* <BlogPost /> */}
            {isLoggedIn ? (
                <IconButton className="bg-dark" aria-label="delete" size="large" onClick={() => dispatch(logout())} style={{ position: 'absolute', right: 0, top: '20%' }}>
                    <ExitToAppIcon fontSize="inherit" />
                </IconButton>
            ) : (
                <IconButton className="bg-dark" aria-label="delete" size="large" onClick={openDrawer()} style={{ position: 'absolute', right: 0, top: '20%' }}>
                    <Lock fontSize="inherit" />
                </IconButton>
            )}
            <Drawer anchor={'right'} open={isLoginPanelopend} onClose={closeDrawer()} transitionDuration={1000}>
                {DrawerList}
            </Drawer>
        </>
    );
};

export default Home;
