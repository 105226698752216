import React from "react";
import "./PlayerDetails.css";
import { PLAYER_ICON_DEFAULT } from '../../common/staticData';

const PlayerImgComponent = ({ player}) => {

    const jersey_style = { color: '#3794c1 !important' }

    const getFootImage = (foot) => {
        switch (foot) {
            case 'Both':
                return '../../../both-shoes.png';
            case 'Right':
                return '../../../RightFoot.png';
            case 'Left':
                return '../../../LeftFoot.png';
            default:
                return '../../../NoFoot.png';
        }
    }

    return (
        <div className="player-photo">
        <div className="image-container">
            <img style={{height: '200px'}} src={player?.player_image ? player?.player_image.includes(process.env.REACT_APP_S3_URI) ? player?.player_image : process.env.REACT_APP_S3_URI + player?.player_image : PLAYER_ICON_DEFAULT} alt="" />
            <div className="skill-info gam-logo" ><img src={player?.team_image ? player?.team_image.includes(process.env.REACT_APP_S3_URI) ? player?.team_image : process.env.REACT_APP_S3_URI + player?.team_image : '../../../ball-img.png'} alt="" /></div>
            <div className="player_name_inside" >{player?.player_first_name}<br />{player?.player_last_name}</div>
            <div className="foot_logo_in" style={{ textAlign: 'left' }}>  <img src={getFootImage(player?.player_strong_foot)} alt="" /></div>
            <div className="jersy_number_in">
                <div className="skill-info-mini skill-infoSec">
                    <img src="../../../white-jersy-img.png" alt="" />
                    <span style={jersey_style}>{player?.player_jersey_number < 99 ? player.player_jersey_number : 'NA'}</span>
                </div>
            </div>
        </div>
    </div>
    );
};

export default PlayerImgComponent;
