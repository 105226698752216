import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { Box, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress } from '@mui/material';
import GameService from '../../../../../services/game.service';
import TeamStatsChart from './statChart';
import TeamFormationLogo from './FormationLogo';
import GeniusBallLogo from '../../../../../assets/images/football/Genius-Ball-Logo_Original Colour - Black.png';
import s4uLogo from '../../../../../assets/images/football/SmallerS4ULogo.png';
import './TeamFormationAnalysis.css';

const TeamFormationAnalysis = ({ teamId, teamName, teamShortName, teamLogo, seasonId, leagueId, gameIds, games, t }) => {
    const [formationAnalysis, setFormationAnalysis] = useState([]);
    const [formationByGame, setFormationByGame] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formationList, setFormationList] = useState([]);
    const formationChart = useRef(null);
    const formationListTable = useRef(null);
    const formationVsOpponents = useRef(null);
    const formationGameByGame = useRef(null);

    useEffect(() => {
        (async () => {
            setLoading(true);
            if (gameIds.length > 0) {
                GameService.getFormationAnalysis({
                    seasonId: seasonId,
                    leagueId: leagueId,
                    teamId: teamId,
                    gameIds: gameIds
                }).then((res) => {
                    setFormationAnalysis(res);
                });

                GameService.getFormationAnalysisSummary({
                    seasonId: seasonId,
                    leagueId: leagueId,
                    teamId: teamId,
                    gameIds: gameIds
                }).then((res) => {
                    setFormationList(res);
                });
                GameService.getFormationAnalysisByGame({
                    seasonId: seasonId,
                    leagueId: leagueId,
                    teamId: teamId,
                    gameIds: gameIds
                }).then((res) => {
                    setFormationByGame(res);
                });
            } else window.alert("No selected games. Please click 'Select Games' button to select.");
            setLoading(false);
        })();
    }, [refresh, gameIds]);

    return (
        <Box sx={{ width: '100%', backgroundColor: '#ffffff', maxHeight: '75vh', minHeight: '70vh', overflowY: 'auto', display: 'flex', padding: '10px', gap: '10px' }}>
            {loading && (
                <div style={{ width: '100%', height: '75vh', zIndex: 9999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                       <Box className="printable-content">
                            <img className="headerGeniusballLogo" src={GeniusBallLogo} />
                            <img className="headerTeamLogo" src={teamLogo} />
                            <p className="headerTeamName">{teamName}</p>
                            <p className="headerReportName">Formations Dynamic</p>
                            <img className="headerS4ULogo" src={s4uLogo} />
                            <Box className="formationChart" ref={formationChart}>
                                <TeamStatsChart
                                    t={t}
                                    chartId="dribble"
                                    title={t('Formation Points')}
                                    isType={false}
                                    filterText="Dribble"
                                    sx={{ margin: '20px' }}
                                    teamId={teamId}
                                    formationList={formationList}
                                    refreshPage={setRefresh}
                                />
                            </Box>

                            <Box
                                className="formationListTable"
                                ref={formationListTable}
                                sx={{
                                    display: 'grid',
                                    margin: '20px 0px',
                                    gridTemplateColumns: 'repeat(5, 1fr)',
                                    gap: '20px',
                                    padding: '0 10px'
                                }}
                            >
                                {formationList.map((item, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            minHeight: '300px'
                                        }}
                                    >
                                        <TeamFormationLogo formation={item} t={t} />
                                    </Box>
                                ))}
                            </Box>

                            <Box
                                className="formationVsOpponents"
                                ref={formationVsOpponents}
                                sx={{
                                    padding: '0 10px',
                                    margin: '0px 0'
                                }}
                            >
                                <p className="tableTitle">Formations vs Opponents</p>
                                <Table stickyHeader sx={{ minWidth: 450, borderWidth: '1px' }} aria-labelledby="tableTitle" size={'small'}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                key="0_0"
                                                align="center"
                                                padding="normal"
                                                sortDirection={false}
                                                sx={{
                                                    color: '#1A3476',
                                                    padding: '0px',
                                                    minWidth: '70px',
                                                    borderWidth: '1px',
                                                    borderStyle: 'solid',
                                                    borderColor: 'rgba(0, 0, 0, 0.20)'
                                                }}
                                            >
                                                <p style={{ color: '#1A3476', margin: '0px' }}>{teamShortName}</p>
                                                <hr style={{ margin: '10px 10px' }} />
                                                OPPONENT
                                            </TableCell>
                                            {formationList.map((formation) => (
                                                <TableCell
                                                    key={`${formation.team_formation_id}_0`}
                                                    align="center"
                                                    sx={{
                                                        color: '#1A3476',
                                                        padding: '5px',
                                                        minWidth: '50px',
                                                        borderWidth: '1px',
                                                        borderStyle: 'solid',
                                                        borderColor: 'rgba(0, 0, 0, 0.20)'
                                                    }}
                                                    sortDirection={false}
                                                >
                                                    <p className="formationTitleName" style={{ color: '#1A3476', margin: '0px' }}>
                                                        {formation.team_formation_name.split(' ')[0]}
                                                    </p>
                                                    <img
                                                        src={formation.team_formation_image}
                                                        alt="Description"
                                                        style={{
                                                            minWidth: '50px',
                                                            maxWidth: '70px',
                                                            height: '77px',
                                                            marginBottom: '2px',
                                                            borderRadius: '2px'
                                                        }}
                                                    />
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {formationList.map((formation_opp) => (
                                            <TableRow key={formation_opp.team_formation_id}>
                                                <TableCell
                                                    key={`0_${formation_opp.team_formation_id}`}
                                                    align="center"
                                                    sx={{
                                                        padding: '0px',
                                                        minWidth: '50px',
                                                        borderWidth: '1px',
                                                        borderStyle: 'solid',
                                                        borderColor: 'rgba(0, 0, 0, 0.20)'
                                                    }}
                                                >
                                                    <p className="formationTitleName" style={{ color: '#1A3476', margin: '0px' }}>
                                                        {formation_opp.team_formation_name.split(' ')[0]}
                                                    </p>
                                                    <img
                                                        src={formation_opp.team_formation_image}
                                                        alt="Description"
                                                        style={{
                                                            minWidth: '50px',
                                                            maxWidth: '70px',
                                                            height: '77px',
                                                            marginBottom: '2px',
                                                            borderRadius: '2px'
                                                        }}
                                                    />
                                                </TableCell>
                                                {formationList.map((formation) => (
                                                    <TableCell
                                                        key={`${formation.team_formation_id}_${formation_opp.team_formation_id}`}
                                                        align="center"
                                                        sx={{
                                                            padding: 0,
                                                            minWidth: '50px',
                                                            borderWidth: '1px',
                                                            borderStyle: 'solid',
                                                            borderColor: 'rgba(0, 0, 0, 0.20)',
                                                            height: '100%'
                                                        }}
                                                    >
                                                        <Box key={`${formation.team_formation_id}_container`} sx={{ padding: '0px', height: '100%' }} align="center">
                                                            {formationAnalysis.map((item) => {
                                                                if (item.team_formation_id == formation.team_formation_id && item.opponent_formation_id == formation_opp.team_formation_id) {
                                                                    return (
                                                                        <Box
                                                                            key={`res_${item.team_formation_id}_${item.opponent_formation_id}`}
                                                                            sx={{
                                                                                padding: '0px',
                                                                                backgroundColor: item.team_won_games + item.team_draw_games >= item.team_lose_games ? '#D9EAD3' : '#F4CCCC',
                                                                                color: '#1A3476',
                                                                                minWidth: '50px',
                                                                                height: '100%'
                                                                            }}
                                                                            align="center"
                                                                        >
                                                                            <p className="formationResult" style={{ margin: '0px', color: '#1A3476' }}>
                                                                                {item.total_number_of_times_played} Games
                                                                            </p>
                                                                            <p className="formationResult" style={{ margin: '0px', color: '#1A3476' }}>
                                                                                {item.total_percentage_of_times}% Played
                                                                            </p>
                                                                            <p className="formationResult" style={{ margin: '0px', color: '#1A3476' }}>
                                                                                {' '}
                                                                                WON: {item.team_won_games}
                                                                            </p>
                                                                            <p className="formationResult" style={{ margin: '0px', color: '#1A3476' }}>
                                                                                {' '}
                                                                                DRAW: {item.team_draw_games}
                                                                            </p>
                                                                            <p className="formationResult" style={{ margin: '0px', color: '#1A3476' }}>
                                                                                {' '}
                                                                                LOSE: {item.team_lose_games}
                                                                            </p>
                                                                        </Box>
                                                                    );
                                                                }
                                                            })}
                                                        </Box>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>

                            <Box
                                className="formationGameByGame"
                                ref={formationGameByGame}
                                sx={{
                                    padding: '0 10px',
                                    margin: '0px 0'
                                }}
                            >
                                <p className="tableTitle">Game By Game Formation Result</p>
                                <Table
                                    stickyHeader
                                    sx={{
                                        minWidth: 450,
                                        borderWidth: '1px',
                                        borderStyle: 'solid',
                                        borderColor: 'rgba(0, 0, 0, 0.20'
                                    }}
                                    aria-labelledby="tableTitle"
                                    size={'small'}
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                key="0_0"
                                                align="center"
                                                padding="normal"
                                                sortDirection={false}
                                                sx={{
                                                    color: '#1A3476',
                                                    padding: '5px',
                                                    minWidth: '50px',
                                                    borderWidth: '1px',
                                                    borderStyle: 'solid',
                                                    borderColor: 'rgba(0, 0, 0, 0.20)'
                                                }}
                                            >
                                                <p style={{ color: '#1A3476', margin: '0px' }}>{teamShortName}</p>
                                                <hr style={{ margin: '10px 10px' }} />
                                                OPPONENT
                                            </TableCell>
                                            {formationList.map((formation) => (
                                                <TableCell
                                                    key={`${formation.team_formation_id}_0`}
                                                    align="center"
                                                    sx={{
                                                        color: '#1A3476',
                                                        padding: '5px',
                                                        minWidth: '50px',
                                                        borderWidth: '1px',
                                                        borderStyle: 'solid',
                                                        borderColor: 'rgba(0, 0, 0, 0.20)'
                                                    }}
                                                    sortDirection={false}
                                                >
                                                    <p className="formationTitleName" style={{ color: '#1A3476', margin: '0px' }}>
                                                        {formation.team_formation_name.split(' ')[0]}
                                                    </p>
                                                    <img
                                                        src={formation.team_formation_image}
                                                        alt="Description"
                                                        style={{
                                                            minWidth: '50px',
                                                            maxWidth: '70px',
                                                            height: '77px',
                                                            marginBottom: '2px',
                                                            borderRadius: '2px'
                                                        }}
                                                    />
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {formationByGame.map((formation_game) => (
                                            <TableRow key={formation_game.game_id}>
                                                <TableCell
                                                    key={`0_${formation_game.game_id}`}
                                                    align="center"
                                                    sx={{
                                                        padding: '5px',
                                                        minWidth: '50px',
                                                        borderWidth: '1px',
                                                        borderStyle: 'solid',
                                                        borderColor: 'rgba(0, 0, 0, 0.20)'
                                                    }}
                                                >
                                                    <p style={{ color: '#1A3476', margin: '2px 2px', fontSize: '14px' }}>{formation_game.opponent_short_name} ({formation_game.final_score})</p>
                                                    <p style={{ color: '#1A3476', margin: '2px 2px', fontSize: '14px' }}>{moment(formation_game.game_date).format('DD/MM/YY')}</p>
                                                </TableCell>
                                                {formationList.map((formation) => {
                                                    if (formation_game.team_formation_id == formation.team_formation_id) {
                                                        return (
                                                            <TableCell
                                                                key={`${formation.team_formation_id}_${formation_game.game_id}`}
                                                                align="center"
                                                                sx={{
                                                                    padding: 0,
                                                                    minWidth: '50px',
                                                                    borderWidth: '1px',
                                                                    borderStyle: 'solid',
                                                                    borderColor: 'rgba(0, 0, 0, 0.20)',
                                                                    height: '100%',
                                                                    backgroundColor: formation_game.team_points === 3 ? '#D9EAD3' : formation_game.team_points === 1 ? '#FFF2CC' : '#F4CCCC'
                                                                }}
                                                            >
                                                                <p style={{ color: '#1A3476', margin: '0px', fontSize: '30px' }} className="formationGameResult">
                                                                    {formation_game.team_points}
                                                                </p>
                                                            </TableCell>
                                                        );
                                                    } else {
                                                        return (
                                                            <TableCell
                                                                key={`${formation.team_formation_id}_${formation_game.game_id}`}
                                                                align="center"
                                                                sx={{
                                                                    padding: 0,
                                                                    minWidth: '50px',
                                                                    borderWidth: '1px',
                                                                    borderStyle: 'solid',
                                                                    borderColor: 'rgba(0, 0, 0, 0.20)',
                                                                    height: '100%'
                                                                }}
                                                            ></TableCell>
                                                        );
                                                    }
                                                })}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Box>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default TeamFormationAnalysis;
