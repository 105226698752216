import { CircularProgress, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { TEAM_ICON_DEFAULT } from '../../../common/staticData';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/SearchOutlined';

import GameService from '../../../services/game.service';
import DeleteConfirmDialog from '../../../common/DeleteConfirmDialog';

const HideTeamControl = ({ refresh, setTeam }) => {
    const [loading, setLoading] = useState(false);
    const [teamList, setTeamList] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [reload, setReload] = useState(refresh);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [searchText, setSearchText] = useState('');

    const handleDeleteTeam = (e) => {
        setDeleteOpen(true);
    };

    const handleDeleteClose = (result) => {
        setDeleteOpen(false);

        if (!result) return;

        GameService.deleteTeamFromHideGame(selectedTeam.id).then((res) => {
            setReload((old) => !old);
        });
    };

    useEffect(() => {
        setLoading(true);
        setTeamList([]);
        setTeam(null);
        setSelectedIndex(-1);
        setSearchText('');
        GameService.getTeamsFromHide().then((res) => {
            setTeamList(res);
            setLoading(false);
        });
    }, [reload]);

    useEffect(() => {
        setReload(refresh);
    }, [refresh]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            <DeleteConfirmDialog open={deleteOpen} handleDeleteClose={handleDeleteClose} />
            <p style={{ fontFamily: "'DM Sans', sans-serif", fontWeight: 600, fontSize: '1rem', color: '#ffffff', textAlign: 'center', margin: 0 }}>Teams</p>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '24px', border: '1px solid #7c7d7d', borderRadius: '8px', width: '360px', height: '65vh', padding: '16px 12px' }}>
                <TextField
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    placeholder="Search"
                    label=""
                    inputProps={{ 'aria-label': 'Without label' }}
                    variant="outlined"
                    autoFocus
                    sx={{
                        width: '100%',
                        fontSize: '0.8rem',
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                        '& .MuiOutlinedInput-root': { borderRadius: '10px' },
                        '& .MuiOutlinedInput-input': { padding: 0, height: '36px' }
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconButton>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                {loading ? (
                    <div style={{ width: '100%', height: '80%', zIndex: 9999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', maxHeight: '58vh', overflowY: 'auto' }}>
                        {teamList
                            .filter((item) => item.name.toLowerCase().includes(searchText.toLowerCase()))
                            .map((item, index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        border: selectedIndex === index ? '2px solid #ffffff' : '1px solid #7c7d7d',
                                        borderRadius: '8px',
                                        padding: '4px 8px',
                                        cursor: 'pointer',
                                        background: item.team_color ? item.team_color : 'transparent'
                                    }}
                                    onClick={() => {
                                        setTeam(item);
                                        setSelectedTeam(item);
                                        setSelectedIndex(index);
                                    }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '16px', flex: 1 }}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '24px' }}>
                                            <img src={item.image ? item.image : TEAM_ICON_DEFAULT} style={{ height: '24px' }} />
                                        </div>
                                        <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontWeight: 500, fontSize: '0.7rem', color: '#ffffff' }}>{item.name}</Typography>
                                    </div>
                                    {selectedIndex === index && (
                                        <IconButton onClick={handleDeleteTeam}>
                                            <DeleteIcon />
                                        </IconButton>
                                    )}
                                </div>
                            ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default HideTeamControl;
