import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, CircularProgress, Grid } from '@mui/material';

import GameService from '../../../../../services/game.service';
import TeamStatsBoxList from './statBoxList';
import TeamStatsChart from './statChart';
import { getSeriesData } from '../../../../../common/utilities';
import TeamStatsVideoPlayer from './videoDialog';

const TeamStats = ({ games, gameIds, teamId, t }) => {
    const [playerList, setPlayerList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [gameList, setGameList] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [videoOpen, setVideoOpen] = useState(false);
    const [playData, setPlayData] = useState([]);

    const { user: currentUser } = useSelector((state) => state.auth);

    useEffect(() => {
        setLoading(true);
        if (games.length > 0 && gameIds.length > 0) {
            setGameList(games.filter((item) => gameIds.includes(item.id)));
            GameService.getGamePlayerTags(currentUser.id, teamId, null, gameIds.join(','), null, null, null, null, null, null, null, null).then((res) => {
                setPlayerList(getSeriesData(res));
                setLoading(false);
            });
        } else {
            setGameList([]);
            setPlayerList([]);
            setLoading(false);
        }
    }, [games, gameIds, refresh]);

    const getList = (series) => {

        if (series) {
            const llst = []
            const slst = []
            const clst = []
            series.forEach(element => {
                if (element?.data > 0) {
                    llst.push(element.name)
                    slst.push(element.data)
                    clst.push(element.color)
                }
            });
            return { seriesDonut: slst, labelsLst: llst, colorLst: clst }
        }
    }
    function getPeriod(id) {
        return id === 1 ? 'H1' : id === 2 ? 'H2' : 'OT';
    }

    const handleClick = (data) => {
        try {
            console.log('handleClick', data);
            const newData = data.map((item) => ({
                tag_id: item.id,
                start_time: item.player_tag_start_time,
                end_time: item.player_tag_end_time,
                player_name: item.player_names,
                action_name: item.action_names,
                action_type: item.action_type_names,
                action_result: item.action_result_names,
                game_id: item.game_id,
                team_id: teamId,
                court_area: item.court_area_id,
                inside_pain: item.inside_the_pain,
                period: getPeriod(item.period),
                time: item.time_in_game,
                home_team_image: item.home_team_logo,
                away_team_image: item.away_team_logo,
                home_team_goals: item.home_team_goal,
                away_team_goals: item.away_team_goal
            }));
            if (newData.length > 0) {
                setVideoOpen(true)
                setPlayData(newData);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Box sx={{ width: '100%', background: '#ffffff', overflowY: 'auto', padding: '20px 10px', gap: '10px', maxHeight: '75vh', minHeight: '70vh' }}>
            {loading && (
                <div style={{ width: '100%', height: '70vh', zIndex: 9999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <>
                    <Box>
                        <TeamStatsBoxList games={gameList} t={t} list={playerList} teamId={teamId} refreshPage={setRefresh} />
                    </Box>
                    <Grid container spacing={2}>
                        {playerList.map((stat, index) => (
                            stat.total > 0 && (
                                <Grid item xs={12} sm={6}>
                                    <TeamStatsChart
                                        className="mb-3"
                                        title={stat.title}
                                        seriesBar={stat.seriesBar}
                                        seriesDonut={getList(stat.seriesBar)}
                                        total={stat.total}
                                        tags={stat.tags}
                                        handleClick={handleClick}
                                        key={index}
                                        t={t}
                                    />
                                </Grid>
                            )
                        ))}
                    </Grid>
                </>
            )}

            <TeamStatsVideoPlayer
                t={t}
                open={videoOpen}
                onClose={(flag) => {
                    setVideoOpen(false);
                    if (flag) setRefresh((r) => !r);
                }}
                video_url={gameList}
                tagList={playData}
            />
        </Box>
    );
};

export default TeamStats;
