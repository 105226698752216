import React from 'react';
import { Box } from '@mui/material';

import MinusIcon from '@mui/icons-material/IndeterminateCheckBox';
import AddIcon from '@mui/icons-material/AddBox';

export function getPeriod(id) {
    return id === 1 ? 'H1' : id === 2 ? 'H2' : 'OT';
}

const GameTagListItem = ({ item, isSelected, idx, isChecked, onChecked, onShowVideo, onChangeTime }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                gap: '0px',
                padding: '4px',
                justifyContent: 'space-between',
                border: '1px solid #e8e8e8',
                borderRadius: '8px'
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }} onClick={() => onShowVideo(idx)}>
                <Box
                    sx={{
                        background: isSelected ? '#17375E' : '#5C738E',
                        borderRadius: '8px',
                        width: '32px',
                        height: '32px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <p className="normal-text-white">{idx + 1}</p>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <p className="normal-text">{`${getPeriod(item.period)} - ${item.game_time}' - ${item.offensive_short_team_name ?? item.offensive_team_name} - Started: ${
                            item.started_action_name
                        } of ${item.started_player_name} `}</p>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        <p className="normal-text">
                            {`${item.total_passes} Passes - ${item.total_dribbles} Dribbles - Ended:  `}
                            {item.ended_action_id === 2 || item.ended_action_id === 7
                                ? item.ended_action_result_name
                                : item.ended_action_id === 6
                                ? item.ended_action_name
                                : item.ended_action_id === 3 || item.ended_action_id === 4
                                ? `${item.ended_action_result_name} ${item.ended_action_name}`
                                : item.ended_action_id === 1
                                ? `${item.ended_action_name} ${item.ended_action_result_name}`
                                : ''}
                            {` of ${item.ended_player_name}`}
                        </p>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <div style={{ width: '100px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box onClick={() => onChangeTime(idx, true, -5)} sx={{ 'svg path': { fill: 'lightgrey' } }}>
                            <MinusIcon fontSize="small" />
                        </Box>
                        <p className="normal-text">{item.start_time}</p>
                        <Box onClick={() => onChangeTime(idx, true, 5)} sx={{ 'svg path': { fill: 'lightgrey' } }}>
                            <AddIcon fontSize="small" />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box onClick={() => onChangeTime(idx, false, -5)} sx={{ 'svg path': { fill: 'lightgrey' } }}>
                            <MinusIcon fontSize="small" />
                        </Box>
                        <p className="normal-text">{item.end_time}</p>
                        <Box onClick={() => onChangeTime(idx, false, 5)} sx={{ 'svg path': { fill: 'lightgrey' } }}>
                            <AddIcon fontSize="small" />
                        </Box>
                    </Box>
                </div>
                <input key={idx} type="checkbox" value={isChecked} checked={isChecked ? true : false} style={{ width: '18px', height: '18px' }} onChange={onChecked(idx)} />
            </Box>
        </Box>
    );
};

export default GameTagListItem;
