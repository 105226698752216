import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import EditVideoPlayer from './edits/videoplayer/sharedplayer';
import GameService from '../../services/game.service';

const CoachSharedEditVideoPlayer = ({ t }) => {
    const params = useParams();
    const [curTagIndex, setCurTagIndex] = useState(0);
    const [tagList, setTagList] = useState([]);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Consider 768px as the breakpoint for mobile devices
        };

        handleResize(); // Check initial screen size
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        (async () => {
            let edit = null;
            const pathname = window.location.pathname;

            if (pathname.match(/\/shareedit\//) !== null) {
                await GameService.verifyShareId({ code: params.code }).then((res) => {
                    edit = res[0];
                });
                await GameService.getEditClipsByUserEditId(edit.id).then((res) => {
                    setTagList(res);
                });
            }
        })();
    }, [params.code]);

    return (
        <Grid container spacing={2}>
            <Grid item md={12}>
                <EditVideoPlayer t={t} idx={curTagIndex} tagList={tagList} onChangeClip={setCurTagIndex} drawOpen={false} />
            </Grid>
        </Grid>
    );
};
export default CoachSharedEditVideoPlayer;
