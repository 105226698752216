import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import '../coach_style.css';
import ReportsExportData from './ReportsExportData';
import ReportPossession from './ReportPossession';
import SeasonContext from '../../../common/Context';

// let Tabs = ['Export Data', 'Possession Analysis'];
let Tabs = ['Export Data'];
const Reports = ({ t }) => {
    const [curTab, setCurTab] = useState(0);
    const { seasonFilter } = React.useContext(SeasonContext);

    return (
        <div className="coach-page-style">
            <div className="page-header">
                <p className="page-title">{t('Reports')}</p>
                <div className="page-tab-container settings-page">
                    {Tabs.map((tab, index) => (
                        <div key={index} onClick={() => setCurTab(index)} className="page-tab-style">
                            <p className="page-tab-title">{t(tab)}</p>
                            {curTab === index ? <div className="selected-line" /> : <div className="unselected-line" />}
                        </div>
                    ))}
                </div>
            </div>
            {curTab === 0 && <ReportsExportData t={t} seasonFilter={seasonFilter} />}
            {curTab === 1 && <ReportPossession t={t} seasonFilter={seasonFilter} />}
        </div>
    );
};

export default Reports;
