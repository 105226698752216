import * as React from 'react';

const Canvas = ({rect_w, w, h, type}) => {

    const canvasRef = React.useRef(null);
    React.useEffect(()=>{
        
        const ctx = canvasRef.current.getContext("2d")
        ctx.beginPath();
        ctx.lineWidth = 2;
        ctx.strokeStyle = 'rgba(255, 0, 255, 1)';

        if (type === 1)
            ctx.ellipse(w/2, h/2, w/2, h/2, 0, -1 * Math.atan(1.73*h/(0.6*w)), Math.PI+Math.atan(1.73*h/(0.6*w)));
        else
            ctx.ellipse(w/2, h/2, w/2, h/2, 0, -1 * Math.atan(h*Math.sqrt(4*w*w - rect_w*rect_w)/(w*rect_w)), Math.PI+Math.atan(h*Math.sqrt(4*w*w - rect_w*rect_w)/(w*rect_w)));        
        ctx.stroke();
    }, [])    

    return(
        <canvas ref={canvasRef} width={w} height={h} />
    )
}

export default Canvas;

