import * as React from 'react';
import Canvas from './Canvas';

const LabelViewCircle = ({ positions, width, height, type, rightClicked, hideLabel }) => {
    return (
        <>
            {positions &&
                positions.map((item) => {
                    let x = item.x;
                    let y = item.y;
                    let w = item.w;
                    let h = item.h;
                    let playerId = item.pui;
                    let frame_id = item.id;

                    let xpos = (width * x) / 1920;
                    let ypos = (height * y) / 1080;
                    let rect_w = (width * w) / 1920;
                    let rect_h = (height * h) / 1080;

                    let jersey_number = item.jersey;
                    let isLabelShow = hideLabel[playerId];
                    return (
                        <div
                            key={frame_id}
                            style={{
                                backgroundColor: 'rgba(255, 255, 255, 0)',
                                position: 'absolute',
                                // left: xpos - rect_w/4,
                                left: type === 1 ? xpos - rect_w / 4 : xpos + rect_w / 2 - 20,
                                top: ypos + rect_h - 4,
                                // width: 2*rect_w,
                                width: type === 1 ? 2 * rect_w : 40,
                                height: 23,
                                display: 'flex',
                                flexDirection: 'column',
                                // border: '1px solid red',
                                alignItems: 'center',
                                zIndex: '10'
                            }}
                            onContextMenu={(event) => rightClicked(event, playerId)}
                        >
                            <div
                                style={{
                                    flexGrow: 1,
                                    width: '100%',
                                    height: 16
                                }}
                            >
                                {/* <Canvas rect_w={rect_w} w={2*rect_w} h={16}/> */}
                                {type === 1 && <Canvas rect_w={rect_w} w={2 * rect_w} h={16} type={type} />}
                                {type === 2 && <Canvas rect_w={rect_w} w={2 * 20} h={16} type={type} />}
                            </div>
                            {isLabelShow !== 0 && (
                                <div
                                    style={{
                                        backgroundColor: 'rgba(255, 0, 255, 1)',
                                        paddingLeft: 3,
                                        paddingRight: 3,
                                        width: 'fitContent',
                                        height: 14,
                                        leftMargin: 'auto',
                                        rightMargin: 'auto',
                                        fontSize: 10,
                                        color: '#ffffff',
                                        zIndex: 100,
                                        bottom: 0,
                                        position: 'absolute',
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {jersey_number ? `# ${jersey_number}` : playerId}
                                </div>
                            )}
                        </div>
                    );
                })}
        </>
    );
};

export default LabelViewCircle;
