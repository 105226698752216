import React from 'react';

import { PLAYER_ICON_DEFAULT } from '../../../common/staticData';
import '../coach_style.css';

const LeadersPlayerStatItem = ({ player, option, isTotal, onShow }) => {

    const sumAverages = (player, option) => {
        const averageOption = parseFloat(player[`average_${option}`]);
        const averageSuperSave = parseFloat(player[`average_super_save`]);
      
        const sum = (averageOption + averageSuperSave).toFixed(2);
        return sum;
      };

    return (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%', minHeight: '100px', padding: '8px', justifyContent: 'space-between', cursor: 'pointer' }} onClick={() => onShow(player)}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                <div style={{ width: '88px', height: '88px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img  
                    src={ player.player_image ? (player.player_image.includes(process.env.REACT_APP_S3_URI)
                    ? player.player_image : process.env.REACT_APP_S3_URI + player.player_image)
                    : PLAYER_ICON_DEFAULT } style={{ height: '100%', borderRadius: '8px' }} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: '2px' }}>
                    <p className="normal-text">{player.player_full_name}</p>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        {player.player_jersey_number < 999 && <p className="normal-text">{`#${player.player_jersey_number}`}</p>}
                        <p className="normal-text">{player.player_position_name}</p>
                    </div>
                    <p className="normal-text">{player.team_name}</p>
                    <p className="normal-text">
                        {player.total_player_games === 0 ? 'No games played' : player.total_player_games === 1 ? '1 game played' : player.total_player_games + ' games played'}
                    </p>
                </div>
            </div>
            <p className="bigger-text">
                {isTotal
                    ? option === 'saved'
                        ? player[`total_${option}`] + player[`total_super_save`]
                        : player[`total_${option}`]
                    : option === 'saved'
                    ? (parseFloat(player[`average_${option}`]) + parseFloat(player[`average_super_save`])).toFixed(2)
                    : parseFloat(player[`average_${option}`]).toFixed(2)}
            </p>
        </div>
    );
};

export default LeadersPlayerStatItem;
