import { CircularProgress, Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeftOutlined';
import GameService from '../../../services/game.service';
import TeamGames from './tabs/games';
import TeamOverview from './tabs/overview';
import TeamPlayersStats from './tabs/players';
import TeamPlayersOverview from './tabs/player_overview';
import GameSelectControl from './tabs/overview/gameSelectControl';
import TeamStats from './tabs/stats';
import { TEAM_ICON_DEFAULT } from '../../../common/staticData';
import '../coach_style.css';
import TeamFormationAnalysis from './tabs/formation';
import { SaveButton } from '../components/common';
import PrintIcon from '@mui/icons-material/Print';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

const Tabs = [
    {
        title: 'Team Matches Analysis',
        categories: [
            { key: 0, id: 'Group Analysis' },
            { key: 1, id: 'Data Analysis' }
            //{ key: 2, id: 'Formations Dynamic' }
        ]
    },
    {
        title: 'Individual Performance Analysis',
        categories: [
            //{ key: 3, id: 'Sub-Group Analysis' },
            { key: 4, id: 'Individual Ability 1v1' },
            { key: 5, id: 'Players Data Analysis' }

        ]
    }
];

const TeamPage = ({ t }) => {
    const params = useParams();
    const [values, setValues] = useState({
        teamName: '',
        teamLogo: '',
        teamId: -1,
        seasonId: -1,
        leagueId: -1,
        loadingDone: false
    });
    const [gameList, setGameList] = useState([]);
    const [gameIds, setGameIds] = useState([]);
    const [curTab, setCurTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const gamesList = useSelector((state) => state.coach.gamesList);
    const teamsList = useSelector((state) => state.coach.teamsList);

    function getEncodedStringFromURL() {
        const currentPath = window.location.pathname;
        const basePath = '/new_coach/teams/';
        const encodedString = currentPath.substring(basePath.length);
        return encodedString;
    }

    function decodePathAndGetImageUrl() {
        const encodedString = getEncodedStringFromURL();
        const decodedString = atob(encodedString);
        const parts = decodedString.split('|');

        if (parts.length >= 4) {
            const encodedImageUrl = parts[3];
            const imageUrl = decodeURIComponent(encodedImageUrl);
            return imageUrl;
        } else {
            console.error('Unexpected format of the encoded string');
            return null;
        }
    }

    useEffect(() => {
        (async () => {
            setLoading(false);
        })();
    }, [gameList]);

    useEffect(() => {
        (async () => {
            const pathname = window.location.pathname;
            const imageUrl = decodePathAndGetImageUrl();
            setLoading(true);

            if (teamsList.length && teamsList && pathname.match(/\/new_coach\/teams\//) !== null) {
                const ids = atob(params.teamId).split('|');
                await GameService.getCoachTeamPlayers(ids[0], ids[1], ids[2]).then((res) => {
                    setValues({
                        teamName: teamsList.filter((team) => team.team_id === Number(ids[0]))[0].team_name,
                        teamShortName: teamsList.filter((team) => team.team_id === Number(ids[0]))[0].team_short_name,
                        teamLogo: imageUrl,
                        loading: false,
                        loadingDone: true,
                        teamId: ids[0],
                        seasonId: ids[1],
                        leagueId: ids[2]
                    });
                    if (gamesList.length) setGameList(gamesList.filter((game) => game.home_team_id === Number(ids[0]) || game.away_team_id === Number(ids[0])));
                });
            }
        })();
    }, [params, teamsList, teamsList]);

    const [isPrintButtonshowed, setIsPrintButtonShowed] = useState(false);

    const handleTabClick = (idx) => {
        switch (idx) {
            case 1:
            case 2:
            case 5:
            case 4:
                setIsPrintButtonShowed(true);
                break;
            case 0:
            case 3:
                setIsPrintButtonShowed(false);
                break;
            default:
                setIsPrintButtonShowed(false);
                break;
        }
        setValues({ ...values, curTab: idx });
    };

    const handlePrint = () => {
        const printContent = document.querySelector('.printable-content');

        switch (curTab) {
            case 1:
                const tda = document.querySelector('.tableDataAnalysis');

                if (printContent && tda) {
                    // Show print content
                    printContent.style.visibility = 'visible';
                    tda.classList.add('tdaPrintable');

                    // Trigger the print dialog
                    window.print();

                    // Hide print content again
                    tda.classList.remove('tdaPrintable');
                }
                break;
            case 2:
                const fc = document.querySelector('.formationChart');
                const flt = document.querySelector('.formationListTable');
                const fvo = document.querySelector('.formationVsOpponents');
                const fgbg = document.querySelector('.formationGameByGame');

                if (printContent && fc && flt && fvo && fgbg) {
                    // Show print content
                    printContent.style.visibility = 'visible';
                    fc.classList.add('fcPrintable');
                    flt.classList.add('fltPrintable');
                    fvo.classList.add('fvoPrintable');
                    fgbg.classList.add('fgbgPrintable');

                    // Trigger the print dialog
                    window.print();

                    // Hide print content again
                    fc.classList.remove('fcPrintable');
                    flt.classList.remove('fltPrintable');
                    fvo.classList.remove('fvoPrintable');
                    fgbg.classList.remove('fgbgPrintable');
                }
                break;
            case 4:
                alert('UNDER DEVELOPMENT');
                break;
            case 5:
                alert('UNDER DEVELOPMENT');
                break;
        }
    };

    return (
        <div className="coach-page-style">
            {loading && (
                <div className="loading-circle">
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <>
                    <div className="page-header">
                        <div className="page-title-bar">
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '24px', 'svg path': { fill: 'black' } }}>
                                <Link to="/new_coach/teams">
                                    <ChevronLeftIcon sx={{ width: '32px', height: '32px' }} />
                                </Link>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
                                    <img style={{ height: '48px' }} src={values.teamLogo ? values.teamLogo : TEAM_ICON_DEFAULT} alt="Team Logo" />
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', flex: 4, padding: '0 8px', width: 500 }}>
                                    <p className="normal-text-strong" style={{ fontSize: 'larger' }}>
                                        {values.teamName}
                                    </p>
                                </Box>
                            </Box>
                            {isPrintButtonshowed ? (
                                <SaveButton
                                    sx={{ fontWeight: 500, width: '120px', height: '28px', fontSize: '0.9rem', marginLeft: 'auto', marginRight: '20px' }}
                                    endIcon={<PrintIcon />}
                                    onClick={handlePrint}
                                >
                                    {t('Print')}
                                </SaveButton>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className="page-tab-container">
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <GameSelectControl gameList={gameList} t={t} setIds={setGameIds} />
                            </div>
                            {Tabs.map((item, index) => (
                                <Card key={index} variant="outlined" sx={{ backgroundColor: 'transparent' }}>
                                    <CardHeader
                                        sx={{ padding: '8px 0' }}
                                        title={
                                            <Typography sx={{ textAlign: 'center', color: '#17375E', fontFamily: "'DM Sans', sans-serif", fontSize: '1.2rem', fontWeight: 500 }}>
                                                {item.title}
                                            </Typography>
                                        }
                                    />
                                    <CardContent style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 50, padding: '0 16px' }}>
                                        {item.categories.map((tab) => (
                                            <div
                                                key={tab.key + '_' + tab.id}
                                                onClick={() => {
                                                    setCurTab(tab.key);
                                                    handleTabClick(tab.key);
                                                }}
                                                className="page-tab-style"
                                            >
                                                <p className="page-tab-title">{t(tab.id)}</p>
                                                {curTab === tab.key ? <div className="selected-line" /> : <div className="unselected-line" />}
                                            </div>
                                        ))}
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    </div>
                </>
            )}

            {!loading && (
                <div className="printable-content">
                    {curTab === 0 && <TeamOverview games={gameList} t={t} gameIds={gameIds} teamname={values.teamName} teamId={values.teamId} />}

                    {curTab === 1 && (
                        <TeamGames
                            games={gameList}
                            t={t}
                            gameIds={gameIds}
                            teamId={values.teamId}
                            seasonId={values.seasonId}
                            teamName={values.teamName}
                            teamShortName={values.teamShortName}
                            teamLogo={values.teamLogo}
                        />
                    )}

                    {curTab === 2 && (
                        <TeamFormationAnalysis
                            teamId={values.teamId}
                            teamName={values.teamName}
                            teamShortName={values.teamShortName}
                            teamLogo={values.teamLogo}
                            t={t}
                            seasonId={values.seasonId}
                            leagueId={values.leagueId}
                            gameIds={gameIds}
                            games={gameList}
                        />
                    )}

                    {curTab === 3 && <TeamPlayersOverview games={gameList} t={t} gameIds={gameIds} teamId={values.teamId} teamName={values.teamName} />}

                    {curTab === 4 && <TeamStats games={gameList} t={t} gameIds={gameIds} teamId={values.teamId} />}
                
                    {curTab === 5 && <TeamPlayersStats teamId={values.teamId} t={t} seasonId={values.seasonId} leagueId={values.leagueId} gameIds={gameIds} games={gameList} />}

                    </div>
            )}
        </div>
    );
};

export default TeamPage;
