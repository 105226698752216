import React from 'react';
import { Box, Divider, Typography, Grid } from '@mui/material';

import GamePlayerTagButton from './tagButton';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

const Tags = [
    {
        title: 'Goalkeeper',
        categories: [
            { key: 21, id: 'SuperSaved', name: 'Super Saved' },
            { key: 16, id: 'Saved', name: 'Saved' },
            { key: 22, id: 'AirChallenge', name: 'Air Challenge' },
            { key: 23, id: 'GroundChallenge', name: 'Ground Challenge' },
            { key: 23, id: 'GoalReceive', name: 'Goal Received' }
        ]
    },
    {
        title: 'Attack',
        categories: [
            { key: 5, id: 'KeyPass', name: 'Key Passes' },
            { key: 6, id: 'ThroughPass', name: 'Through Passes' },
            { key: 7, id: 'Offside', name: 'Offsides' }
        ]
    },
    {
        title: 'Final Third',
        categories: [
            { key: 1, id: 'Goal', name: 'Goals' },
            { key: 2, id: 'GoalOpportunity', name: 'Chances' },
            { key: 15, id: 'GoalKick', name: 'Shot on Goal' },
            { key: 3, id: 'Cross', name: 'Crosses' },
            { key: 4, id: 'Penalty', name: 'Penalties' }
        ]
    },    
    {
        title: '1v1',
        categories: [
            { key: 17, id: 'Dribble', name: 'Dribbles' },
            { key: 18, id: 'DrawFoul', name: 'Draw Fouls' },
            { key: 19, id: 'Turnover', name: 'Turnovers' },
            { key: 20, id: 'All', name: 'All Actions' }
        ]
    },
    {
        title: 'Defense',
        categories: [
            { key: 8, id: 'Interception', name: 'Interceptions' },
            { key: 9, id: 'Tackle', name: 'Tackles' },
            { key: 10, id: 'Foul', name: 'Fouls given' },
            { key: 11, id: 'Blocked', name: 'Blocked' },
            { key: 12, id: 'Clearance', name: 'Clearance' }
        ]
    },
    {
        title: 'Set Pieces',
        categories: [
            { key: 13, id: 'Corner', name: 'Corners' },
            { key: 14, id: 'FreeKick', name: 'Free Kicks' },
            { key: 24, id: 'ThrowIn', name: 'Throw-Ins' }
        ]
    }
];

const GamePlayerTagButtonList = ({ selectedTag, onShow, t }) => {
    return (
        <>
            <Divider sx={{ width: '100%', backgroundColor: 'black', opacity: 1, border: 'none', margin: '8px 0 2px' }} />
            <Grid container sx={{  width: '825px'}}>
                {Tags.map((item, index) => {
                    return (
                        <Grid item xs={2} style={{ padding: '4px' }} key={index}>
                            <Card variant="outlined" sx={{ height: '11rem' }}>
                                <CardHeader
                                    title={
                                        <Typography sx={{ textAlign: 'center', color: '#F3B210', fontFamily: "'DM Sans', sans-serif", fontSize: '0.8rem', fontWeight: 600 }}>{item.title}</Typography>
                                    }
                                    sx={{ height: '2rem' }}
                                />
                                <CardContent>
                                    <Grid container spacing={2}>
                                        {item.categories.map((category) => {
                                            return <GamePlayerTagButton t={t} key={category.key} isSelected={selectedTag === category} tag={category} onShow={onShow} />;
                                        })}
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );
};

export default GamePlayerTagButtonList;
