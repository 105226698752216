import { Button, DialogContentText, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';

import GameService from '../../../services/game.service';

const SessionDialog = ({ open, onClose, allowed_count, user_id, sessions, refresh, t }) => {
    const [session, setSession] = useState('all');
    const [deleteOpen, setDeleteOpen] = useState(false);

    const handleDeleteClose = (flag) => {
        setDeleteOpen(false);
        if (flag) {
            GameService.clearUserSession({ user_id, session }).then(() => {
                refresh((r) => !r);
                onClose();
            });
        }
    };

    return (
        <div>
            <Dialog open={deleteOpen} onClose={(e) => handleDeleteClose(false)}>
                <DialogTitle>{t('confirmMsg')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{session === 'all' ? t('deleteSessionAllConformMsg') : t('deleteSessionConformMsg')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => handleDeleteClose(false)}>{t('Close')}</Button>
                    <Button onClick={(e) => handleDeleteClose(true)}>{t('Delete')}</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={open} onClose={onClose}>
                <DialogTitle>User Sessions ({allowed_count} sessions allowed)</DialogTitle>
                <DialogContent dividers style={{ width: '450px', display: 'flex', flexDirection: 'column', gap: '48px' }}>
                    <Table sx={{ minWidth: 250 }} aria-labelledby="tableTitle" size={'small'}>
                        <TableBody>
                            {sessions && sessions.length ? (
                                sessions.map((sess, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="left" className="fs-6 border-0">
                                            {sess}
                                        </TableCell>
                                        <TableCell align="center" sx={{ width: 50 }} className="border-0">
                                            <IconButton
                                                onClick={() => {
                                                    setSession(sess);
                                                    setDeleteOpen(true);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell align="center" className="fs-4 border-0">
                                        No Sessions
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={onClose}>
                        OK
                    </Button>
                    <Button
                        variant="outlined"
                        disabled={!sessions || !sessions.length}
                        onClick={() => {
                            setSession('all');
                            setDeleteOpen(true);
                        }}
                    >
                        {t('Clear All Sessions')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default SessionDialog;
