import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./PlayerDetails.css";
import "./assets/css/icons.css";

const style = {
  position: 'relative',
  width: '185px'
};

const Header = ({ onMenuChange, seasonList, onSeasonChange, selected ,disableSeason }) => {
  const findDefaultSeason = () => {
    return seasonList.find((element) => element.id === selected.id) || {};
  };

  const [selectedSeason, setSelectedSeason] = useState(findDefaultSeason());
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    setSelectedSeason(findDefaultSeason());
  }, [selected, seasonList]);

  const handleSelect = (season) => {
    setSelectedSeason(season);
    setMenuOpen(false);
    onSeasonChange(season);
  };

  const switchTo = (tabname) => {
    onMenuChange(tabname);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header>
      <ul className="dropdown">
        <li><Link onClick={() => switchTo('skills')}>Skills</Link></li>
        <li><Link onClick={() => switchTo('advance_stats')}>Advance Stats</Link></li>
        <li style={style}>
          <Link onClick={toggleMenu} className={(disableSeason ? 'disabled-link' : '')} >
            <span className="selected">{selectedSeason.name || 'Select Season'}</span>
            <i className={`bi ${menuOpen ? "bi-chevron-up" : "bi-chevron-down"}`}></i>
          </Link>
          {menuOpen && (
            <div className="dropdown-outer menu-open">
              <ul className="menu">
                {seasonList.map((season) => (
                  <li
                    key={season.id}
                    className={selectedSeason.id === season.id ? "active" : ""}
                    onClick={() => handleSelect(season)}
                  >
                    {season.name}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </li>
      </ul>
    </header>
  );
};

export default Header;
