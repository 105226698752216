import React, { useEffect, useState } from 'react';
import './PlayerDetails.css';
import { Link } from 'react-router-dom';
import GameService from '../../services/game.service';
import SeasonContext from '../../common/Context';
import { CircularProgress, Popover, Box, Divider } from '@mui/material';
import { ActionData } from '../../components/newcoach/components/common';
import { PLAYER_ICON_DEFAULT } from '../../common/staticData';
import { useSelector } from 'react-redux';
import PlayerImgComponent from './PlayerImgComponent';

const styles = {
    loader: {
        position: 'fixed',
        left: '0px',
        top: '0px',
        width: '100%',
        height: '100%',
        backgroundColor: '#fff',
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    play: {
        color: '#07863d'
    },
    paper: { minWidth: '98%', maxHeight: 'none', backgroundColor: 'transparent' }
};
const AdvanceStats = ({ player, gameList, onMenuChange, seasonFilter,playVideo ,t,playVideoTag}) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const statList = [
        { id: 'goal', title: 'Goals', action: 'Goal' },
        { id: 'shot', title: 'Shots', action: 'GoalKick' },
        { id: 'penalties', title: 'Penalties', action: 'Penalty' },
        { id: 'penalties_missed', title: 'Penalties Missed', action: 'PenaltyMissed' },
        { id: 'shot_on_target', title: 'Shots On Target', action: 'GoalOpportunity' },
        { id: 'shot_off_target', title: 'Shots Off Target', action: 'ShotOffTarget' },
        { id: 'shot_on_box', title: 'Shots In The Box', action: 'GoalKick' },
        { id: 'shot_out_of_box', title: 'Shots Out Of The Box', action: 'GoalKick' },
        { id: 'dribble', title: 'Dribbles', action: 'Dribble' },
        { id: 'dribble_successful', title: 'Successful Dribbles', action: 'DribbleSuccess' },
        { id: 'crosses', title: 'Crosses', action: 'Cross' },
        { id: 'free_kick', title: 'Free Kicks', action: 'FreeKick' },
        { id: 'corner', title: 'Corners', action: 'Corner' },
        { id: 'passes', title: 'Passes', action: 'Passes' },
        { id: 'successful_passes', title: 'Successful Passes', action: 'PassesSuccess' },
        { id: 'passes_for_shots', title: 'Passes For Shots', action: 'PassesShots' },
        { id: 'key_passes', title: 'Key Passes', action: 'KeyPass' },
        { id: 'through_passes', title: 'Through Passes', action: 'ThroughPass' },
        { id: 'turnover', title: 'Turnovers', action: 'Turnover' },
        { id: 'offside', title: 'Offsides', action: 'Offside' },
        { id: 'draw_fouls', title: 'Draw Fouls', action: 'DrawFoul' },
        { id: 'tackle', title: 'Tackles', action: 'Tackle' },
        { id: 'interception', title: 'Interceptions', action: 'Interception' },
        { id: 'saved', title: 'Saved', action: 'Saved' },
        { id: 'clearance', title: 'Clearance', action: 'Clearance' },
        { id: 'blocked', title: 'Blocked', action: 'Blocked' },
        { id: 'fouls', title: 'Fouls', action: 'Foul' },
        { id: 'yellow_cards', title: 'Yellow Cards', action: 'YellowCard' },
        { id: 'red_cards', title: 'Red Cards', action: 'RedCard' },
        { id: 'player_games', title: 'Games', action: '' }
    ];

    const goalkeeper = [
        { id: 'passes', title: 'Passes', action: 'Passes' },
        { id: 'successful_passes', title: 'Successful Passes', action: 'PassesSuccess' },
        { id: 'short_passes', title: 'Short Passes', action: 'ShortPass' },
        { id: 'long_passes', title: 'Long Passes', action: 'LongPass' },
        { id: 'build_ups', title: 'Build Ups', action: 'BuildUp' },
        { id: 'super_save', title: 'Super Saved', action: 'SuperSaved' },
        { id: 'saved', title: 'Saved', action: 'Saved' },
        { id: 'goalkeeper_exit', title: 'Exits', action: 'Exits' },
        { id: 'air_challenge', title: 'Air Challenges', action: 'AirChallenge' },
        { id: 'ground_challenge', title: 'Ground Challenges', action: 'GroundChallenge' },
        { id: 'one_vs_one', title: '1 vs 1', action: 'One' },
        { id: 'goal_received', title: 'Goals Received', action: 'GoalReceive' },
        { id: 'tackle', title: 'Tackles', action: 'Tackle' },
        { id: 'interception', title: 'Interceptions', action: 'Interception' },
        { id: 'clearance', title: 'Clearance', action: 'Clearance' },
        { id: 'fouls', title: 'Fouls', action: 'Foul' },
        { id: 'draw_fouls', title: 'Draw Fouls', action: 'DrawFoul' },
        { id: 'red_cards', title: 'Red Cards', action: 'RedCard' },
        { id: 'yellow_cards', title: 'Yellow Cards', action: 'YellowCard' },
        { id: 'player_games', title: 'Games Played', action: '' }
    ];

    const [loading, setLoading] = useState(true);
    const [gameIds, setGameIds] = useState(gameList.map((game) => game.game_id));
    const [playerState, setPlayerState] = useState(null);
    const [gameTime, setGameTime] = useState(['1', '2', '3', '4', '5', '6']);
    const [courtAreaId, setCourtAreaId] = useState(['1', '2', '3', '4']);
    const [insidePaint, setInsidePaint] = useState(null);
    const [homeAway, setHomeAway] = useState(null);
    const [gameResult, setgameResult] = useState(null);

    const arr_stat_profile = ['player_games', 'yellow_cards', 'red_cards'];
    const [multipleIds, setMultipleIds] = useState([]);

    useEffect(() => {
        setLoading(true);
        getData();
        setLoading(false);
    }, [homeAway, gameResult, courtAreaId, gameTime, seasonFilter]);
    const getData = () => {
        GameService.getPlayersStatsAdvanceSummary({
            seasonId: seasonFilter === 'none' ? null : seasonFilter.id,
            leagueId: null,
            gameId: gameList.length>0?gameList.map((game) => game.game_id).join(','):null,
            teamId: null,
            playerId: player.player_id,
            gameTime: gameTime.length>0?gameTime.join(','):null,
            courtAreaId: courtAreaId.length>0?courtAreaId.join(','):null,
            insidePaint: insidePaint,
            homeAway: homeAway,
            gameResult: gameResult
        }).then((res) => {
            setPlayerState(res[0]);
        });
    };
    const switchTo = () => {
        onMenuChange('details');
    };
    const handleCourtIdChange = (event) => {
        setCourtAreaId(addOrRemove([...courtAreaId], event.target.value));
    };
    const handleGameTimeChange = (event) => {
        var newList = addOrRemove([...gameTime], event.target.value);
        setGameTime(newList);
    };
    const addOrRemove = (dataArr, itm) => {
        const newList = dataArr.includes(itm) ? dataArr.filter((item) => item !== itm) : [...dataArr, itm];
        return newList;
    };
    const removeResult = (event) => {
        var val = event.target.value;
        if (gameResult === val) setgameResult(null);
        else setgameResult(val);
    };
    const removeHomeAway = (event) => {
        var val = event.target.value;
        if (homeAway === val) setHomeAway(null);
        else setHomeAway(val);
    };

    const handleDisplayVideo = (cell) => async (e) => {
        if (playerState && playerState[`total_${cell.id}`] > 0 && cell.title !== 'Games') {
            setMultipleIds([]);
            try {
                const res = await GameService.getGamePlayerTags(
                    2,
                    null,
                    `${player.player_id}`,
                    !gameIds || (gameIds && gameIds.length === 0) ? null : gameIds.join(','),
                    ActionData[cell.action].action_id,
                    ActionData[cell.action].action_type_id,
                    ActionData[cell.action].action_result_id,
                    gameTime.length === 0 ? null : gameTime.join(','),
                    courtAreaId.length === 0 ? null : courtAreaId.join(','),
                    null,
                    gameResult ? parseInt(gameResult) : null,
                    homeAway ? parseInt(homeAway) : null );
                const data =
                    cell.title === 'Shots In The Box'
                        ? res.filter((item) => item.inside_the_pain === true)
                        : cell.title === 'Shots Out Of The Box'
                        ? res.filter((item) => item.inside_the_pain === false)
                        : res;

                const newData = data.map((item) => ({
                    tag_id: item.id,
                    start_time: item.player_tag_start_time,
                    end_time: item.player_tag_end_time,
                    player_name: item.player_names,
                    action_name: item.action_names,
                    action_type: item.action_type_names,
                    action_result: item.action_result_names,
                    game_id: item.game_id,
                    team_id: null,
                    court_area: item.court_area_id,
                    inside_pain: item.inside_the_pain,
                    period: getPeriod(item.period),
                    time: item.time_in_game,
                    home_team_image: item.home_team_logo,
                    away_team_image: item.away_team_logo,
                    home_team_goals: item.home_team_goal,
                    away_team_goals: item.away_team_goal
                }));

                if(newData.length>0){
                    playVideoTag({game: gameList[0],tagList:newData})
                }
            } catch (error) {
                console.error(error);
            }
        }
    };
    function getPeriod(id) {
        return id === 1?'H1':id===2?'H2':'OT';
    }
    return (
        <section className="container">
            {loading && (
                <div style={styles.loader}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <div className="player-row-outer">
                    <div className="back-button w-100">
                        <h3>Advance Stats</h3>
                        <Link onClick={() => switchTo()}>
                            <span className="bi bi-arrow-left-circle"></span> Back
                        </Link>
                    </div>
                    <div className="player-profile" >
                        <div className="multi-col check-col home-town-select">
                            <div className="match-period">
                                <h3>Game Place</h3>
                            </div>
                            <div className="option-col">
                                <input type="checkbox" className="input-hide-checkbox" id="ra04" name="gameResult01" value="1" onChange={(e) => removeHomeAway(e)} checked={homeAway === '1'} />
                                <label htmlFor="ra04">
                                    <span className="bi bi-house-fill"></span> Home Games
                                </label>
                            </div>
                            <div className="option-col">
                                <input type="checkbox" className="input-hide-checkbox" id="ra05" name="gameResult02" value="2" onChange={(e) => removeHomeAway(e)} checked={homeAway === '2'} />
                                <label htmlFor="ra05">
                                    <span className="bi bi-airplane-engines-fill"></span> Away Games
                                </label>
                            </div>
                        </div>
                        <div className="multi-col check-col home-town-select home-won-select">
                            <div className="match-period">
                                <h3>Game Result</h3>
                            </div>
                            <div className="option-col">
                                <input type="checkbox" className="input-hide-checkbox" id="ra01" name="gameResult" value="1" onChange={(e) => removeResult(e)} checked={gameResult === '1'} />
                                <label htmlFor="ra01">
                                    <span className="bi bi-trophy"></span> Won
                                </label>
                            </div>
                            <div className="option-col">
                                <input type="checkbox" className="input-hide-checkbox" id="ra02" name="gameResult2" value="2" onChange={(e) => removeResult(e)} checked={gameResult === '2'} />
                                <label htmlFor="ra02">
                                    <span className="draw">
                                        <img src="../../draw-icon.png" alt="" />
                                    </span>
                                    Draw
                                </label>
                            </div>
                            <div className="option-col">
                                <input type="checkbox" className="input-hide-checkbox" id="ra03" name="gameResult3" value="3" onChange={(e) => removeResult(e)} checked={gameResult === '3'} />
                                <label htmlFor="ra03">
                                    <span className="bi bi-trophy lose"></span> Lose
                                </label>
                            </div>
                        </div>
                        <div className="ground-option">
                            <input type="checkbox" className="input-hide-checkbox" id="gr01" name="gameResult03" value="4" onChange={handleCourtIdChange} checked={courtAreaId.includes('4')} />
                            <label className="ground-label" htmlFor="gr01"></label>
                            <input type="checkbox" className="input-hide-checkbox" id="gr02" name="gameResult03" value="3" onChange={handleCourtIdChange} checked={courtAreaId.includes('3')} />
                            <label className="ground-label" htmlFor="gr02"></label>
                            <input type="checkbox" className="input-hide-checkbox" id="gr03" name="gameResult03" value="2" onChange={handleCourtIdChange} checked={courtAreaId.includes('2')} />
                            <label className="ground-label" htmlFor="gr03"></label>
                            <input type="checkbox" className="input-hide-checkbox" id="gr04" name="gameResult03" value="1" onChange={handleCourtIdChange} checked={courtAreaId.includes('1')} />
                            <label className="ground-label" htmlFor="gr04"></label>
                        </div>
                        <div className="match-period">
                            <h3>Match Period</h3>
                        </div>
                        <div className="pieContainer multi-col check-col">
                            <div className="pieBackground"></div>
                            <label htmlFor="cho01" className="lableValue lableValue01">
                                1-15'
                            </label>
                            <div id="pieSlice1" className="hold">
                                <input type="checkbox" className="input-hide-checkbox" id="cho01" name="gameResult04" value="1" onChange={handleGameTimeChange} checked={gameTime.includes('1')} />
                                <label className="pie textpie"></label>
                            </div>
                            <label htmlFor="cho02" className="lableValue lableValue02">
                                16-30'
                            </label>
                            <div id="pieSlice2" className="hold">
                                <input type="checkbox" className="input-hide-checkbox" id="cho02" name="gameResult04" value="2" onChange={handleGameTimeChange} checked={gameTime.includes('2')} />
                                <label className="pie textpie"></label>
                            </div>
                            <label htmlFor="cho03" className="lableValue lableValue03">
                                31-45'+
                            </label>
                            <div id="pieSlice3" className="hold">
                                <input type="checkbox" className="input-hide-checkbox" id="cho03" name="gameResult04" value="3" onChange={handleGameTimeChange} checked={gameTime.includes('3')} />
                                <label className="pie textpie"></label>
                            </div>
                            <label htmlFor="cho04" className="lableValue lableValue04">
                                46-60'
                            </label>
                            <div id="pieSlice4" className="hold">
                                <input type="checkbox" className="input-hide-checkbox" id="cho04" name="gameResult04" value="4" onChange={handleGameTimeChange} checked={gameTime.includes('4')} />
                                <label className="pie textpie"></label>
                            </div>
                            <label htmlFor="cho05" className="lableValue lableValue05">
                                61-75'
                            </label>
                            <div id="pieSlice5" className="hold">
                                <input type="checkbox" className="input-hide-checkbox" id="cho05" name="gameResult04" value="5" onChange={handleGameTimeChange} checked={gameTime.includes('5')} />
                                <label className="pie textpie"></label>
                            </div>
                            <label htmlFor="cho06" className="lableValue lableValue06">
                                76-90'+
                            </label>
                            <div id="pieSlice6" className="hold">
                                <input type="checkbox" className="input-hide-checkbox" id="cho06" name="gameResult04" value="6" onChange={handleGameTimeChange} checked={gameTime.includes('6')} />
                                <label className="pie textpie"></label>
                            </div>
                        </div>
                    </div>

                    <div className="player-profile profile-photo" >
                        {/* <div className="player-photo">
                            <img src={player.player_image ? player.player_image.includes(process.env.REACT_APP_S3_URI) ? player.player_image : process.env.REACT_APP_S3_URI + player.player_image : PLAYER_ICON_DEFAULT} alt="" />
                        </div> */}
                         <PlayerImgComponent
                                player={playerState}
                            />

                        <div className="player-minuties-row">
                            <div className="match-period">
                                <h3>From All Games</h3>
                            </div>
                            <div className="player-minutes">
                                <div className="ad-minutes-detail">
                                    <p>
                                        Total Minutes Played: <span>{playerState?.total_games_player_minutes}</span>
                                    </p>
                                    <p>
                                        Total Games Played: <span>{playerState?.total_games_player_played}</span>
                                    </p>
                                    <p>
                                        Starting 11: <span>{playerState?.total_games_player_starting}</span>
                                    </p>
                                    <p>
                                        Average Minutes Per Game: <span>{playerState?.total_games_player_average_minutes}</span>
                                    </p>
                                    <p>
                                        Percentage Minutes Played: <span>{playerState?.total_games_player_percentage_minutes}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="player-minuties-row">
                            <div className="match-period">
                                <h3>From Selected Games</h3>
                            </div>
                            <div className="player-minutes">
                                <div className="ad-minutes-detail">
                                    <p>
                                        Total Minutes Played: <span>{playerState?.selected_games_player_minutes}</span>
                                    </p>
                                    <p>
                                        Total Games Played: <span>{playerState?.selected_games_player_played}</span>
                                    </p>
                                    <p>
                                        Starting 11: <span>{playerState?.selected_games_player_starting}</span>
                                    </p>
                                    <p>
                                        Average Minutes Per Game: <span>{playerState?.selected_games_player_average_minutes}</span>
                                    </p>
                                    <p>
                                        Percentage Minutes Played: <span>{playerState?.selected_games_player_percentage_minutes}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="player-profile player-actionOuter" >
                        <div className="match-period">
                            <h3>Game Actions</h3>
                        </div>
                        <div className="player-action-details">
                            {((player && player.player_position_name && player.player_position_name === 'Goalkeeper') || (player && player.pos_name && player.pos_name === 'Goalkeeper')
                                ? goalkeeper
                                : statList
                            ).map(
                                (item, index) =>
                                    !arr_stat_profile.includes(item.id) &&
                                    playerState &&
                                    playerState[`total_${item.id}`] > 0 && (
                                        <div className="action-col stats-data" onClick={handleDisplayVideo(item)} key={index}>
                                            <div className="action-img">
                                                <h5>{playerState ? playerState[`total_${item.id}`] : '0'}</h5>
                                            </div>
                                            <h3>{item.title}</h3>
                                            <span>{playerState ? playerState[`average_${item.id}`] : '0'} per game</span>
                                        </div>
                                    )
                            )}
                        </div>
                    </div>
                </div>
            )}
        </section>
    );
};

export default AdvanceStats;
