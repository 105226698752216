import { Box, Tooltip, Zoom } from '@mui/material';
import React, { useEffect, useState } from 'react';

import '../../../coach_style.css';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Label, LabelList } from 'recharts';  
import { Typography } from '@mui/material';  
 
const TeamStatsChart = ({ formationList }) => {  
  const [upperLimit, setUpperLimit] = useState(10);  
  
  useEffect(() => {  
    const maxPoints = formationList.reduce((max, formation) => {  
      return formation.team_points > max ? formation.team_points : max;  
    }, 0) || 10;  
    setUpperLimit(maxPoints+1);  
  }, [formationList]);

  const calculateTicks = (upperLimit) => {  
    const tickValues = [];  
    for (let i = 0; i <= upperLimit +1; i += 1) {  
      tickValues.push(i);  
    }  
    return tickValues;  
  };  
  
  const CustomTick = ({ x, y, payload }) => {  
    const words = payload.value.split(/\s+/);  
    return (  
      <g transform={`translate(${x},${y - 10})`}>  
        <text x={0} y={0} dy={16} textAnchor="middle" fill="#666" transform="rotate(0)">  
          {words.map((word, index) => (  
            <tspan key={index} x="0" dy={index * 16}>{word}</tspan>  
          ))}  
        </text>  
      </g>  
    );  
  };  
  
  return (  
    <Box sx={{ width: '100%', height: 350 }}>  
      <Typography variant="h6" gutterBottom>  
 
      </Typography>  
      <ResponsiveContainer width="100%" height="100%">  
        <BarChart  
          data={formationList}  
          margin={{  
            top: 5,  
            right: 30,  
            left: 20,  
            bottom: 5,  
          }}  
          barCategoryGap="10px"  
        >  
          <CartesianGrid stroke="none" />  
          <XAxis  
            dataKey="team_formation_name"  
            interval={0}  
            height={60}  
            tick={<CustomTick />}  
            tickLine={false}  
            tickMargin={50}  
          />  
          <YAxis  
            allowDecimals={false}  
            domain={[0, upperLimit]}  
            ticks={calculateTicks(upperLimit)}  
          >  
            <Label value="Points" position="insideLeft" angle={-90} style={{ textAnchor: 'middle' }} />  
          </YAxis>  
          <Bar dataKey="team_points" fill="#3f51b5" barSize={40} barGap="10px">  
          <LabelList dataKey="team_points" position="top" />    
          </Bar>    
        </BarChart>    
      </ResponsiveContainer>    
    </Box>    
  );    
};    
    
export default TeamStatsChart;    
