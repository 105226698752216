import React, { useEffect, useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';

import GameService from '../../../services/game.service';
import RenderTable from './RenderTable';
// import EditTagTable from './tagtable';
// import EditVideoPlayer from './videoplayer';
// import EditFolderTreeView from './treeview';

import Input from '@mui/material/Input';
import { FormControlLabel, Switch } from '@mui/material';

const Renders = ({ t }) => {
    // const [editTagList, setEditTagList] = useState([]);
    // const [curEdit, setCurEdit] = useState(null);
    // const [tagLoading, setTagLoading] = useState(false);
    // const [curTagIdx, setCurTagIdx] = useState(-1);

    // const handleClickRow = (index) => {
    //     setCurTagIdx(index);
    // };

    // const handleSort = async (rows, isDropped) => {
    //     if (!isDropped) await GameService.updateEditClipsSort(rows);
    //     else await handleUpdateTable();
    // };

    // const handleUpdateTable = async () => {
    //     await GameService.getEditClipsByUserEditId(curEdit.id).then((res) => {
    //         setEditTagList(res);
    //     });
    // };

    const [xmlList, setXmlList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [showDone, setShowDone] = useState(false);
    const [count, setCount] = useState(0);

    useEffect(() => {
        GameService.getUserXmlAll().then((res) => {
            setXmlList(res);
            setLoading(false);
        });
    }, [count]);

    const gameListUpdated = useCallback(() => {
        setCount(count + 1);
    }, [count]);

    // useEffect(() => {
    //     setCurTagIdx(0);
    // }, [editTagList]);

    return (
        <Box sx={{ width: '98%', margin: '0 auto' }}>
            <Box sx={{ width: '100%', padding: '24px 24px 24px 24px', display: 'flex', flexDirection: 'column', gap: '24px' }}>
                <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '1.4rem', fontWeight: 700, color: '#1a1b1d' }}>{t('Renders')}</Typography>
            </Box>
            <Box sx={{ display: 'flex', maxHeight: '85vh', height: '85vh', background: '#ffffff', overflowY: 'auto' }}>
                <div style={{ display: 'flex', padding: '10px 0' }}>
                    {/* <EditFolderTreeView t={t} setEdit={setCurEdit} isMain={true} entireHeight="95%" treeHeight="90%" /> */}
                    {/* <EditTagTable
                        t={t}
                        loading={tagLoading}
                        tagList={editTagList}
                        setIdx={handleClickRow}
                        selected={curTagIdx}
                        sort={handleSort}
                        name={curEdit?.name ?? ''}
                        update={handleUpdateTable}
                        showPlay={false}
                    /> */}
                </div>
                {/* <div style={{ position: 'absolute', zIndex: 10, padding: 10, display: 'flex' }}>
                    <Input sx={{ mx: 10 }} placeholder={t('Search')} value={search} onChange={(e) => setSearch(e.target.value)} />
                    <FormControlLabel
                        sx={{ margin: 0 }}
                        control={<Switch checked={showDone} onChange={() => setShowDone(!showDone)} inputProps={{ 'aria-label': 'controlled' }} />}
                        label={
                            <Typography color="black">
                                Hide Expired XMLs
                            </Typography>
                        }
                    />
                </div> */}
                <RenderTable rows={xmlList} gameListUpdated={gameListUpdated} search={search} loading={loading} setLoading={(v) => setLoading(v)} t={t} />
            </Box>
        </Box>
    );
};

export default Renders;
