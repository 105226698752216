import React, { useState, useRef, useEffect } from 'react';
//import S3 from 'react-aws-s3';
import { Box, CircularProgress } from '@mui/material';
import AWS from 'aws-sdk';
import CameraIcon from '@mui/icons-material/PhotoCameraOutlined';

const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
    }
    return result;
};

const UploadButton = ({ class_name, id_name, dirName, img, onURL, defaultImage }) => {
    const fileInput = useRef();
    const [image, setImage] = useState(img);
    const [loading, setLoading] = useState(false);
    const getImage = () => {
        return img && img.length > 0 ? img : defaultImage;
    };

    const handleUpload = () => {
        const file = fileInput.current.files[0];
        if (!file) return;

        AWS.config.update({
            region: process.env.REACT_APP_REGION,
            accessKeyId: process.env.REACT_APP_ACCESS_ID,
            secretAccessKey: process.env.REACT_APP_ACCESS_KEY
        });

        const s3 = new AWS.S3();
        const fileExtension = file.name.split('.').pop();
        const uniqueFileName = generateRandomString(22) + `.${fileExtension}`; // Generate a random filename // Generate a unique filename
        const params = {
            Bucket: process.env.REACT_APP_BUCKET_NAME,
            Key: `${dirName}/${uniqueFileName}`,
            Body: file,
            ACL: 'public-read' // If you want the file to be publicly accessible
        };

        setLoading(true);

        s3.upload(params, (err, data) => {
            if (err) {
                console.log('error', err);
                setLoading(false);
            } else {
                if (data) {
                    setImage(data.Location);
                    const path = data.Location.split('amazonaws.com')[1]; // Fixed split to include trailing slash
                    console.log(path);
                    onURL(path);
                }
                setLoading(false);
            }
        });
    };

    return (
        <Box className={class_name} sx={{ width: '140px', height: '140px', borderRadius: '15px', background: `url(${getImage()}) center center / cover no-repeat silver` }}>
            {loading && (
                <div style={{ width: '100%', height: '100%', zIndex: 9999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </div>
            )}
            <input accept="image/*" id={id_name} type="file" ref={fileInput} onChange={(e) => handleUpload()} style={{ display: 'none' }} />
            <label htmlFor={id_name} style={{ width: '100%', cursor: 'pointer' }}>
                <Box sx={{ background: 'transparent', height: '110px' }} />
                <Box
                    sx={{
                        width: '100%',
                        height: '30px',
                        backgroundColor: 'black',
                        borderRadius: '0 0 15px 15px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        'svg path': { fill: '#ffffff' }
                    }}
                >
                    <CameraIcon />
                </Box>
            </label>
        </Box>
    );
};

export default UploadButton;
