import * as React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { ButtonGroup } from '@mui/material';
// import Snackbar from '@mui/material/Snackbar';
// import EditIcon from '@mui/icons-material/Edit';
// import TagIcon from '@mui/icons-material/Tag';
import { visuallyHidden } from '@mui/utils';
// import randomString from 'randomstring';

import DownloadIcon from '@mui/icons-material/Download';
import MovieCreationIcon from '@mui/icons-material/MovieCreation';
// import { Button, CircularProgress } from '@material-ui/core';

import GameService from '../../../services/game.service';
// import VIDEO_ICON from '../../../assets/video_icon.jpg';
// import { TEAM_ICON_DEFAULT } from '../../../common/staticData';
import { getComparator, stableSort } from '../../newcoach/components/utilities';
// import { ErrorRounded } from '@mui/icons-material';
// import Game from '../../admin/GameTab';
import { processXml } from '../components/utilities';
// import axios from 'axios';
import * as settings from '../../../config/settings';
import { getUser } from '../../../common/utilities';
import ShareIcon from '@mui/icons-material/Share';
import MovieIcon from '@mui/icons-material/PlayCircle';

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, t } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    const headCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Edit Name'
        },
        {
            id: 'date_time',
            numeric: false,
            disablePadding: false,
            label: t('Date')
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: t('Status')
        }
    ];

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'desc'} onClick={createSortHandler(headCell.id)}>
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell> </TableCell>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};

export default function EnhancedTable({ rows, gameListUpdated, editCallBack, loading, setLoading, search, show_done, t }) {
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('date');
    const [selected, setSelected] = React.useState({});
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [, setOpen] = React.useState(false);
    const [, setAlertContent] = React.useState('');
    const [renderingIds, setRenderingIds] = React.useState([]);
    const API_URL = `${settings.APIBASEURL}/`;
    const user = getUser();

    const [showResultAlert, setShowResultAlert] = React.useState(false);
    const [downState, setDownSate] = React.useState('');
    const [downloading, setDownloading] = React.useState({});
    const [refreshloading, setRefreshloading] = React.useState(true);

    React.useEffect(() => {
        if (showResultAlert) {
            const timer = setTimeout(() => {
                setShowResultAlert(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [showResultAlert]);

    React.useEffect(() => {
        let intervalId;
        const fetchDataForId = (id) => {
            GameService.getxmlStatus(id)
                .then((response) => {
                    if (response.status === 2) {
                        gameListUpdated();
                        const newRenderingIds = renderingIds.filter((renderId) => renderId !== id);
                        setRenderingIds(newRenderingIds);
                        fetch(API_URL + `update-rendering-ids/${user.id}`, {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ renderingIds: newRenderingIds })
                        });
                    } else if (response.status !== 4) {
                        const newRenderingIds = renderingIds.filter((renderId) => renderId !== id);
                        setRenderingIds(newRenderingIds);
                        fetch(API_URL + `update-rendering-ids/${user.id}`, {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ renderingIds: newRenderingIds })
                        });
                        clearInterval(intervalId);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        };

        const fetchData = () => {
            if (renderingIds.length > 0) {
                renderingIds.forEach((id) => fetchDataForId(id));
            }
        };

        fetchData();
        if (renderingIds.length > 0) {
            intervalId = setInterval(fetchData, 5000);
            return () => clearInterval(intervalId);
        }
    }, [API_URL, gameListUpdated, user.id, renderingIds]);

    React.useEffect(() => {
        if (user) {
            fetch(API_URL + `get-rendering-ids/${user.id}`)
                .then((response) => response.json())
                .then((data) => {
                    if (data.message) {
                        console.error(data.message); // or handle it in another way
                    } else {
                        setRenderingIds(data);
                        setRefreshloading(false);
                    }
                });
        }
    }, [API_URL, user]);

    React.useEffect(() => {
        if (user && !refreshloading) {
            fetch(API_URL + `update-rendering-ids/${user.id}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ renderingIds: renderingIds })
            });
        }
    }, [API_URL, refreshloading, user, renderingIds]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'desc';
        setOrder(isAsc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDeleteClose = (result) => {
        setDeleteOpen(false);

        if (!result) return;
        setLoading(true);
        if (renderingIds.includes(selected.id)) {
            const newRenderingIds = renderingIds.filter((renderId) => renderId !== selected.id);
            setRenderingIds(newRenderingIds);
            // setRenderingId(null)
        }
        if (selected.status === 2) {
            GameService.deleteVideo(selected.id)
                .then((res) => {})
                .catch((err) => {})
                .finally(() => {
                    // Call deleteXml function after deleteVideo has completed, regardless of success or failure
                    GameService.deleteXml(selected.id).then(
                        (res) => {
                            if (res.result === 'success') {
                                gameListUpdated();
                            } else {
                                setOpen(true);
                                setAlertContent(res.message);
                                setLoading(false);
                            }
                        },
                        (error) => {
                            setOpen(true);
                            setAlertContent(error);
                            setLoading(false);
                        }
                    );
                });
        } else {
            GameService.deleteXml(selected.id).then(
                (res) => {
                    if (res.result === 'success') {
                        gameListUpdated();
                    } else {
                        setOpen(true);
                        setAlertContent(res.message);
                        setLoading(false);
                    }
                },
                (error) => {
                    setOpen(true);
                    setAlertContent(error);
                    setLoading(false);
                }
            );
        }
    };

    const getSortedList = () => {
        const array = stableSort(
            rows.filter((r) => r.name.toLowerCase().includes(search.toLowerCase()) || r.date_time.slice(0, 10).toString().toLowerCase().includes(search.toLowerCase())),
            getComparator(order, orderBy)
        ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

        return show_done ? array.filter((item) => !item.done_tagging) : array;
        // return array;
    };

    const handleMovieClick = (link) => {
        window.open(link, '_blank');
    };

    const download = (download_link, video_name, id) => {
    
        // Set downloading state to true
        setDownloading((prevState) => ({ ...prevState, [id]: true }));

        // Fetch the file from the URL
        fetch(download_link)
            .then(response => {
                // Check if the response is ok (status is in the range 200-299)
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.blob();
            })
            .then(blob => {
                // Create a URL for the blob
                const blobUrl = URL.createObjectURL(blob);
    
                // Create an anchor element and set the download attributes
                const a = document.createElement('a');
                a.href = blobUrl;
                a.download = video_name;
                document.body.appendChild(a); // Append anchor to body
    
                // Trigger the download by clicking the anchor element
                a.click();
    
                // Clean up by revoking the object URL and removing the anchor element
                a.remove();
                URL.revokeObjectURL(blobUrl);
    
                /// Set downloading state to false
                setDownloading((prevState) => ({ ...prevState, [id]: false }));
            })
            .catch(error => {
                console.error('Download failed:', error);
    
                // Update the downloading state in case of error
                setDownloading((prevState) => ({ ...prevState, [id]: false }));
            });
    };
    
    /*const download = async (download_link, video_name, id) => {
        try {
            // Set downloading state to true
            setDownloading((prevState) => ({ ...prevState, [id]: true }));
    
            // Fetch the video file
            const response = await fetch(download_link);
            
            // Check if the response is ok (status is in the range 200-299)
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            // Convert the response to a blob
            const blob = await response.blob();
    
            // Create a URL for the blob
            const url = URL.createObjectURL(blob);
    
            // Create an anchor element and set the download attributes
            const a = document.createElement('a');
            a.href = url;
            a.download = video_name;
            document.body.appendChild(a); // Append anchor to body
    
            // Trigger the download by clicking the anchor element
            a.click();
    
            // Clean up by revoking the object URL after a short delay
            setTimeout(() => {
                URL.revokeObjectURL(url);
                document.body.removeChild(a); // Remove the anchor element
            }, 100);
    
            // Set downloading state to false
            setDownloading((prevState) => ({ ...prevState, [id]: false }));
        } catch (error) {
            console.error('Download failed:', error);
    
            // Set downloading state to false in case of error
            setDownloading((prevState) => ({ ...prevState, [id]: false }));
        }
    };*/
    

    const render = (xml_txt, id) => {
        // setRenderingId(id);
        setRenderingIds((prevRenderingIds) => [...prevRenderingIds, id]);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xml_txt, 'text/xml');
        const name = xmlDoc.getElementsByTagName('Name')[0].childNodes[0].nodeValue;

        processXml(xml_txt, id, name)
            .then((res) => {
                // setShowResultAlert(true)
                // setDownState(res)
            })
            .catch((err) => {
                // setShowResultAlert(true)
                // setDownState(err)
            })
            .finally(() => {
                // gameListUpdated();
                // setRenderingId(null)
            });
    };

    const handleCopyLink = (link) => {
        navigator.clipboard
            .writeText(link)
            .then(() => {
                setShowResultAlert(true);
                setDownSate(link);
            })
            .catch((err) => {
                console.error('Failed to copy: ', err);
            });
    };

    return (
        <Box sx={{ width: '100%' }}>
            {showResultAlert && (
                <Alert severity="success" onClose={() => setShowResultAlert(false)}>
                    Link copied to clipboard!
                </Alert>
            )}
            {downState}
            <Dialog open={deleteOpen} onClose={(e) => handleDeleteClose(false)}>
                <DialogTitle>{t('confirmMsg')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t('deleteConformMsg')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => handleDeleteClose(false)}>{t('Close')}</Button>
                    <Button onClick={(e) => handleDeleteClose(true)}>{t('Delete')}</Button>
                </DialogActions>
            </Dialog>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <TableContainer>
                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'small'}>
                        <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={rows.length} t={t} />
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={9} align="center">
                                        <CircularProgress sx={{ my: '30vh' }} />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <>
                                    {getSortedList().map((row, index) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                <TableCell align="center">{row.name}</TableCell>
                                                <TableCell align="center">
                                                    {(() => {
                                                        let date = new Date(row.date_time.slice(0, 19));
                                                        let day = ('0' + date.getDate()).slice(-2);
                                                        let month = ('0' + (date.getMonth() + 1)).slice(-2);
                                                        let year = date.getFullYear();
                                                        let hours = date.getHours();
                                                        let minutes = ('0' + date.getMinutes()).slice(-2);
                                                        return day + '/' + month + '/' + year + ' ' + hours + ':' + minutes;
                                                    })()}
                                                </TableCell>
                                                <TableCell align="center" sx={{ width: 100 }}>
                                                    {row.status === 2 ? (
                                                        <Button
                                                            variant="outlined"
                                                            style={{
                                                                width: '160px',
                                                                height: '34px',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap'
                                                            }}
                                                            onClick={() => download(row.download_link, row.video_name, row.id)}
                                                            startIcon={<DownloadIcon />}
                                                            disabled={downloading[row.id]}
                                                        >
                                                            {downloading[row.id] ? t('Downloading...') : t('Download')}
                                                        </Button>
                                                    ) : (
                                                        // (renderingId === row.id ? (
                                                        //     <CircularProgress style={{width: "24px", height: "24px"}}   /> // This is the Material UI component for a spinning wheel
                                                        //   ) : (
                                                        <Button
                                                            variant="outlined"
                                                            style={{ width: '130px', height: '34px' }}
                                                            onClick={() => render(row.xml_txt, row.id)}
                                                            startIcon={<MovieCreationIcon />}
                                                            disabled={renderingIds.includes(row.id) || renderingIds.length > 0}
                                                        >
                                                            {renderingIds.includes(row.id) ? t('Processing') : t('Render')}
                                                        </Button>

                                                        //   ))
                                                    )}
                                                </TableCell>
                                                <TableCell align="center" sx={{ width: 70 }}>
                                                    <ButtonGroup size="small" aria-label="Small button group">
                                                        <IconButton aria-label="copy-link" color="primary" onClick={() => handleCopyLink(row.download_link)} disabled={downloading[row.id]}>
                                                            <ShareIcon />
                                                        </IconButton>
                                                        <IconButton aria-label="movie" color="primary" onClick={() => handleMovieClick(row.download_link)} disabled={downloading[row.id]}>
                                                            <MovieIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={() => {
                                                                setDeleteOpen(true);
                                                                setSelected(row);
                                                            }}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </ButtonGroup>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}
