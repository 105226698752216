import {
    SETTINGS_LOGIN_PANEL,
    SETTINGS_LOGIN_PANEL_CLOSE,
    SETTINGS_FORGOT_PASSWORD_PANEL,
    SETTINGS_FORGOT_PASSWORD_PANEL_CLOSE,
    SETTINGS_REGISTER_PANEL,
    SETTINGS_REGISTER_PANEL_CLOSE
} from './types';

export const openLoginPanel = () => ({
    type: SETTINGS_LOGIN_PANEL
});

export const closeLoginPanel = () => ({
    type: SETTINGS_LOGIN_PANEL_CLOSE
});

export const openForgotPanel = () => ({
    type: SETTINGS_FORGOT_PASSWORD_PANEL
});

export const closeForgotPanel = () => ({
    type: SETTINGS_FORGOT_PASSWORD_PANEL_CLOSE
});

export const openRegisterPanel = () => ({
    type: SETTINGS_REGISTER_PANEL
});

export const closeRegisterPanel = () => ({
    type: SETTINGS_REGISTER_PANEL_CLOSE
});
