import CryptoJS from 'crypto-js';
import builder from 'xmlbuilder';
import fileDownload from 'js-file-download';
import { SECRET } from '../config/settings';
import { DEMO } from './staticData';
import gameService from '../services/game.service';

export const createCommand = async (tagList, name) => {
    let rawVideoList = [...new Set(tagList.map((tag) => tag.video_url))];
    let videoList = await Promise.all(
        rawVideoList.map(async (url) => {
            if (url?.includes('youtube.com') || url?.includes('youtu.be')) {
                return (await gameService.getAsyncNewStreamURL(url)).result.video_url;
            }
            return url;
        })
    );

    let videos = videoList.map((tag, i) => {
        return {
            url: tag,
            SecondBoxText: tagList[0]?.player_fname ? `#${tagList[0]?.jersey} ${tagList[0]?.player_fname} ${tagList[0]?.player_lname}` : name
        };
    });

    let clips = tagList.map((tag, i) => {
        return {
            Video: rawVideoList.indexOf(tag.video_url) + 1,
            Trim: `${toSecond(tag.start_time)}:${toSecond(tag.end_time)}`,
            FirstBoxText: `${tag.action_name ?? 'Team Actions'}`
        };
    });

    let obj = {
        Render: {
            FileData: {
                Name: name,
                Format: 'mp4',
                Resolution: '1280x720',
                FPS: '60',
                Preset: 'ultrafast',
                FontFile: 'ArialBold.ttf',
                FontColor: '#ffffff',
                FontSize: '35',
                FirstBoxSize: '300x60',
                FirstBoxColor: '#808080@0.7',
                FirstBoxFormat: 'rgba',
                SeconBoxSize: '500x60',
                SecondBoxColor: '#FFA500@0.7',
                SecondBoxFormat: 'rgba',
                LogoURL: 'https://s4usitesimages.s3.amazonaws.com/images/JustSmallLogo.png'
            },
            Videos: { Video: videos },
            Clips: { Clip: clips }
        }
    };

    const command = builder.create(obj).end({ pretty: true });

    // const command = `ffmpeg - y - i "${url}" - f lavfi - i color = color =#808080@0.7: size = 300x60, format = rgba - f lavfi - i color = color = #FFA500@0.7: size = ${ !tagList[0]?.player_fname ? "540" : "340" } x60, format = rgba - i "https://s4usitesimages.s3.amazonaws.com/images/JustSmallLogo.png" - filter_complex "${tagList.map((tag, i) =>
    //   `[0:v]trim=${toSecond(tag.start_time)}:${toSecond(tag.end_time)},setpts=PTS-STARTPTS[v${i}];[1:v]drawtext=text='${tag.action_name ?? "Team Actions"}':fontfile=ArialBold.ttf:x=(w-text_w)/2:y=(h-text_h)/2:fontsize=37:fontcolor=white[trans_gray_tx${i ? i : ''}];[v${i}][trans_gray_tx${i ? i : ''}]overlay=shortest=1:x=20:y=H-h-20[v_${i}];[0:a]atrim=${toSecond(tag.start_time)}:${toSecond(tag.end_time)},asetpts=PTS-STARTPTS[a${i}];`).join("")
    //   }${tagList.map((tag, i) => `[v_${i}][a${i}]`).join("")
    //   }concat=n=${tagList.length}:v=1:a=1[outv][outa];[2:v]drawtext=text='${!tagList[0]?.player_fname ? name : `#${tagList[0]?.jersey} ${tagList[0]?.player_fname}`}':fontfile=ArialBold.ttf:x=(w-text_w)/2:y=(h-text_h)/2:fontsize=37:fontcolor=white[trans_org_tx];[outv][trans_org_tx]overlay=shortest=1:x=262:y=H-h-20[v_org];[v_org][3:v]overlay=W-w-20:H-h-20[v_t_logo]" -map "[v_t_logo]" -map "[outa]" -preset superfast "${name}.mp4"`

    fileDownload(command, `${name}.xml`);
};

export function toHHMMSS(data) {
    if (!data || data === '') return '00:00:00';
    let sec_num = parseInt(data, 10); // don't forget the second param
    if (sec_num < 0) sec_num = 0;
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - hours * 3600) / 60);
    let seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
        hours = '0' + hours;
    }
    if (minutes < 10) {
        minutes = '0' + minutes;
    }
    if (seconds < 10) {
        seconds = '0' + seconds;
    }
    return hours + ':' + minutes + ':' + seconds;
}

export function addSecToHHMMSS(str, sec) {
    return toHHMMSS(toSecond(str) + sec);
}

export function subSecToHHMMSS(str, sec) {
    return toHHMMSS(toSecond(str) - sec);
}

export function toSecond(data) {
    if (!data || data === '') return 0;
    let a = data.split(':'); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    return +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
}
export function getUser() {
    try {
        const user = localStorage.getItem('user') ? JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('user'), SECRET).toString(CryptoJS.enc.Utf8)) : null;
        return user;
    } catch (e) {
        console.error('loading user error');
        return null;
    }
}
export function setUser(user) {
    try {
        localStorage.setItem('user', CryptoJS.AES.encrypt(JSON.stringify(user), SECRET).toString());
    } catch (e) {
        console.error('saving user error');
    }
}

export function parseJwt(token) {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
}

export function divideTags(tagList) {
    let actions = {};
    tagList.forEach((tag) => {
        const actionKey = tag.action_name;
        const typeKey = tag.action_type_name;
        let success = actions?.[actionKey]?.[typeKey]?.success ?? [];
        let unsuccess = actions?.[actionKey]?.[typeKey]?.unsuccess ?? [];

        if (DEMO?.[actionKey]?.success.includes(tag.action_result_name)) {
            success = [...success, tag];
        } else {
            unsuccess = [...unsuccess, tag];
        }

        actions = {
            ...actions,
            [actionKey]: {
                ...actions?.[actionKey],
                [typeKey]: { success, unsuccess }
            }
        };
    });
    return actions;
}

export function filterSuccessTags(tagList) {
    let actions = {};
    tagList.forEach((tag) => {
        const actionKey = tag.action_name;
        let success = actions?.[actionKey] ?? [];

        if (DEMO?.[actionKey]?.success.includes(tag.action_result_name)) {
            success = [...success, tag];
            actions = {
                ...actions,
                [actionKey]: [success]
            };
        }
    });
    return actions;
}

export function getPercent(value, max) {
    return (value * 100) / max;
}

export function manualFilterForTags(tagList, playerId, t) {
    const filterData = [
        {
            title: t('Goal'),
            action: 1,
            action_type: null,
            action_result: [3]
        },
        {
            title: t('Assist'),
            action: 9,
            action_type: null,
            action_result: null
        },
        {
            title: t('Shot'),
            action: 1,
            action_type: null,
            action_result: [1]
        },
        {
            title: t('Cross'),
            action: 3,
            action_type: null,
            action_result: [4]
        },
        {
            title: t('Dribble'),
            action: 4,
            action_type: null,
            action_result: [4]
        },
        {
            title: t('ThroughPass'),
            action: 2,
            action_type: 6,
            action_result: [4]
        },
        {
            title: t('ShortPass'),
            action: 2,
            action_type: 4,
            action_result: [4]
        },
        {
            title: t('LongPass'),
            action: 2,
            action_type: 5,
            action_result: [4]
        },
        {
            title: t('KeyPass'),
            action: 2,
            action_type: 7,
            action_result: [4]
        },
        {
            title: t('HeaderPass'),
            action: 2,
            action_type: 3,
            action_result: [4]
        },
        {
            title: t('DrawFoul'),
            action: 6,
            action_type: null,
            action_result: [13, 14]
        },
        {
            title: t('Interception'),
            action: 10,
            action_type: null,
            action_result: null
        },
        {
            title: t('Clearance'),
            action: 11,
            action_type: null,
            action_result: null
        },
        {
            title: t('Saved'),
            action: 8,
            action_type: null,
            action_result: null
        },
        {
            title: t('ThrowIn'),
            action: 2,
            action_type: 14,
            action_result: [4]
        }
    ];
    return filterData
        .map((f) => {
            const total = tagList.filter(
                (tag) =>
                    tag.action_id === f.action &&
                    (f.action_type === null ? true : tag.action_type_id === f.action_type) &&
                    (f.action_result === null ? true : f.action_result.includes(tag.action_result_id))
            );
            return {
                title: f.title,
                success: total.filter((f) => f.player_id === playerId),
                total: total.length
            };
        })
        .filter((f) => f.success.length > 0);
}
export function filterAllTags(tagList, playerId) {
    let actions = {};
    const playerTag = tagList.filter((f) => f.player_id === playerId);
    playerTag.forEach((tag) => {
        const actionKey = tag.action_name;

        actions = {
            ...actions,
            [actionKey]: !actions?.[actionKey]
                ? {
                      total: 1,
                      success: []
                  }
                : {
                      ...actions?.[actionKey],
                      total: actions?.[actionKey].total + 1
                  }
        };

        let success = actions?.[actionKey]?.success ?? [];

        if (tag.player_id === playerId && DEMO?.[actionKey]?.success.includes(tag.action_result_name)) {
            success = [...success, tag];
            actions = {
                ...actions,
                [actionKey]: {
                    ...actions?.[actionKey],
                    success
                }
            };
        }
    });

    // then sort by success length

    return Object.keys(actions)
        .map((key) => {
            return { ...actions[key], action: key };
        })
        .sort((a, b) => b.total - a.total);
}

export const getSum = (dataArr) => {
    var sm = 0;
    dataArr.map(e => {
        sm = sm + e
    })
    return sm;
}

export const getList = (series) => {

    if (series) {
        const llst = []
        const slst = []
        const clst = []
        series.forEach(element => {
            if (element?.data > 0) {
                llst.push(element.name)
                slst.push(element.data)
                clst.push(element.color)
            }
        });
        return { seriesDonut: slst, labelsLst: llst, colorLst: clst }
    }
}


export const getSeriesData = (playerData) => {

    const one_vs_one_attacking = [
        { action_names: 'Dribble', action_type_names: '', action_result_names: 'Successful', value: 0, color: '#1F712D', tags: [] },
        { action_names: 'Dribble', action_type_names: '', action_result_names: 'Draw Foul', value: 0, color: '#185522', tags: [] },
        { action_names: 'Dribble', action_type_names: '', action_result_names: 'Deflected', value: 0, color: 'grey', tags: [] },
        { action_names: 'Dribble', action_type_names: '', action_result_names: 'Unsuccessful', value: 0, color: '#FF8C00', tags: [] },
        { action_names: 'Dribble', action_type_names: '', action_result_names: 'Stolen', value: 0, color: '#A50707', tags: [] }
    ];

    const one_vs_one_defending = [
        { action_names: 'Interception', action_type_names: '', action_result_names: 'Intercepted', value: 0, color: '#1F712D', tags: [] },
        { action_names: 'Tackle', action_type_names: '', action_result_names: 'Stolen', value: 0, color: '#185522', tags: [] },
        { action_names: 'Pass', action_type_names: '', action_result_names: 'Deflected', value: 0, color: 'grey', tags: [] },
        { action_names: 'Foul', action_type_names: '', action_result_names: 'Free Kick', value: 0, color: '#A50707', tags: [] }
    ];

    const passing_accuaracy = [
        { action_names: 'Pass', action_type_names: 'Key Pass,Pass For a Shot,Short Pass,Through Pass,Long Pass', action_result_names: 'Successful', value: 0, color: '#1F712D', tags: [] },
        { action_names: 'Pass', action_type_names: 'Key Pass,Pass For a Shot,Short Pass,Through Pass,Long Pass', action_result_names: 'Offside', value: 0, color: 'black', tags: [] },
        { action_names: 'Pass', action_type_names: 'Key Pass,Pass For a Shot,Short Pass,Through Pass,Long Pass', action_result_names: 'Deflected', value: 0, color: 'grey', tags: [] },
        { action_names: 'Pass', action_type_names: 'Key Pass,Pass For a Shot,Short Pass,Through Pass,Long Pass', action_result_names: 'Bad Pass', value: 0, color: '#A50707', tags: [] }
    ];

    const passing_types = [
        { action_names: 'Pass', action_type_names: 'Key Pass', action_result_names: '', value: 0, color: '#05389E', tags: [] },
        { action_names: 'Pass', action_type_names: 'Pass For a Shot', action_result_names: '', value: 0, color: '#148DB7', tags: [] },
        { action_names: 'Pass', action_type_names: 'Short Pass', action_result_names: '', value: 0, color: '#1F712D', tags: [] },
        { action_names: 'Pass', action_type_names: 'Through Pass', action_result_names: '', value: 0, color: '#FF8C00', tags: [] },
        { action_names: 'Pass', action_type_names: 'Long Pass', action_result_names: '', value: 0, color: '#F1C40F', tags: [] }
    ];

    const intercepting_types = [
        { action_names: 'Interception', action_type_names: 'Key Pass', action_result_names: 'Bad Pass,Deflected,Blocked,Stolen', value: 0, color: '#05389E', tags: [] },
        { action_names: 'Interception', action_type_names: 'Through Pass', action_result_names: 'Bad Pass,Deflected,Blocked,Stolen', value: 0, color: '#FF8C00', tags: [] },
        { action_names: 'Interception', action_type_names: 'Short Pass', action_result_names: 'Bad Pass,Deflected,Blocked,Stolen', value: 0, color: '#1F712D', tags: [] },
        { action_names: 'Interception', action_type_names: 'Long Pass', action_result_names: 'Bad Pass,Deflected,Blocked,Stolen', value: 0, color: '#F1C40F', tags: [] },
        { action_names: 'Interception', action_type_names: 'Throw-In', action_result_names: 'Bad Pass,Deflected,Blocked,Stolen', value: 0, color: '#A50707', tags: [] }
    ];

    const crossing_accuaracy = [
        { action_names: 'Cross', action_type_names: 'Right, Left', action_result_names: 'Successful', value: 0, color: '#1F712D', tags: [] },
        { action_names: 'Cross', action_type_names: 'Right, Left', action_result_names: 'Cleared', value: 0, color: '#148DB7', tags: [] },
        { action_names: 'Cross', action_type_names: 'Right, Left', action_result_names: 'Offside', value: 0, color: 'black', tags: [] },
        { action_names: 'Cross', action_type_names: 'Right, Left', action_result_names: 'Blocked', value: 0, color: '#FF8C00', tags: [] },
        { action_names: 'Cross', action_type_names: 'Right, Left', action_result_names: 'Unsuccessful', value: 0, color: '#A50707', tags: [] }
    ];

    const shooting_accuaracy = [
        { action_names: 'Shot', action_type_names: '', action_result_names: 'Goal', value: 0, color: '#148DB7', tags: [] },
        { action_names: 'Shot', action_type_names: '', action_result_names: 'On Target', value: 0, color: '#1F712D', tags: [] },
        { action_names: 'Shot', action_type_names: '', action_result_names: 'Blocked', value: 0, color: '#FF8C00', tags: [] },
        { action_names: 'Shot', action_type_names: '', action_result_names: 'Saved', value: 0, color: 'grey', tags: [] },
        { action_names: 'Shot', action_type_names: '', action_result_names: 'Off Target', value: 0, color: '#cc0000', tags: [] }
    ];

    const defensive_stops_types = [
        { action_names: 'Tackle', action_type_names: '', action_result_names: 'Stolen', value: 0, color: '#05389E', tags: [] },
        { action_names: 'Clearance', action_type_names: '', action_result_names: 'Cleared', value: 0, color: '#148DB7', tags: [] },
        { action_names: 'Blocked', action_type_names: '', action_result_names: 'Blocked', value: 0, color: '#FF8C00', tags: [] },
        { action_names: 'Deflected', action_type_names: '', action_result_names: 'Deflected', value: 0, color: 'grey', tags: [] }
    ];

    const one_vs_one_attacking_items = []
    const one_vs_one_defending_items = []
    const passing_accuaracy_items = []
    const passing_types_items = []
    const intercepting_types_items = []
    const crossing_accuaracy_items = []
    const shooting_accuaracy_items = []
    const defensive_stops_types_items = []

    playerData.forEach((ses) => {
        const one_vs_one_attacking_found = one_vs_one_attacking.find(act => act.action_names.includes(ses.action_names) && act.action_result_names.includes(ses.action_result_names))
        if (one_vs_one_attacking_found) {
            one_vs_one_attacking_found.value += 1;
            one_vs_one_attacking_found.tags.push(ses);
            one_vs_one_attacking_items.push(ses)
        }
        const one_vs_one_defending_found = one_vs_one_defending.find(act => act.action_names.includes(ses.action_names) && act.action_result_names.includes(ses.action_result_names))
        if (one_vs_one_defending_found) {
            one_vs_one_defending_found.value += 1;
            one_vs_one_defending_found.tags.push(ses);
            one_vs_one_defending_items.push(ses)
        }
        const passing_accuaracy_found = passing_accuaracy.find(act => act.action_names.includes(ses.action_names) && act.action_type_names.includes(ses.action_type_names) && act.action_result_names.includes(ses.action_result_names))
        if (passing_accuaracy_found) {
            passing_accuaracy_found.value += 1;
            passing_accuaracy_found.tags.push(ses);
            passing_accuaracy_items.push(ses);
        }
        const passing_types_found = passing_types.find(act => act.action_names.includes(ses.action_names) && act.action_type_names.includes(ses.action_type_names))
        if (passing_types_found) {
            passing_types_found.value += 1;
            passing_types_found.tags.push(ses);
            passing_types_items.push(ses);
        }
        const intercepting_types_found = intercepting_types.find(act => act.action_names.includes(ses.action_names) && act.action_type_names.includes(ses.action_type_names) && act.action_result_names.includes(ses.action_result_names))
        if (intercepting_types_found) {
            intercepting_types_found.value += 1;
            intercepting_types_found.tags.push(ses);
            intercepting_types_items.push(ses);
        }

        const crossing_accuaracy_found = crossing_accuaracy.find(act => act.action_names.includes(ses.action_names) && act.action_type_names.includes(ses.action_type_names) && act.action_result_names.includes(ses.action_result_names))
        if (crossing_accuaracy_found) {
            crossing_accuaracy_found.value += 1;
            crossing_accuaracy_found.tags.push(ses);
            crossing_accuaracy_items.push(ses);
        }

        const shooting_accuaracy_found = shooting_accuaracy.find(act => act.action_names.includes(ses.action_names) && act.action_result_names.includes(ses.action_result_names))
        if (shooting_accuaracy_found) {
            shooting_accuaracy_found.value += 1;
            shooting_accuaracy_found.tags.push(ses);
            shooting_accuaracy_items.push(ses);
        }
        const defensive_stops_types_found = defensive_stops_types.find(act => act.action_names.includes(ses.action_names) && act.action_result_names.includes(ses.action_result_names))
        if (defensive_stops_types_found) {
            defensive_stops_types_found.value += 1;
            defensive_stops_types_found.tags.push(ses);
            defensive_stops_types_items.push(ses);
        }
    });

    return [
        {
            title: '1v1 Attacking',
            total: getSum(one_vs_one_attacking.map(act => act.value)),
            tags: one_vs_one_attacking_items,
            seriesBar: [
                {
                    name: 'Successful',
                    data: one_vs_one_attacking.find(act => act.action_result_names === "Successful").value,
                    tags: one_vs_one_attacking.find(act => act.action_result_names === "Successful").tags,
                    color: one_vs_one_attacking.find(act => act.action_result_names === "Successful").color
                },
                {
                    name: 'Draw Foul',
                    data: one_vs_one_attacking.find(act => act.action_result_names === "Draw Foul").value,
                    tags: one_vs_one_attacking.find(act => act.action_result_names === "Draw Foul").tags,
                    color: one_vs_one_attacking.find(act => act.action_result_names === "Draw Foul").color
                },
                {
                    name: 'Deflected',
                    data: one_vs_one_attacking.find(act => act.action_result_names === "Deflected").value,
                    tags: one_vs_one_attacking.find(act => act.action_result_names === "Deflected").tags,
                    color: one_vs_one_attacking.find(act => act.action_result_names === "Deflected").color
                },
                {
                    name: 'Unsuccessful',
                    data: one_vs_one_attacking.find(act => act.action_result_names === "Unsuccessful").value,
                    tags: one_vs_one_attacking.find(act => act.action_result_names === "Unsuccessful").tags,
                    color: one_vs_one_attacking.find(act => act.action_result_names === "Unsuccessful").color
                },
                {
                    name: 'Stolen',
                    data: one_vs_one_attacking.find(act => act.action_result_names === "Stolen").value,
                    tags: one_vs_one_attacking.find(act => act.action_result_names === "Stolen").tags,
                    color: one_vs_one_attacking.find(act => act.action_result_names === "Stolen").color
                }
            ]
        }, //1
        {
            title: '1v1 Defending',
            total: getSum(one_vs_one_defending.map(act => act.value)),
            tags: one_vs_one_defending_items,
            seriesBar: [
                {
                    name: 'Intercepted',
                    data: one_vs_one_defending.find(act => act.action_result_names === "Intercepted").value,
                    tags: one_vs_one_defending.find(act => act.action_result_names === "Intercepted").tags,
                    color: one_vs_one_defending.find(act => act.action_result_names === "Intercepted").color
                },
                {
                    name: 'Stolen',
                    data: one_vs_one_defending.find(act => act.action_result_names === "Stolen").value,
                    tags: one_vs_one_defending.find(act => act.action_result_names === "Stolen").tags,
                    color: one_vs_one_defending.find(act => act.action_result_names === "Stolen").color
                },
                {
                    name: 'Deflected',
                    data: one_vs_one_defending.find(act => act.action_result_names === "Deflected").value,
                    tags: one_vs_one_defending.find(act => act.action_result_names === "Deflected").tags,
                    color: one_vs_one_defending.find(act => act.action_result_names === "Deflected").color
                },
                {
                    name: 'Foul Given',
                    data: one_vs_one_defending.find(act => act.action_result_names === "Free Kick").value,
                    tags: one_vs_one_defending.find(act => act.action_result_names === "Free Kick").tags,
                    color: one_vs_one_defending.find(act => act.action_result_names === "Free Kick").color
                }
            ]
        }, //2
        {
            title: 'Passing Accuracy',
            total: getSum(passing_accuaracy.map(act => act.value)),
            tags: passing_accuaracy_items,
            seriesBar: [
                {
                    name: 'Successful',
                    data: passing_accuaracy.find(act => act.action_result_names === "Successful").value,
                    tags: passing_accuaracy.find(act => act.action_result_names === "Successful").tags,
                    color: passing_accuaracy.find(act => act.action_result_names === "Successful").color
                },
                {
                    name: 'Offside',
                    data: passing_accuaracy.find(act => act.action_result_names === "Offside").value,
                    tags: passing_accuaracy.find(act => act.action_result_names === "Offside").tags,
                    color: passing_accuaracy.find(act => act.action_result_names === "Offside").color
                },
                {
                    name: 'Deflected',
                    data: passing_accuaracy.find(act => act.action_result_names === "Deflected").value,
                    tags: passing_accuaracy.find(act => act.action_result_names === "Deflected").tags,
                    color: passing_accuaracy.find(act => act.action_result_names === "Deflected").color
                },
                {
                    name: 'Unsuccessful',
                    data: passing_accuaracy.find(act => act.action_result_names === "Bad Pass").value,
                    tags: passing_accuaracy.find(act => act.action_result_names === "Bad Pass").tags,
                    color: passing_accuaracy.find(act => act.action_result_names === "Bad Pass").color
                }
            ]
        }, //3
        {
            title: 'Passing Types',
            total: getSum(passing_types.map(act => act.value)),
            tags: passing_types_items,
            seriesBar: [
                {
                    name: 'Key Pass',
                    data: passing_types.find(act => act.action_type_names === "Key Pass").value,
                    tags: passing_types.find(act => act.action_type_names === "Key Pass").tags,
                    color: passing_types.find(act => act.action_type_names === "Key Pass").color
                },
                {
                    name: 'Pass for a Shot',
                    data: passing_types.find(act => act.action_type_names === "Pass For a Shot").value,
                    tags: passing_types.find(act => act.action_type_names === "Pass For a Shot").tags,
                    color: passing_types.find(act => act.action_type_names === "Pass For a Shot").color
                },
                {
                    name: 'Short Pass',
                    data: passing_types.find(act => act.action_type_names === "Short Pass").value,
                    tags: passing_types.find(act => act.action_type_names === "Short Pass").tags,
                    color: passing_types.find(act => act.action_type_names === "Short Pass").color
                },
                {
                    name: 'Through Pass',
                    data: passing_types.find(act => act.action_type_names === "Through Pass").value,
                    tags: passing_types.find(act => act.action_type_names === "Through Pass").tags,
                    color: passing_types.find(act => act.action_type_names === "Through Pass").color
                },
                {
                    name: 'Long Pass',
                    data: passing_types.find(act => act.action_type_names === "Long Pass").value,
                    tags: passing_types.find(act => act.action_type_names === "Long Pass").tags,
                    color: passing_types.find(act => act.action_type_names === "Long Pass").color
                }
            ]
        },//6
        {
            title: 'Crossing Accuracy',
            total: getSum(crossing_accuaracy.map(act => act.value)),
            tags: crossing_accuaracy_items,
            seriesBar: [
                {
                    name: 'Successful',
                    data: crossing_accuaracy.find(act => act.action_result_names === "Successful").value,
                    tags: crossing_accuaracy.find(act => act.action_result_names === "Successful").tags,
                    color: crossing_accuaracy.find(act => act.action_result_names === "Successful").color
                },
                {
                    name: 'Cleared',
                    data: crossing_accuaracy.find(act => act.action_result_names === "Cleared").value,
                    tags: crossing_accuaracy.find(act => act.action_result_names === "Cleared").tags,
                    color: crossing_accuaracy.find(act => act.action_result_names === "Cleared").color
                },
                {
                    name: 'Offside',
                    data: crossing_accuaracy.find(act => act.action_result_names === "Offside").value,
                    tags: crossing_accuaracy.find(act => act.action_result_names === "Offside").tags,
                    color: crossing_accuaracy.find(act => act.action_result_names === "Offside").color
                },
                {
                    name: 'Blocked',
                    data: crossing_accuaracy.find(act => act.action_result_names === "Blocked").value,
                    tags: crossing_accuaracy.find(act => act.action_result_names === "Blocked").tags,
                    color: crossing_accuaracy.find(act => act.action_result_names === "Blocked").color
                },
                {
                    name: 'Unsucessful',
                    data: crossing_accuaracy.find(act => act.action_result_names === "Unsuccessful").value,
                    tags: crossing_accuaracy.find(act => act.action_result_names === "Unsuccessful").tags,
                    color: crossing_accuaracy.find(act => act.action_result_names === "Unsuccessful").color
                }
            ]
        },  //7
        {
            title: 'Shooting & Finishing',
            total: getSum(shooting_accuaracy.map(act => act.value)),
            tags: shooting_accuaracy_items,
            seriesBar: [
                {
                    name: 'Goal',
                    data: shooting_accuaracy.find(act => act.action_result_names === "Goal").value,
                    tags: shooting_accuaracy.find(act => act.action_result_names === "Goal").tags,
                    color: shooting_accuaracy.find(act => act.action_result_names === "Goal").color
                },
                {
                    name: 'On Target',
                    data: shooting_accuaracy.find(act => act.action_result_names === "On Target").value,
                    tags: shooting_accuaracy.find(act => act.action_result_names === "On Target").tags,
                    color: shooting_accuaracy.find(act => act.action_result_names === "On Target").color
                },
                {
                    name: 'Blocked',
                    data: shooting_accuaracy.find(act => act.action_result_names === "Blocked").value,
                    tags: shooting_accuaracy.find(act => act.action_result_names === "Blocked").tags,
                    color: shooting_accuaracy.find(act => act.action_result_names === "Blocked").color
                },
                {
                    name: 'Saved',
                    data: shooting_accuaracy.find(act => act.action_result_names === "Saved").value,
                    tags: shooting_accuaracy.find(act => act.action_result_names === "Saved").tags,
                    color: shooting_accuaracy.find(act => act.action_result_names === "Saved").color
                },
                {
                    name: 'Off Target',
                    data: shooting_accuaracy.find(act => act.action_result_names === "Off Target").value,
                    tags: shooting_accuaracy.find(act => act.action_result_names === "Off Target").tags,
                    color: shooting_accuaracy.find(act => act.action_result_names === "Off Target").color
                }
            ]
        }, //4
        {
            title: 'Intercepting Types',
            total: getSum(intercepting_types.map(act => act.value)),
            tags: intercepting_types_items,
            seriesBar: [
                {
                    name: 'Key Pass',
                    data: intercepting_types.find(act => act.action_type_names === "Key Pass").value,
                    tags: intercepting_types.find(act => act.action_type_names === "Key Pass").tags,
                    color: intercepting_types.find(act => act.action_type_names === "Key Pass").color
                },
                {
                    name: 'Through Pass',
                    data: intercepting_types.find(act => act.action_type_names === "Through Pass").value,
                    tags: intercepting_types.find(act => act.action_type_names === "Through Pass").tags,
                    color: intercepting_types.find(act => act.action_type_names === "Through Pass").color
                },
                {
                    name: 'Short Pass',
                    data: intercepting_types.find(act => act.action_type_names === "Short Pass").value,
                    tags: intercepting_types.find(act => act.action_type_names === "Short Pass").tags,
                    color: intercepting_types.find(act => act.action_type_names === "Short Pass").color
                },
                {
                    name: 'Long Pass',
                    data: intercepting_types.find(act => act.action_type_names === "Long Pass").value,
                    tags: intercepting_types.find(act => act.action_type_names === "Long Pass").tags,
                    color: intercepting_types.find(act => act.action_type_names === "Long Pass").color
                },
                {
                    name: 'Throw-In',
                    data: intercepting_types.find(act => act.action_type_names === "Throw-In").value,
                    tags: intercepting_types.find(act => act.action_type_names === "Throw-In").tags,
                    color: intercepting_types.find(act => act.action_type_names === "Throw-In").color
                }
            ]
        },//5
        {
            title: 'Defensive Stops',
            total: getSum(defensive_stops_types.map(act => act.value)),
            tags: defensive_stops_types_items,
            seriesBar: [
                {
                    name: 'Cleared',
                    data: defensive_stops_types.find(act => act.action_result_names === "Cleared").value,
                    tags: defensive_stops_types.find(act => act.action_result_names === "Cleared").tags,
                    color: defensive_stops_types.find(act => act.action_result_names === "Cleared").color
                },
                {
                    name: 'Blocked',
                    data: defensive_stops_types.find(act => act.action_result_names === "Blocked").value,
                    tags: defensive_stops_types.find(act => act.action_result_names === "Blocked").tags,
                    color: defensive_stops_types.find(act => act.action_result_names === "Blocked").color
                },
                {
                    name: 'Stolen',
                    data: defensive_stops_types.find(act => act.action_result_names === "Stolen").value,
                    tags: defensive_stops_types.find(act => act.action_result_names === "Stolen").tags,
                    color: defensive_stops_types.find(act => act.action_result_names === "Stolen").color
                },
                {
                    name: 'Deflected',
                    data: defensive_stops_types.find(act => act.action_result_names === "Deflected").value,
                    tags: defensive_stops_types.find(act => act.action_result_names === "Deflected").tags,
                    color: defensive_stops_types.find(act => act.action_result_names === "Deflected").color
                }
            ]
        } //8
    ]
}
