// Create a Redux store with Redux Toolkit
// Define a slice to manage the shared state
// Dispatch actions to update the state

// Example: coachSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    teamsList: [],
    seasonList: [],
    leagueList: [],
    gamesList: [],
    playersList: [],
    standingList: [],
    positions: [],
    roles: [],
    ageGroups: [],
    playersStatsAdvanceSummaryList: { all: [] }, // Object to store player stats by seasonId
    goalkeepersStatsAdvanceSummaryList: { all: [] } // Object to store goalkeeper stats by seasonId
};

const coachSlice = createSlice({
    name: 'coach',
    initialState,
    reducers: {
        setTeamsList(state, action) {
            state.teamsList = action.payload;
        },
        setSeasonList(state, action) {
            state.seasonList = action.payload;
        },
        setLeagueList(state, action) {
            state.leagueList = action.payload;
        },
        setPlayersList(state, action) {
            state.playersList = action.payload;
        },
        setGamesList(state, action) {
            state.gamesList = action.payload;
        },
        setStandingList(state, action) {
            state.standingList = action.payload;
        },
        setPositions(state, action) {
            state.positions = action.payload;
        },
        setRoles(state, action) {
            state.roles = action.payload;
        },
        setAgeGroups(state, action) {
            state.ageGroups = action.payload;
        },
        setPlayersStatsAdvanceSummaryList(state, action) {
            const { season_name, data } = action.payload;
            state.playersStatsAdvanceSummaryList[season_name] = data;
        },
        setGoalkeepersStatsAdvanceSummaryList(state, action) {
            const { season_name, data } = action.payload;
            state.goalkeepersStatsAdvanceSummaryList[season_name] = data;
        }
    }
});

export const {
    setTeamsList,
    setSeasonList,
    setLeagueList,
    setPlayersList,
    setGamesList,
    setStandingList,
    setPositions,
    setRoles,
    setAgeGroups,
    setPlayersStatsAdvanceSummaryList,
    setGoalkeepersStatsAdvanceSummaryList
} = coachSlice.actions;

export default coachSlice.reducer;
