import React, { useState, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import AWS from 'aws-sdk';
import { CircularProgress, Button } from '@mui/material';

const styles = {
    loader: {
        position: 'absolute',
        left: '0px',
        top: '0px',
        width: '100%',
        height: '100%',
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
};

const Input = styled('input')({
    display: 'none'
});

// Function to generate a random string
const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
    }
    return result;
};

function Upload({ dirName, img, onURL, fileName, defaultImg = '', btn_name = 'Upload' }) {
    const fileInput = useRef();
    const [image, setImage] = useState(img);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setImage(img);
    }, [img]);

    const handleUpload = () => {
        const file = fileInput.current.files[0];
        if (!file) return;

        AWS.config.update({
            region: process.env.REACT_APP_REGION,
            accessKeyId: process.env.REACT_APP_ACCESS_ID,
            secretAccessKey: process.env.REACT_APP_ACCESS_KEY
        });

        const s3 = new AWS.S3();
        const fileExtension = file.name.split('.').pop();
        const uniqueFileName = generateRandomString(22) + `.${fileExtension}`; // Generate a random filename // Generate a unique filename
        const params = {
            Bucket: process.env.REACT_APP_BUCKET_NAME,
            Key: `${dirName}/${uniqueFileName}`,
            Body: file,
            ACL: 'public-read' // If you want the file to be publicly accessible
        };

        setLoading(true);

        s3.upload(params, (err, data) => {
            if (err) {
                console.log('error', err);
                setLoading(false);
            } else {
                if (data) {
                    setImage(data.Location);
                    const path = data.Location.split('amazonaws.com')[1]; // Fixed split to include trailing slash
                    onURL(path);
                }
                setLoading(false);
            }
        });
    };

    return (
        <Box style={{ textAlign: 'center', width: 200, margin: 'auto' }}>
            <label htmlFor="contained-button-file">
                <Input accept="image/*" id="contained-button-file" type="file" ref={fileInput} onChange={(e) => handleUpload()} />
                <Button variant="contained" component="span">
                    {btn_name}
                </Button>
            </label>
            <br />
            <img className="upload-image-preview" src={image && image.length > 0 ? image : defaultImg} width="200" alt="img" style={{ border: '1px black solid' }}></img>
            {loading && (
                <div style={styles.loader}>
                    <CircularProgress />
                </div>
            )}
        </Box>
    );
}

export default Upload;
