import { SET_USER_SUBSCRIPTION } from '../actions/subscription';

const initialState = {
    subscription: {}, // Ensure initial state is set to null or an empty object
};

const subscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_SUBSCRIPTION:
            return {
                ...state,
                subscription: action.payload, // Set subscription payload here
            };
        default:
            return state;
    }
};

export default subscriptionReducer;
