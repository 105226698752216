import React, { useReducer, useEffect, useState } from 'react';
import { Box, Select, CircularProgress, MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined';
import TeamListItem from './teamListItem';
import SeasonContext from '../../../common/Context';
import { MenuProps } from '../components/common';
import { getFilteredArray } from '../../../helpers/coach';
import '../coach_style.css';

const Teams = ({ t }) => {
    const { seasonFilter } = React.useContext(SeasonContext);

    const [state, setState] = useReducer((old, action) => ({ ...old, ...action }), {
        hoverIndex: -1,
        leagueFilter: 'none',
        loading: false
    });
    const [loading, setLoading] = useState(false);
    const teamsList = useSelector((state) => state.coach.teamsList);
    const leagueList = useSelector((state) => state.coach.leagueList);
    const { hoverIndex, leagueFilter } = state;

    const [seasonLeaguesList, setSeasonLeaguesList] = useState([]);
    const [selectedTeams, setSelectedTeams] = useState([]);

    const handleChange = (prop) => (e) => {
        setState({ [prop]: e.target.value });
    };

    const handleMouseEnter = (idx) => {
        setState({ hoverIndex: idx });
    };

    const handleMouseLeave = () => {
        setState({ hoverIndex: -1 });
    };

    useEffect(() => {
        if (teamsList.length) {
            const filtered_arr = getFilteredArray(teamsList, seasonFilter, leagueFilter, null, null);
            setSelectedTeams(filtered_arr);
        } else {
            setSelectedTeams([]);
            setLoading(false);
        }
    }, [seasonLeaguesList, leagueFilter, teamsList, seasonFilter]);

    console.log("selectedTeams", selectedTeams);

    useEffect(() => {
        if (leagueList.length) {
            setSeasonLeaguesList(leagueList.filter((league) => league.season_id === seasonFilter.id));
        }
        setState({ leagueFilter: 'none' });
    }, [seasonFilter, leagueList]);

    return (
        <Box sx={{ width: '98%', margin: '0 auto', height: '85vh' }}>
            <Box sx={{ width: '100%', padding: '24px 24px 24px 24px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <p className="page-title" >{t('Club - All Teams')}</p>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '24px', justifyContent: 'flex-end', width: '100%' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <p className="select-narrator">{t('League')}</p>
                        <Select
                            value={leagueFilter}
                            onChange={handleChange('leagueFilter')}
                            label=""
                            variant="outlined"
                            IconComponent={ExpandMoreIcon}
                            inputProps={{ 'aria-label': 'Without label' }}
                            MenuProps={MenuProps}
                            sx={{ borderRadius: '10px', outline: 'none', height: '36px', width: '300px', fontSize: '0.8rem', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                        >
                            <MenuItem key="0" value="none">
                                {t('All')}
                            </MenuItem>
                            {seasonLeaguesList.map((league, index) => (
                                <MenuItem key={index + 1} value={league}>
                                    {league.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Box>
            </Box>
            {loading && (
                <div style={{ width: '100%', height: '100%', zIndex: 9999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <Box sx={{ overflowY: 'auto', minHeight: '85vh', maxHeight: '85vh', marginLeft: '10px' }}>
                    <Box sx={{ marginRight: '4px' }}>
                        {selectedTeams.map((team, index) => (
                            <Box key={`${team.id}-${index}`} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave}>
                                <TeamListItem team={team} t={t} isHover={hoverIndex === index}/>
                            </Box>
                        ))}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default Teams;
