import React, { useState, useEffect, useReducer } from 'react';
import { Box, MenuItem, Typography, Select, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined';
import { MenuProps, SaveButton } from '../../../components/common';
import GameService from '../../../../../services/game.service';
import GameVideoPlayerPos from './gameVideoPlayerPos';
import GameExportToEdits from './exportEditsPos';
import GameTagList from './tagListPos';
import GameTagControlSection from './tagControlSectionPos';
import { XmlDataFilterGame } from '../../../components/xmldata';
import { getPeriod } from './tagListItemPos';
import { gameCreateCommand } from '../../../components/utilities';
import Alert from '../../../../../common/alert';

const Possession = ({ game, t }) => {
    const [values, setValues] = useState({
        loading: false,
        hoverIndex: -1,
        curTab: 0,
        offensiveFilter: 'none',
        offensiveList: [],
        videoUrl: '',
        teamIds: [],
        startActionFilter: 'none',
        startActionList: [],
        endActionFilter: 'none',
        endActionList: [],
        selectAll: false
    });

    const [gameTime, setGameTime] = useState({
        period: 'H1',
        time: 0,
        video_url: '',
        home_team_goals: 0,
        away_team_goals: 0,
        home_team_image: game.home_team_image,
        away_team_image: game.away_team_image
    });
    const [exportHudl, setExportHudl] = useState(false);
    const [playerTagList, setPlayerTagList] = useState([]);
    const [exportEditOpen, setExportEditOpen] = useState(false);
    const [curTeamTagIdx, setCurTeamTagIdx] = useState(0);
    const [possessionData, setPosessionData] = useState([]);
    const [filteredPossession, setFilteredPossession] = useState([]);
    const [loading, setLoading] = useState(false);
    const [checkArray, setCheckArray] = useState([]);

    const [exportList, setExportList] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [showResultAlert, setShowResultAlert] = useState(false);
    const [tagIndex, setTagIndex] = useState('');
    const [downState, setDownState] = useState('');

    useEffect(() => {
        if (showAlert) {
            const timer = setTimeout(() => {
                setShowAlert(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [showAlert]);
    useEffect(() => {
        if (showResultAlert) {
            const timer = setTimeout(() => {
                setShowResultAlert(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [showResultAlert]);

    useEffect(() => {
        setCheckArray([]);
        filteredPossession.map((item, index) => {
            setCheckArray((oldRows) => [...oldRows, values.selectAll]);
        });
    }, [values.selectAll]);

    const handleShowVideo = (index) => {
        setCurTeamTagIdx(index);
        setVideoData({ ...videoData, idx: index, click: !videoData.click });
    };

    const handleCheckChange = (idx) => (e) => {
        setCheckArray({ ...checkArray, [idx]: e.target.checked });
    };

    const getUniqueValuesForKey = (dataArray, key) => {
        const uniqueValues = new Set(dataArray.map((item) => item[key]));
        return Array.from(uniqueValues);
    };

    const handleChange = (prop) => (e) => {
        setValues({ ...values, [prop]: e.target.value });
    };

    const getTime = (time, delta) => {
        const items = time.split(':');
        const changedTime = parseInt(items[0]) * 3600 + parseInt(items[1]) * 60 + parseInt(items[2]) + delta;
        let hour = Math.floor(changedTime / 3600);
        let minute = Math.floor((changedTime - hour * 3600) / 60);
        let second = changedTime - hour * 3600 - minute * 60;

        if (hour < 10) hour = '0' + hour;
        if (minute < 10) minute = '0' + minute;
        if (second < 10) second = '0' + second;

        return hour + ':' + minute + ':' + second;
    };

    const handleChangeTime = (index, isStart, direction) => {
        let array = [...filteredPossession];
        let data = [...videoData.tagList];
        if (isStart) {
            const changed = getTime(array[index].start_time, direction);

            array[index].start_time = changed;
            data[index].start_time = changed;
        } else {
            const changed = getTime(array[index].end_time, direction);

            array[index].end_time = changed;
            data[index].end_time = changed;
        }

        setValues({ ...values, playList: array });
        setVideoData({ ...videoData, tagList: data });
    };

    const changeGameTime = (array, idx) => {
        var timeString = array[idx].game_time;
        var parts = timeString.split(':');
        var minutes = parseInt(parts[0], 10);

        console.log(array);

        setGameTime({
            ...gameTime,
            period: getPeriod(array[idx].period),
            time: minutes ?? 0,
            home_team_goals: array[idx].home_team_goal ?? 0,
            away_team_goals: array[idx].away_team_goal ?? 0,
            home_team_image: game.home_team_image ?? 'https://s4usitesimages.s3.amazonaws.com/images/JustSmallLogo.png',
            away_team_image: game.away_team_image ?? 'https://s4usitesimages.s3.amazonaws.com/images/JustSmallLogo.png'
        });
    };

    const [videoData, setVideoData] = useReducer((old, action) => ({ ...old, ...action }), {
        idx: 0,
        autoPlay: true,
        tagList: [],
        videoPlay: true,
        click: false
    });

    const handleClickHudlFromButton = () => {
        const newList = filteredPossession.filter((item, index) => checkArray[index] === true);

        setPlayerTagList(newList);
        setExportHudl(true);
    };

    const handleClickRenderFromButton = async () => {
        const newList = filteredPossession.filter((item, index) => checkArray[index] === true);
        setShowAlert(true);
        const result = await gameCreateCommand(newList, tagIndex, game, game.id);

        setShowResultAlert(true);
        setDownState(result);
    };

    const handleClickEditFromButton = () => {
        const newList = filteredPossession.filter((item, index) => checkArray[index] === true);
        let tmp_exportList = [];
        newList.map((item, index) => {
            const clip_name = `${getPeriod(item.period)} - ${item.game_time}' - ${item.offensive_short_team_name ?? item.offensive_team_name} - Started: ${item.started_action_name} of ${
                item.started_player_name
            } - Ended: ${
                item.ended_action_id === 2 || item.ended_action_id === 7
                    ? item.ended_action_result_name
                    : item.ended_action_id === 6
                    ? item.ended_action_name
                    : item.ended_action_id === 3 || item.ended_action_id === 4
                    ? `${item.ended_action_result_name} ${item.ended_action_name}`
                    : item.ended_action_id === 1
                    ? `${item.ended_action_name} ${item.ended_action_result_name}`
                    : ''
            } of ${item.ended_player_name}`;
            tmp_exportList.push({ ...item, clip_name });
        });
        setExportList(tmp_exportList);
        setExportEditOpen(true);
    };

    useEffect(() => {
        setGameTime({ ...gameTime, video_url: game.video_url });

        const filteredItems = possessionData.filter(
            (item) =>
                (values.offensiveFilter == 'none' || item.offensive_team_name === values.offensiveList[values.offensiveFilter]) &&
                (values.startActionFilter == 'none' || item.started_action_name === values.startActionList[values.startActionFilter]) &&
                (values.endActionFilter == 'none' || item.ended_action_name === values.endActionList[values.endActionFilter])
        );

        if (filteredItems.length > 0) {
            setFilteredPossession(filteredItems);
            setVideoData({
                ...videoData,
                idx: 0,
                tagList: filteredItems.map((item) => {
                    return {
                        start_time: item.start_time,
                        end_time: item.end_time,
                        name: `${item.started_player_name} - ${item.started_action_name} - ${item.started_action_type_name} - ${item.started_action_result_name}`
                    };
                })
            });
            setValues({ ...values, playList: filteredItems });
            setCheckArray([]);
            filteredItems.map((item, index) => {
                setCheckArray((oldRows) => [...oldRows, false]);
            });

            if (filteredItems.length > 0) changeGameTime(filteredItems, 0);
        } else {
            setFilteredPossession(possessionData);
        }
    }, [values.offensiveFilter, values.startActionFilter, values.endActionFilter, possessionData]);

    useEffect(() => {
        setLoading(true);
        GameService.getGamePosessions(game.id).then((res) => {
            const arr_1 = getUniqueValuesForKey(res, 'started_action_name');
            const arr_2 = getUniqueValuesForKey(res, 'ended_action_name');
            setPosessionData(res);
            setLoading(false);
            setValues({
                ...values,
                videoUrl: game.video_url,
                offensiveList: [game.away_team_name, game.home_team_name],
                teamIds: [game.away_team_id, game.home_team_id],
                startActionList: arr_1,
                endActionList: arr_2,
                offensiveFilter: 'none'
            });
        });
    }, []);

    useEffect(() => {
        if (filteredPossession.length > 0) changeGameTime(filteredPossession, curTeamTagIdx);
    }, [curTeamTagIdx]);

    return (
        <Box sx={{ width: '98%', margin: '0 auto' }}>
            {showAlert && <Alert text={'Render started!'} />}
            {showResultAlert && <Alert text={downState} />}
            {values.loading && (
                <div style={{ width: '100%', height: '100%', zIndex: 9999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </div>
            )}
            {!values.loading && (
                <>
                    <Box sx={{ width: '100%', background: '#ffffff', maxHeight: '78vh', overflowY: 'auto', display: 'flex' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', padding: '24px 10px' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '24px' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', width: '100%' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '0.8rem', fontWeight: 500, color: '#1a1b1d', width: '85px' }}>{t('Offensive')}</Typography>
                                    <Select
                                        value={values.offensiveFilter}
                                        onChange={handleChange('offensiveFilter')}
                                        label=""
                                        variant="outlined"
                                        IconComponent={ExpandMoreIcon}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        MenuProps={MenuProps}
                                        sx={{ outline: 'none', height: '36px', width: '100%', fontSize: '0.8rem', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                    >
                                        <MenuItem key="0" value="none">
                                            {t('All')}
                                        </MenuItem>
                                        {values.offensiveList.map((team, index) => (
                                            <MenuItem key={index + 1} value={index}>
                                                {team}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '24px', marginTop: '15px' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '0.8rem', fontWeight: 500, color: '#1a1b1d' }}>{t('Started From')}</Typography>
                                    <Select
                                        value={values.startActionFilter}
                                        onChange={handleChange('startActionFilter')}
                                        label=""
                                        variant="outlined"
                                        IconComponent={ExpandMoreIcon}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        MenuProps={MenuProps}
                                        sx={{ outline: 'none', height: '36px', width: '240px', fontSize: '0.8rem', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                    >
                                        <MenuItem key="0" value="none">
                                            {t('All')}
                                        </MenuItem>
                                        {values.startActionList.map((action, index) => (
                                            <MenuItem key={index + 1} value={index}>
                                                {action}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '0.8rem', fontWeight: 500, color: '#1a1b1d' }}>{t('Ended Result')}</Typography>
                                    <Select
                                        value={values.endActionFilter}
                                        onChange={handleChange('endActionFilter')}
                                        label=""
                                        variant="outlined"
                                        IconComponent={ExpandMoreIcon}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        MenuProps={MenuProps}
                                        sx={{ outline: 'none', height: '36px', width: '240px', fontSize: '0.8rem', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                    >
                                        <MenuItem key="0" value="none">
                                            {t('All')}
                                        </MenuItem>
                                        {values.endActionList.map((action, index) => (
                                            <MenuItem key={index + 1} value={index}>
                                                {action}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                            </Box>
                            {filteredPossession.length > 0 && (
                                <GameTagControlSection
                                    t={t}
                                    clipCount={filteredPossession.length}
                                    isSelectAll={values.selectAll}
                                    onAll={(e) => setValues({ ...values, selectAll: e.target.checked })}
                                    onHudl={handleClickHudlFromButton}
                                    onRender={handleClickRenderFromButton}
                                    onEdits={handleClickEditFromButton}
                                />
                            )}
                            <GameTagList
                                t={t}
                                isLoading={loading}
                                tagList={filteredPossession}
                                curTagListIdx={curTeamTagIdx}
                                checkArr={checkArray}
                                onChecked={handleCheckChange}
                                onVideo={handleShowVideo}
                                onTime={handleChangeTime}
                            />
                        </Box>
                        <GameVideoPlayerPos t={t} videoData={videoData} game={gameTime} onChangeClip={(idx) => setCurTeamTagIdx(idx)} drawOpen={true} />
                        {exportHudl && <XmlDataFilterGame game={game} tagList={playerTagList} isOur={values.isOur} tag_name={tagIndex} setExportXML={setExportHudl} possession={false} />}
                        <GameExportToEdits t={t} open={exportEditOpen} onClose={() => setExportEditOpen(false)} tagList={exportList} isTeams={true} gameId={game.id} />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default Possession;
