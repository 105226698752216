import * as React from 'react';
import PlayerList from './basic/PlayerList';
import gameService from '../../../services/game.service';

import { styled } from '@mui/system';
import Box from '@mui/material/Box';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const SubBox = styled(Box)`
    margin: 6px;
    padding: 6px 2px 2px 2px;
    background-color: #343434;
    border-radius: 6px;
    & .MuiPaper-root {
        padding: 6px;
        border-radius: 6px;
        max-height: 90vh;
        overflow-y: auto;
    }
    & .title {
        text-align: center;
    }
`;

// const style = {
//     padding: '6px',
//     borderRadius: '6px',
//     maxHeight: '90vh',
//     backgroundColor: '#121212',
//     height: '260px'
// };

export default function UpdatePlayerDetection({ homeTeam, awayTeam, game, setGamePlayerRefresh, setModalOpen, updatelabel, setTimeInt, timeInt, setLabelshow, labelshow }) {
    const [posList, setPosList] = React.useState([]);
    React.useEffect(() => {
        gameService.getAllPositions().then((res) => {
            setPosList(res);
        });
    }, []);

    return (
        <>
            <SubBox sx={{ marginTop: '5rem', width: '150px', height: '340px' }}>
                <div className="title">Time Interval</div>
                {/* <div style={style}>
                </div> */}

                <RadioGroup
                    sx={{
                        my: 0,
                        mx: 2,
                        border: '2px solid white',
                        borderRadius: '8px',
                        padding: '4px',
                        marginLeft: '4px',
                        marginRight: '4px'
                    }}
                    aria-label="firstsecond"
                    name="row-radio-buttons-group"
                    value={timeInt}
                    onChange={(e) => setTimeInt(e.target.value)}
                >
                    <FormControlLabel value="1" control={<Radio />} label="Before" />
                    <FormControlLabel value="2" control={<Radio />} label="After" />
                    <FormControlLabel value="3" control={<Radio />} label="All" />
                </RadioGroup>

                <div className="title" style={{ marginTop: '4px' }}>
                    Label Show
                </div>
                <RadioGroup
                    sx={{
                        my: 0,
                        mx: 2,
                        border: '2px solid white',
                        borderRadius: '8px',
                        padding: '4px',
                        marginLeft: '4px',
                        marginRight: '4px'
                    }}
                    aria-label="firstsecond"
                    name="row-radio-buttons-group"
                    value={labelshow === 0 ? '0' : '1'}
                    onChange={(e) => {
                        setModalOpen(false);
                        setLabelshow(e.target.value);
                    }}
                >
                    <FormControlLabel value="1" control={<Radio />} label="Yes" />
                    <FormControlLabel value="0" control={<Radio />} label="No" />
                </RadioGroup>
            </SubBox>

            <PlayerList
                title="Home Team"
                playerList={homeTeam}
                game={game}
                posList={posList}
                setGamePlayerRefresh={setGamePlayerRefresh}
                onSelect={(player) => {
                    setModalOpen(false);
                    updatelabel(player.player_id, player.player_jersey_number);
                }}
            />

            <PlayerList
                title="Away Team"
                playerList={awayTeam}
                game={game}
                posList={posList}
                setGamePlayerRefresh={setGamePlayerRefresh}
                onSelect={(player) => {
                    setModalOpen(false);
                    updatelabel(player.player_id, player.player_jersey_number);
                }}
            />
        </>
    );
}
