import React, { useState, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography, Dialog, DialogContent, DialogTitle, TextField, Select, MenuItem, CircularProgress } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined';

import { PLAYER_ICON_DEFAULT } from '../../../common/staticData';
import { SaveButton, MenuProps } from '../components/common';
import UploadButton from '../components/uploadButton';
import GameService from '../../../services/game.service';
import SeasonContext from '../../../common/Context';
import { setPlayersStatsAdvanceSummaryList, setGoalkeepersStatsAdvanceSummaryList } from '../../../helpers/coachSlice';
import { getLeagueIds, getGameIds } from '../../../helpers/coach';

const init_player = {
    id: 0,
    f_name: '',
    l_name: '',
    player_name: '',
    date_of_birth: new Date(),
    image: '',
    weight: '',
    height: '',
    strong_foot: '',
    age_group_id: 0,
    age_group_name: '',
    jersey_number: 0,
    position: 0,
    position_name: '',
    second_position: 0,
    second_position_name: '',
    position_role_one: '43',
    position_role_two: '43',
    role_one_name: 'Unknown',
    role_two_name: 'Unknown'
};

const PlayerEditDialog = ({ open, onClose, player, t, onSave }) => {
    // const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [loading, setLoading] = useState(false);

    const { seasonFilter } = React.useContext(SeasonContext);
    const [oldPosition, setOldPosition] = useState('');

    const positions = useSelector((state) => state.coach.positions);
    const roles = useSelector((state) => state.coach.roles);
    const ageGroups = useSelector((state) => state.coach.ageGroups);
    const leagueList = useSelector((state) => state.coach.leagueList);
    const gamesList = useSelector((state) => state.coach.gamesList);
    const playersStatsAdvanceSummaryList = useSelector((state) => state.coach.playersStatsAdvanceSummaryList);
    const goalkeepersStatsAdvanceSummaryList = useSelector((state) => state.coach.goalkeepersStatsAdvanceSummaryList);

    const dispatch = useDispatch();

    const [values, setValues] = useReducer((old, action) => {
        if (action.age_group_id !== undefined && action.age_group_id !== old.age_group_id) {
            let ageGroupName = '';
            ageGroups.forEach((age) => {
                if (age.id === action.age_group_id) ageGroupName = age.name;
            });
            action.age_group_name = ageGroupName;
        }
        if (action.position_role_one !== undefined && action.position_role_one !== old.position_role_one) {
            let role_name = '';
            roles.forEach((role) => {
                if (role.id === action.position_role_one) role_name = role.role;
            });
            action.role_one_name = role_name;
        }
        if (action.position_role_two !== undefined && action.position_role_two !== old.position_role_two) {
            let role_name = '';
            roles.forEach((role) => {
                if (role.id === action.position_role_two) role_name = role.role;
            });
            action.role_two_name = role_name;
        }
        const newState = { ...old, ...action };
        return newState;
    }, init_player);

    const [roleOneList, setRoleOneList] = useState([]);
    const [roleTwoList, setRoleTwoList] = useState([]);

    useEffect(() => {
        if (!open) return

        GameService.getPlayerById(player.player_id).then(res => {
            console.log("player details", res)
            setValues({
                f_name: res.player_first_name ?? init_player.f_name,
                l_name: res.player_last_name ?? init_player.l_name,
                date_of_birth: res.player_date_of_birth ?? init_player.date_of_birth,
                weight: res.player_weight ?? init_player.weight,
                height: res.player_height ?? init_player.height,
                age_group_id: res.player_age_group_id ?? init_player.age_group_id,
                age_group_name: res.player_age_group_name ?? init_player.age_group_name,
                jersey_number: res.player_jersey_number ?? init_player.jersey_number,
                position: res.player_position_id ?? init_player.position,
            })
        })
    }, [open, player])

    const setPositionRole = (pos_id) => {
        if (pos_id) {
            setRoleOneList(roles.filter((role) => (role.visible && role.position_id === pos_id) || role.id === '43'));
        } else setRoleOneList(roles.filter((role) => role.id === '43'));
        let pos_name = '';
        positions.forEach((pos) => {
            if (pos.id === pos_id) pos_name = pos.name;
        });
        setValues({ position_name: pos_name, position_role_one: '43' });
    };

    const setSecondPositionRole = (pos_id) => {
        if (pos_id) {
            setRoleTwoList(roles.filter((role) => (role.visible && role.position_id === pos_id) || role.id === '43'));
        } else setRoleTwoList(roles.filter((role) => role.id === '43'));
        let pos_name = '';
        positions.forEach((pos) => {
            if (pos.id === pos_id) pos_name = pos.name;
        });
        setValues({ second_position_name: pos_name, position_role_two: '43' });
    };
    const handleChange = (prop) => (e) => {
        if (prop === 'position') {
            setPositionRole(e.target.value);
        } else if (prop === 'second_position') setSecondPositionRole(e.target.value);
        setValues({ [prop]: e.target.value });
    };

    const updatePlayer = async (playerItem) => {
        if (playersStatsAdvanceSummaryList && playersStatsAdvanceSummaryList.hasOwnProperty(seasonFilter === 'none' ? 'all' : seasonFilter.name)) {
            dispatch(
                setPlayersStatsAdvanceSummaryList({
                    season_name: seasonFilter === 'none' ? 'all' : seasonFilter.name,
                    data: playersStatsAdvanceSummaryList[seasonFilter === 'none' ? 'all' : seasonFilter.name].map((statPlayer) => {
                        if (statPlayer.player_id === playerItem.player_id) {
                            return { ...statPlayer, ...playerItem };
                        }
                        return statPlayer;
                    })
                })
            );
            if (oldPosition === 'Goalkeeper') {
                if (playerItem.player_position_name === 'Goalkeeper') {
                    dispatch(
                        setGoalkeepersStatsAdvanceSummaryList({
                            season_name: seasonFilter === 'none' ? 'all' : seasonFilter.name,
                            data: goalkeepersStatsAdvanceSummaryList[seasonFilter === 'none' ? 'all' : seasonFilter.name].map((statGoalkeeper) => {
                                if (statGoalkeeper.player_id === playerItem.player_id) {
                                    return { ...statGoalkeeper, ...playerItem };
                                }
                                return statGoalkeeper;
                            })
                        })
                    );
                } else {
                    dispatch(
                        setGoalkeepersStatsAdvanceSummaryList({
                            season_name: seasonFilter === 'none' ? 'all' : seasonFilter.name,
                            data: goalkeepersStatsAdvanceSummaryList[seasonFilter === 'none' ? 'all' : seasonFilter.name].filter((statGoalkeeper) => statGoalkeeper.player_id !== playerItem.player_id)
                        })
                    );
                }
            } else {
                if (playerItem.player_position_name === 'Goalkeeper') {
                    let leagueIds = getLeagueIds(leagueList);
                    let gameIds = getGameIds(gamesList, seasonFilter.id);
                    let tmp_goalkeepers = [...goalkeepersStatsAdvanceSummaryList[seasonFilter === 'none' ? 'all' : seasonFilter.name]];
                    await GameService.getGoalkeepersStatsAdvanceSummary({
                        seasonId: seasonFilter === 'none' ? null : seasonFilter.id,
                        leagueId: leagueIds.length > 0 ? leagueIds.join(',') : null,
                        gameId: gameIds.length > 0 ? gameIds.join(',') : null,
                        teamId: playerItem.team_id,
                        playerId: playerItem.player_id,
                        gameTime: null,
                        courtAreaId: null,
                        insidePaint: null,
                        homeAway: null,
                        gameResult: null
                    }).then((res) => {
                        tmp_goalkeepers.push(res[0]);
                        dispatch(
                            setGoalkeepersStatsAdvanceSummaryList({
                                season_name: seasonFilter === 'none' ? 'all' : seasonFilter.name,
                                data: tmp_goalkeepers
                            })
                        );
                    });
                }
            }
        }
    };
    const convertEmptyStringsToNull=(obj)=> {
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            if (obj[key] === "" || obj[key] === 0) {
              obj[key] = null;
            }
          }
        }
        return obj;
      }
    const saveChanges = () => {
        setLoading(true);
        GameService.updatePlayer({ ...convertEmptyStringsToNull(values), weight: values.weight === '' ? null : values.weight, height: values.height === '' ? null : values.height })
            .then((res) => {
                const updatedPlayer = {
                    ...player,
                    player_full_name: values?.f_name.trim() + ' ' + values?.l_name.trim(),
                    player_first_name: values?.f_name.trim(),
                    player_last_name: values?.l_name.trim(),
                    player_date_of_birth: values?.date_of_birth ?? new Date(),
                    player_position_id: values?.position,
                    player_position_name: values?.position_name,
                    player_second_position_id: values?.second_position,
                    player_jersey_number: values?.jersey_number,
                    player_image: values?.image,
                    player_weight: values?.weight ? parseInt(values?.weight) : '',
                    player_height: values?.height ? parseInt(values?.height) : '',
                    player_strong_foot: values?.strong_foot??"",
                    player_age_group_id: values?.age_group_id,
                    player_position_role_one_id: values?.position_role_one ?? 43,
                    player_position_role_two_id: values?.position_role_two ?? 43
                };
                updatePlayer(updatedPlayer);
                setLoading(false);
                onSave(updatedPlayer);
            })
            .catch((e) => {
                setLoading(false);
            });
        if (!loading) onClose();
    };

    useEffect(() => {
        if (player && Object.getOwnPropertyNames(player).length !== 0) {
            setOldPosition(player && player.player_position_name ? player.player_position_name : '');
            const data = {
                id: player.player_id,
                f_name: player?.player_first_name,
                l_name: player?.player_last_name,
                date_of_birth: player?.player_date_of_birth ?? new Date(),
                position: player?.player_position_id ?? player?.position,
                position_name: player?.player_position_name,
                second_position: player?.player_second_position_id,
                jersey_number: player?.player_jersey_number,
                image: player?.player_image,
                weight: player?.player_weight ? parseInt(player?.player_weight) : '',
                height: player?.player_height ? parseInt(player?.player_height) : '',
                strong_foot: player?.player_strong_foot,
                age_group_id: player?.player_age_group_id,
                position_role_one: player?.player_position_role_one_id ?? 43,
                position_role_two: player?.player_position_role_two_id ?? 43
            };
            setValues(data);
            setPositionRole(data.position);
            setSecondPositionRole(data.second_position);
            setTimeout(() => {
                setValues({ position_role_one: data.position_role_one, position_role_two: data.position_role_two });
            }, 500);
        }
    }, [player]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="1500px" maxheight="initial">
            <DialogTitle>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '4px', cursor: 'pointer' }} onClick={onClose}>
                    <CloseIcon sx={{ color: 'red' }} />
                </Box>
            </DialogTitle>
            <DialogContent style={{ display: 'flex', margin: '0 30px', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '24px' }}>
                {values.loading && (
                    <div style={{ width: '100%', height: '100%', zIndex: 9999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                    </div>
                )}
                <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '28px', fontWeight: 700, color: '#1a1b1d' }}>{t('Edit Player')}</Typography>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: '32px' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '7px' }}>
                        <UploadButton
                            class_name="upload-player-view"
                            id_name="player-logo"
                            dirName={process.env.REACT_APP_DIR_PLAYER}
                            img={values.image ? (values.image.includes(process.env.REACT_APP_S3_URI) ? values.image : process.env.REACT_APP_S3_URI + values.image) : PLAYER_ICON_DEFAULT}
                            onURL={(url) => {
                                if (url !== PLAYER_ICON_DEFAULT) setValues({ image: url });
                            }}
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                            <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', marginLeft: '16px' }}>{t('Age Group')}</Typography>
                            <Select
                                value={values.age_group_id}
                                onChange={handleChange('age_group_id')}
                                label=""
                                variant="outlined"
                                IconComponent={ExpandMoreIcon}
                                inputProps={{ 'aria-label': 'Without label' }}
                                MenuProps={MenuProps}
                                sx={{ outline: 'none', height: '30px', width: '140px', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                            >
                                {ageGroups.map((item, index) => (
                                    <MenuItem key={index} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                            <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', marginLeft: '16px' }}>{t('Height')}</Typography>
                            <Select
                                value={values.height}
                                onChange={handleChange('height')}
                                label=""
                                variant="outlined"
                                IconComponent={ExpandMoreIcon}
                                inputProps={{ 'aria-label': 'Without label' }}
                                MenuProps={MenuProps}
                                sx={{ outline: 'none', height: '30px', width: '140px', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                            >
                                {Array.from({ length: 56 }, (_, i) => 150 + i).map((value) => (
                                    <MenuItem key={value} value={value}>
                                        {value} cm
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                            <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', marginLeft: '16px' }}>{t('Weight')}</Typography>
                            <Select
                                value={values.weight}
                                onChange={handleChange('weight')}
                                label=""
                                variant="outlined"
                                IconComponent={ExpandMoreIcon}
                                inputProps={{ 'aria-label': 'Without label' }}
                                MenuProps={MenuProps}
                                sx={{
                                    outline: 'none',
                                    height: '30px',
                                    width: '140px',
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 }
                                }}
                            >
                                {Array.from({ length: 51 }, (_, i) => 50 + i).map((value) => (
                                    <MenuItem key={value} value={value}>
                                        {value} kg
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                            <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', marginLeft: '16px' }}>{t('Preferred Foot')}</Typography>
                            <Select
                                value={values.strong_foot}
                                onChange={handleChange('strong_foot')}
                                label=""
                                variant="outlined"
                                IconComponent={ExpandMoreIcon}
                                inputProps={{ 'aria-label': 'Without label' }}
                                MenuProps={MenuProps}
                                sx={{ outline: 'none', height: '30px', width: '140px', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                            >
                                <MenuItem key={0} value="Right">
                                    Right
                                </MenuItem>
                                <MenuItem key={1} value="Left">
                                    Left
                                </MenuItem>
                                <MenuItem key={2} value="Both">
                                    Both
                                </MenuItem>
                            </Select>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', marginLeft: '16px' }}>{t('FirstName')}</Typography>
                                <TextField
                                    value={values.f_name}
                                    onChange={handleChange('f_name')}
                                    label=""
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    variant="outlined"
                                    placeholder="First Name"
                                    disabled={true}
                                    sx={{ borderRadius: '10px', height: '48px', width: '300px', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', marginLeft: '16px' }}>{t('LastName')}</Typography>
                                <TextField
                                    value={values.l_name}
                                    onChange={handleChange('l_name')}
                                    label=""
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    variant="outlined"
                                    placeholder="Last Name"
                                    disabled={true}
                                    sx={{ borderRadius: '10px', height: '48px', width: '300px', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', marginLeft: '16px' }}>{t('Birthday')}</Typography>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label=""
                                        inputFormat="dd-MM-yyyy"
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        variant="outlined"
                                        value={values.date_of_birth}
                                        onChange={(v) => setValues({ date_of_birth: v })}
                                        renderInput={(params) => (
                                            <TextField sx={{ borderRadius: '10px', height: '48px', width: '300px', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} {...params} />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>
                            {/*<Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', marginLeft: '16px' }}>{t('Jersey Number')}</Typography>
                                <TextField
                                    value={values.jersey_number}
                                    onChange={handleChange('jersey_number')}
                                    label=""
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    variant="outlined"
                                    disabled={true}
                                    type="number"
                                    placeholder="Jersey Number"
                                    sx={{ borderRadius: '10px', height: '48px', width: '300px', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                />
                            </Box>*/}
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', marginLeft: '16px' }}>{t('First Position')}</Typography>
                                <Select
                                    value={values.position}
                                    onChange={handleChange('position')}
                                    label=""
                                    variant="outlined"
                                    IconComponent={ExpandMoreIcon}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    MenuProps={MenuProps}
                                    sx={{ outline: 'none', height: '48px', width: '300px', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                >
                                    {positions.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', marginLeft: '16px' }}>{t('First Role')}</Typography>
                                <Select
                                    value={values.position_role_one}
                                    onChange={handleChange('position_role_one')}
                                    label=""
                                    variant="outlined"
                                    IconComponent={ExpandMoreIcon}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    MenuProps={MenuProps}
                                    sx={{ outline: 'none', height: '48px', width: '300px', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                >
                                    {roleOneList.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>
                                            {item.role}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', marginLeft: '16px' }}>{t('Second Position')}</Typography>
                                <Select
                                    value={values.second_position}
                                    onChange={handleChange('second_position')}
                                    label=""
                                    variant="outlined"
                                    IconComponent={ExpandMoreIcon}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    MenuProps={MenuProps}
                                    sx={{ outline: 'none', height: '48px', width: '300px', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                >
                                    {positions.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', marginLeft: '16px' }}>{t('Second Role')}</Typography>
                                <Select
                                    value={values.position_role_two}
                                    onChange={handleChange('position_role_two')}
                                    label=""
                                    variant="outlined"
                                    IconComponent={ExpandMoreIcon}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    MenuProps={MenuProps}
                                    sx={{ outline: 'none', height: '48px', width: '300px', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                >
                                    {roleTwoList.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>
                                            {item.role}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <SaveButton onClick={saveChanges} sx={{ width: '300px', fontSize: '14px' }}>
                    {t('Save changes')}
                </SaveButton>
            </DialogContent>
        </Dialog>
    );
};

export default PlayerEditDialog;
