import * as React from 'react';
import { TableCell, TextField } from '@mui/material';

export default function TCellNameEdit({ value, update, error, onValidation, ...other }) {
    const [editable, setEditable] = React.useState(false);
    const [temp, setTemp] = React.useState('');

    React.useEffect(() => {
        onValidation(editable ? temp : '')
    }, [onValidation, temp, editable])

    React.useEffect(() => { setTemp(value) }, [value])

    const updateValue = () => {
        update(temp);
        setEditable(false);
    };

    return (
        <TableCell onDoubleClick={() => setEditable(true)} style={{ height: '36px' }} {...other}>
            {editable ? (
                <TextField
                    value={temp}
                    autoFocus
                    error={!!error}
                    helperText={error}
                    sx={{ fontSize: 14, width: '100%' }}
                    size="small"
                    onBlur={() => updateValue()}
                    onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                            ev.preventDefault();
                            updateValue();
                        }
                    }}
                    variant="standard"
                    onChange={(e) => setTemp(e.target.value)}
                />
            ) : (
                <>{value}</>
            )}
        </TableCell>
    );
}
