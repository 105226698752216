import React, { useMemo, useState } from "react";
import { Tooltip, Zoom, Divider } from '@mui/material';
import { getComparator, stableSort } from '../../../components/utilities';
import { PieChart, Pie, Cell } from 'recharts';
import GameExportToEdits from '../../../games/tabs/overview/exportEdits';

const RADIAN = Math.PI / 180;


const PlayerStatsChart = ({ title, seriesBar, total, tags, handleClick, t }) => {
    const [exportOpen, setExportOpen] = useState(false);
    const [playData, setPlayData] = useState([]);
    const [hoverId, setHoverId] = useState('');
    const getPercent = (val, total) => {
        var per = (val * 100.00 / total)
        return per + '%'
    }

    const handlePieExportTags = (videoList) => (e) => {
        e.preventDefault();
        setPlayData(videoList);
        setExportOpen(true);
    };

    const playerList = useMemo(() => {
        let temp = [];

        seriesBar.forEach(element => {
            element.tags.forEach(tag => {
                const filtered = temp.filter((data) => tag.player_names === data.player_names);
                const filteredTags = element.tags.filter(et => et.player_names === tag.player_names)
                if (filtered.length === 0) {
                    const total = tags.filter(t => t.player_names === tag.player_names)
                    temp = [...temp, { player_names: tag.player_names, total: total.length, series: [{ name: element.name, data: filteredTags.length, color: element.color, tags: filteredTags }] }];
                } else {
                    const playerTemp = temp.find(data => tag.player_names === data.player_names)
                    if (!playerTemp.series.find(serie => serie.name === element.name))
                        playerTemp.series.push({ name: element.name, data: filteredTags.length, color: element.color, tags: filteredTags })
                }
            })
        });
        return stableSort(temp, getComparator('desc', 'total'));

    }, [seriesBar, tags]);

    const getBarWidth = (maxCount, count) => {
        const realWidth = Math.floor((count * 98) / maxCount);

        return `${realWidth}%`;
    };

    const handleMouseEnter = (index, sId) => {
        setHoverId(`${index}-${sId}`);
    };

    const getChartBarId = (index, sId) => {
        return `${index}-${sId}`;
    };

    const handleExportTags = (player, sId) => (e) => {
        e.preventDefault();
        setPlayData(player.series[sId].tags);
        setExportOpen(true);
    };

    const getTooltipContent = (player, sId) => {
        return (
            <div>
                <p className="normal-text-white">Name: {player.player_names}</p>
                <p className="normal-text-white">Action: {player.series[sId].name}</p>
                <p className="normal-text-white">
                    Result: {player.series[sId].data}
                </p>
                <p className="normal-text-white">Count: {player.total}</p>
            </div>
        );
    };

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, value, fill }) => {
        const radius = outerRadius + 10;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        const radius1 = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x1 = cx + radius1 * Math.cos(-midAngle * RADIAN);
        const y1 = cy + radius1 * Math.sin(-midAngle * RADIAN);

        if ((percent * 100).toFixed(0) == 0) return null;

        return (
            <>
                <text x={x} y={y} fill={"black"} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {`${(percent * 100).toFixed(0)}%`}
                </text>
                <text
                    x={x1} y={y1}
                    fill={"white"}
                    textAnchor={x1 > cx ? 'start' : 'end'}
                    dominantBaseline="central"
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleClick(seriesBar[index].tags)}
                    onContextMenu={handlePieExportTags(seriesBar[index].tags)}
                >
                    {value}
                </text>
            </>
        );
    };


    return (
        <div
            style={{
                width: '100%',
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                padding: '15px',
            }}>
            <Divider sx={{ width: '100%', backgroundColor: '#17375E', opacity: 1, border: 'none', marginTop: '20px', marginBottom: '20px' }} />
            <p className="chart-title">{title}</p>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', overflowX: 'auto', whiteSpace: 'nowrap' }}>
                    {seriesBar.map((dt, idx) => (
                        <div key={`lbl${idx}`} style={{ minWidth: 'auto', display: 'flex', alignItems: 'center', gap: '2px' }}>
                            <div style={{ background: `${dt.color}`, borderRadius: '50%', width: '12px', height: '12px' }} />
                            <p className="normal-text-italic">{t(dt.name)}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <PieChart width={240} height={240}>
                    <Pie
                        data={seriesBar}
                        cx="50%"
                        cy="50%"
                        style={{ cursor: 'pointer' }}
                        labelLine={false}
                        isAnimationActive={false}
                        label={renderCustomizedLabel}
                        outerRadius={75}
                        fill="#8884d8"
                        dataKey="data"
                    >
                        {seriesBar.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={entry.color}
                                onClick={() => handleClick(entry.tags)}
                                onContextMenu={handlePieExportTags(entry.tags)} // Prevent default context menu
                            />
                        ))}
                    </Pie>
                </PieChart>
            </div>
            {playerList.map((player, index) => (<div key={player.player_names} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <p className="chart-primary-text" style={{ width: '20%' }}>
                    {player.player_names}
                </p>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px', width: '80%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        {player.series.map((skill, sId) => (
                            <div
                                key={`${index}-${sId}`}
                                style={{
                                    background: skill.color,
                                    width: getBarWidth(playerList[0].total, skill.data),
                                    minWidth: '4%',
                                    height: '20px',
                                    cursor: 'pointer',
                                    border: hoverId === getChartBarId(index, sId) ? '1px solid white' : 'none'
                                }}
                                onMouseEnter={() => handleMouseEnter(index, sId)}
                                onMouseLeave={() => setHoverId('')}
                                onClick={() => handleClick(skill.tags)}
                                onContextMenu={handleExportTags(player, sId)}
                            >
                                <Tooltip arrow title={getTooltipContent(player, sId)} TransitionComponent={Zoom} placement="right">
                                    <p className="chart-bar-text">{skill.data}</p>
                                </Tooltip>
                            </div>
                        ))}
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', width: '95x' }}>
                    <div style={{ display: 'flex', alignItems: 'center', width: '95x' }}>
                        {title === '1v1 Attacking' ? (
                            <>
                                <p className="normal-text-italic" style={{ width: '20px', textAlign: 'right', gap: '5px' }}>
                                    {player.total}
                                </p>
                                <p className="normal-text-italic" style={{ width: '20px', textAlign: 'right', gap: '5px' }}>
                                    {player.series[0] && (player.series[0].name === 'Successful' || player.series[0].name === 'Draw Foul')
                                        ? player.series[1] && player.series[1].name === 'Draw Foul'
                                            ? player.series[0].data + player.series[1].data
                                            : player.series[0].data
                                        : player.series[1] && player.series[1].name === 'Draw Foul'
                                            ? player.series[1].data
                                            : 0}
                                </p>
                                <p className="bold-text-italic" style={{ width: '40px', textAlign: 'right', gap: '5px' }}>
                                    {(
                                        ((player.series[0] && (player.series[0].name === 'Successful' || player.series[0].name === 'Draw Foul')
                                            ? player.series[1] && player.series[1].name === 'Draw Foul'
                                                ? player.series[0].data + player.series[1].data
                                                : player.series[0].data
                                            : player.series[1] && player.series[1].name === 'Draw Foul'
                                                ? player.series[1].data
                                                : 0) /
                                            player.total) *
                                        100
                                    ).toFixed(0) + '%'}
                                </p>
                            </>
                        ) : title === 'Passing Accuracy' ? (
                            <>
                                <p className="normal-text-italic" style={{ width: '20px', textAlign: 'right', gap: '5px' }}>
                                    {player.total}
                                </p>
                                <p className="normal-text-italic" style={{ width: '20px', textAlign: 'right', gap: '5px' }}>
                                    {player.series[0] ? player.series[0].data : 0}
                                </p>
                                <p className="bold-text-italic" style={{ width: '40px', textAlign: 'right', gap: '5px' }}>
                                    {player.series[0] ? ((player.series[0].data / player.total) * 100).toFixed(0) + '%' : '0%'}
                                </p>
                            </>
                        ) : title === '1v1 Defending' ? (
                            <>
                                <p className="normal-text-italic" style={{ width: '20px', textAlign: 'right', gap: '5px' }}>
                                    {player.total}
                                </p>
                                <p className="normal-text-italic" style={{ width: '20px', textAlign: 'right', gap: '5px' }}>
                                    {player.series[0] && (player.series[0].name === 'Bad Pass' || player.series[0].name === 'Stolen')
                                        ? player.series[1] && player.series[1].name === 'Stolen'
                                            ? player.series[0].data + player.series[1].data
                                            : player.series[0].data
                                        : player.series[1] && player.series[1].name === 'Stolen'
                                            ? player.series[1].data
                                            : 0}
                                </p>
                                <p className="bold-text-italic" style={{ width: '40px', textAlign: 'right', gap: '5px' }}>
                                    {(
                                        ((player.series[0] && (player.series[0].name === 'Bad Pass' || player.series[0].name === 'Stolen')
                                            ? player.series[1] && player.series[1].name === 'Stolen'
                                                ? player.series[0].data + player.series[1].data
                                                : player.series[0].data
                                            : player.series[1] && player.series[1].name === 'Stolen'
                                                ? player.series[1].data
                                                : 0) /
                                            player.total) *
                                        100
                                    ).toFixed(0) + '%'}
                                </p>
                            </>
                        ) : title === 'Crossing Accuracy' ? (
                            <>
                                <p className="normal-text-italic" style={{ width: '20px', textAlign: 'right', gap: '5px' }}>
                                    {player.total}
                                </p>
                                <p className="normal-text-italic" style={{ width: '20px', textAlign: 'right', gap: '5px' }}>
                                    {player.series[0] && (player.series[0].name === 'Successful' || player.series[0].name === 'Cleared')
                                        ? player.series[1] && player.series[1].name === 'Cleared'
                                            ? player.series[0].data + player.series[1].data
                                            : player.series[0].data
                                        : player.series[1] && player.series[1].name === 'Cleared'
                                            ? player.series[1].data
                                            : 0}
                                </p>
                                <p className="bold-text-italic" style={{ width: '40px', textAlign: 'right', gap: '5px' }}>
                                    {(
                                        ((player.series[0] && (player.series[0].name === 'Successful' || player.series[0].name === 'Cleared')
                                            ? player.series[1] && player.series[1].name === 'Cleared'
                                                ? player.series[0].data + player.series[1].data
                                                : player.series[0].data
                                            : player.series[1] && player.series[1].name === 'Cleared'
                                                ? player.series[1].data
                                                : 0) /
                                            player.total) *
                                        100
                                    ).toFixed(0) + '%'}
                                </p>
                            </>
                        ) : title === 'Shooting & Finishing' ? (
                            <>
                                <p className="normal-text-italic" style={{ width: '20px', textAlign: 'right', gap: '5px' }}>
                                    {player.total}
                                </p>
                                <p className="normal-text-italic" style={{ width: '20px', textAlign: 'right', gap: '5px' }}>
                                    {player.series[0] && (player.series[0].name === 'Goal' || player.series[0].name === 'On Target')
                                        ? player.series[1] && player.series[1].name === 'On Target'
                                            ? player.series[0].data + player.series[1].data
                                            : player.series[0].data
                                        : player.series[1] && player.series[1].name === 'On Target'
                                            ? player.series[1].data
                                            : 0}
                                </p>
                                <p className="bold-text-italic" style={{ width: '40px', textAlign: 'right', gap: '5px' }}>
                                    {(
                                        ((player.series[0] && (player.series[0].name === 'Goal' || player.series[0].name === 'On Target')
                                            ? player.series[1] && player.series[1].name === 'On Target'
                                                ? player.series[0].data + player.series[1].data
                                                : player.series[0].data
                                            : player.series[1] && player.series[1].name === 'On Target'
                                                ? player.series[1].data
                                                : 0) /
                                            player.total) *
                                        100
                                    ).toFixed(0) + '%'}
                                </p>
                            </>
                        ) : (
                            <>
                                <p className="normal-text-italic" style={{ width: '20px', textAlign: 'right', gap: '5px' }}>
                                    {player.total}
                                </p>
                                <p className="normal-text-italic" style={{ width: '20px', textAlign: 'right', gap: '5px' }}>
                                    {/* Empty content for the second conditional */}
                                </p>
                                <p className="bold-text-italic" style={{ width: '40px', textAlign: 'right', gap: '5px' }}>
                                    {/* Empty content for the second conditional */}
                                </p>
                            </>
                        )}
                    </div>
                </div>
            </div>
            ))}

            <GameExportToEdits t={t} open={exportOpen} onClose={() => setExportOpen(false)} tagList={playData} isTeams={false} />
        </div>
    );
}
export default PlayerStatsChart;
