import React, { useEffect, useRef, useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import ReactPlayer from 'react-player';
import { IconButton, Button, Slider } from '@mui/material';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import EditIcon from '@mui/icons-material/Edit';
import PauseIcon from '@mui/icons-material/Pause';
import SkipNextSharpIcon from '@mui/icons-material/SkipNextSharp';
import SkipPreviousSharpIcon from '@mui/icons-material/SkipPreviousSharp';
import gameService from '../../services/game.service';
const styles = {
    action: {
        position: 'absolute',
        left: '3%',
        bottom: 10,
        zIndex: 100,
        color: '#ffffff',
        fontSize: 14,
        display: 'flex',
        width: '50%'
    },
    buttonBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 20px',
        width: '100%',
        position: 'absolute',
        bottom: 0,
        left: 0
    },
    button: {
        color: '#ffffff',
        backgroundColor: '#80808069'
    }
};
const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
};
const FullVideoPlayer = ({ video_url, muteState, setFullVideo, IsSkills }) => {
    const handle = useFullScreenHandle();
    const player = useRef(null);
    const [play, setPlay] = useState(true);
    const [currentTime, setCurrentTime] = useState(0);
    const [videoURL, setVideoURL] = useState('');
    const [duration, setDuration] = useState(0);
    const [, setReady] = useState(false);
    const [curIdx, setCurIdx] = useState(0);
    const [videoList, setVideoList] = useState([]);
    const [canNext, setCanNext] = useState(true);
    const [, setFullMode] = useState(false);
    const [playRate, setPlayRate] = useState(1);
    const [showLogo, setShowLogo] = useState(true);
    const fastVideo = (param) => {
        player.current && player.current.seekTo(currentTime + param);
    };
    const onProgress = (seconds) => {
        setCurrentTime(seconds);
    };
    const PlayVideo = (num) => {
        let index;
        setPlay(true);
        setCurIdx(index);
    };
    const handlePause = () => {
        setPlay(false);
    };
    const handlePlay = () => {
        setPlay(true);
    };
    useEffect(() => {
        if (video_url?.includes('youtube.com') || video_url?.includes('youtu.be')) {
            gameService.getNewStreamURL(video_url).then((res) => {
                setVideoURL(res.result.video_url);
            });
        } else setVideoURL(video_url);
    }, [video_url]);
    return (
        <>
            <div className="player-shared-wrapper tag-video">
                <ReactPlayer
                    className="react-shared-player"
                    url={videoURL}
                    ref={player}
                    onPlay={() => setPlay(true)}
                    onPause={() => setPlay(false)}
                    onProgress={(p) => onProgress(p.playedSeconds)}
                    onDuration={(d) => setDuration(d)}
                    playing={play}
                    muted={muteState}
                    playbackRate={playRate}
                    controls={false}
                    width="100%"
                    height="100%"
                />
            </div>
            <IconButton
                aria-label="close"
                onClick={(e) => setFullVideo(false)}
                sx={{
                    position: 'fixed',
                    right: 8,
                    top: 8,
                    zIndex: 9999,
                    color: 'red'
                }}
            >
                <CloseIcon />
            </IconButton>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    zIndex: 999,
                    alignItems: 'center',
                    position: 'fixed',
                    left: 0,
                    bottom: '16px',
                    justifyContent: 'center',
                    width: '100%',
                    gap: '0.2rem'
                }}
            >
                <div>
                    {IsSkills && (
                        <IconButton onClick={() => PlayVideo(1)} style={styles.button}>
                            <SkipPreviousSharpIcon />
                        </IconButton>
                    )}
                    <IconButton style={styles.button} onClick={() => fastVideo(-3)}>
                        <FastRewindIcon color="#ffffff" />
                    </IconButton>
                    <Button variant="outlined" sx={{ width: '60px', color: '#ffffff' }} onClick={() => setPlayRate(0.5)}>
                        {'Slow'}
                    </Button>
                    <IconButton
                        onClick={() => {
                            if (playRate === 1) setPlay((p) => !p);
                            else setPlayRate(1);
                        }}
                        style={{ color: '#ffffff', backgroundColor: '#80808069' }}
                    >
                        {play && playRate === 1 ? <PauseIcon /> : <PlayArrowIcon />}
                    </IconButton>
                    <Button variant="outlined" sx={{ width: '60px', color: '#ffffff' }} onClick={() => setPlayRate((s) => s + 0.5)}>
                        {'Fast'}
                    </Button>
                    <IconButton style={styles.button} onClick={() => fastVideo(3)}>
                        <FastForwardIcon color="#ffffff" />
                    </IconButton>
                    {IsSkills && (
                        <IconButton onClick={() => PlayVideo(1)} style={styles.button}>
                            <SkipNextSharpIcon />
                        </IconButton>
                    )}
                </div>
                <Slider
                    size="small"
                    value={(currentTime / duration) * 100}
                    aria-label="Small"
                    valueLabelDisplay="auto"
                    sx={{ color: 'white' , padding: '0px'}}
                    valueLabelFormat={(value) => formatTime((value / 100) * duration)}
                    onChange={(e, value) => {
                        const seekTo = (value / 100) * duration;
                        player.current.seekTo(seekTo, 'seconds');
                        setCurrentTime(seekTo);
                    }}
                />
            </div>
        </>
    );
};
export default FullVideoPlayer;
