import React from 'react';
import { Box, Typography } from '@mui/material';

const TeamFormationLogo = ({ formation, t }) => {
    return (
        <Box
            sx={{
                minWidth: '200px', // Adjust based on your needs
                minHeight: '340px',
                maxHeight: '470px',
                height: '-webkit-fill-available',
                backgroundColor: '#F4F4F4',
                borderRadius: '10px',
                border: '1px solid #DDD',
                p: 1, // padding inside the box
                boxSizing: 'border-box'
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '2px' }}>
                <div
                    style={{
                        background: formation.team_points > 0 ? 'green' : formation.total_number_of_times_played ? 'red' : 'orange',
                        borderRadius: '50%',
                        width: '17px',
                        height: '17px',
                        display: 'flex', // Make this div also a flex container
                        alignItems: 'center', // Center the circle vertically
                        justifyContent: 'center' // Center the circle horizontally
                    }}
                />
                <p style={{ fontSize: '21px', textAlign: 'center', color: 'black' }}> {t(formation.team_formation_name)}</p>
            </div>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mb: 2
                }}
            >
                <img
                    src={formation.team_formation_image}
                    alt="Description"
                    style={{
                        maxWidth: '85%',
                        height: 'auto',
                        borderRadius: '5px' // rounded corners for the image
                    }}
                />
            </Box>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '2px' }}>
                {formation.total_number_of_times_played > 0 ? (
                    <>
                        <p style={{ fontSize: '20px', lineHeight: '0.9', textAlign: 'center', color: 'black', margin: '1px 0' }}>
                            <span style={{ color: 'black' }}> {formation.total_number_of_times_played} GAMES </span>
                            {formation.total_number_of_times_played > 0 && <span style={{ color: 'black', lineHeight: '0.9' }}> ({formation.total_percentage_of_times}%) </span>}
                        </p>
                        <p style={{ fontSize: '20px', lineHeight: '0.9', textAlign: 'center', color: formation.team_points ? 'green' : 'red', margin: '1px 0' }}>{formation.team_points} POINTS</p>
                        <p style={{ fontSize: '18px', lineHeight: '0.9', textAlign: 'center', margin: '1px 0' }}>
                            {formation.team_won_games > 0 && <span style={{ color: 'green' }}> WON: {formation.team_won_games} </span>}

                            {formation.team_draw_games > 0 && (
                                <>
                                    {formation.team_won_games > 0 && <span style={{ color: 'black' }}> - </span>}
                                    <span style={{ color: 'orange' }}> DRAW: {formation.team_draw_games} </span>
                                </>
                            )}

                            {formation.team_lose_games > 0 && (
                                <>
                                    {(formation.team_won_games > 0 || formation.team_draw_games > 0) && <span style={{ color: 'black' }}> - </span>}
                                    <span style={{ color: 'red' }}> LOSE: {formation.team_lose_games} </span>
                                </>
                            )}
                        </p>
                    </>
                ) : (
                    <p style={{ fontSize: '20px', lineHeight: '22px', textAlign: 'center', color: 'black', margin: '1px 0' }}>NO GAMES</p>
                )}
            </div>
        </Box>
    );
};

export default TeamFormationLogo;
