import { useEffect } from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const ExcelDataFiltering = ({ team, setExcelData }) => {
    useEffect(() => {
        const ws = XLSX.utils.json_to_sheet(team, {
            header: [
                'game_date',
                'start_time',
                'end_time',
                'period_name',
                'offensive_team_name',
                'defensive_team_name',
                'player_fname',
                'player_lname',
                'action_name',
                'action_type_name',
                'action_result_name'
            ]
        });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Team Tags');
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        const buf = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
        saveAs(buf, 'team_tags.xlsx');
        setExcelData(false);
    }, []);

    function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    }

    return null;
};

export default ExcelDataFiltering;
