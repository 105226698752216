import React, { useRef, useState, useEffect, useCallback } from 'react';
import ReactPlayer from 'react-player';
import { IconButton, Switch, FormControlLabel, Box, Typography, Button } from '@mui/material';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined';
import PauseIcon from '@mui/icons-material/Pause';
import SkipNextSharpIcon from '@mui/icons-material/SkipNextSharp';
import SkipPreviousSharpIcon from '@mui/icons-material/SkipPreviousSharp';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';

import { toSecond } from '../../components/utilities';
import gameService from '../../../../services/game.service';
import GameImage from '../../../../assets/MyEdits.png';
import { getPeriod } from '../../games/tabs/overview/tagListItem';
import { TEAM_ICON_DEFAULT } from '../../../../common/staticData';

// import VIDEO from '../../assets/1.mp4'

const styles = {
    action: {
        position: 'absolute',
        left: '3%',
        bottom: 10,
        zIndex: 100,
        color: '#ffffff',
        fontSize: 14,
        display: 'flex',
        width: '50%'
    },
    buttonBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 20px',
        width: '100%',
        position: 'absolute',
        bottom: 0,
        left: 0
    },
    button: {
        color: '#ffffff',
        backgroundColor: '#80808069'
    }
};
export default function SharedEditVideoPlayer({ idx, tagList, onChangeClip, drawOpen, t }) {
    const handle = useFullScreenHandle();

    const player = useRef(null);
    const [play, setPlay] = useState(false);
    const [, setReady] = useState(false);
    const [curIdx, setCurIdx] = useState(0);
    const [videoURL, setVideoURL] = useState('');
    const [videoList, setVideoList] = useState([]);
    const [canNext, setCanNext] = useState(true);
    const [currentTime, setCurrentTime] = useState(0);
    const [, setFullMode] = useState(false);
    const [playRate, setPlayRate] = useState(1);
    const [showLogo, setShowLogo] = useState(true);

    const seekTo = (sec) => player.current && player.current.seekTo(sec);

    const playTagByIdx = (i) => {
        const video = videoList.filter((item) => item.id === tagList[i].game_id).map((item) => item.url)[0];

        if (video !== videoURL) setVideoURL(video);

        seekTo(toSecond(tagList[i]?.start_time));
    };

    const onProgress = (current) => {
        setCurrentTime(current);

        const startTime = toSecond(tagList[curIdx]?.start_time);
        const endTime = toSecond(tagList[curIdx]?.end_time);

        if (current < startTime) seekTo(startTime);

        if (current > endTime) {
            if (curIdx < tagList.length - 1) {
                if (canNext) {
                    const video = videoList.filter((item) => item.id === tagList[curIdx + 1].game_id).map((item) => item.url)[0];

                    if (video !== videoURL) setVideoURL(video);

                    setCurIdx((c) => c + 1);
                } else setPlay(false);
            } else PlayVideo(1);
        }
    };

    const PlayVideo = (num) => {
        let index;
        if (curIdx + num >= tagList.length) {
            index = 0;
        } else if (curIdx + num < 0) {
            index = tagList.length - 1;
        } else index = curIdx + num;

        playTagByIdx(index);
        setPlay(true);
        setCurIdx(index);
    };

    const fastVideo = (param) => {
        if (currentTime + param < 0) seekTo(0);
        else seekTo(currentTime + param);
    };

    const handlePause = () => {
        setPlay(false);
    };

    const handlePlay = () => {
        setPlay(true);
    };

    useEffect(() => {
        setVideoList([]);
        setCurIdx(0);
        setVideoURL('');
        tagList.map((tag, index) => {
            if (tag.video_url.includes('youtube.com') || tag.video_url.includes('youtu.be')) {
                gameService.getNewStreamURL(tag.video_url).then((res) => {
                    setVideoList((old) => [...old, { id: tag.game_id, url: res }]);

                    if (index === 0) setVideoURL(res);
                });
            } else {
                setVideoList((old) => [...old, { id: tag.game_id, url: tag.video_url }]);

                if (index === 0) setVideoURL(tag.video_url);
            }
        });
    }, [tagList]);

    useEffect(() => {
        if (tagList.length === 0) {
            setVideoURL('');
            setCurIdx(0);

            return;
        }

        playTagByIdx(idx);
        setCurIdx(idx);
        setPlay(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idx]);

    useEffect(() => {
        if (idx !== curIdx) onChangeClip(curIdx);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [curIdx]);

    const fullscreenChange = useCallback(
        (state, h) => {
            if (handle === h) setFullMode(state);
        },
        [handle]
    );

    return (
        <FullScreen handle={handle} onChange={fullscreenChange}>
            <div className="video_player_section" style={{ marginLeft: 0 }} onContextMenu={(e) => e.preventDefault()}>
                <div className="player-shared-wrapper" onContextMenu={(e) => e.preventDefault()}>
                    {videoURL !== '' && (
                        <ReactPlayer
                            className="react-shared-player"
                            url={videoURL}
                            muted
                            ref={player}
                            onPlay={handlePlay}
                            onPause={handlePause}
                            onReady={() => setReady(true)}
                            onProgress={(p) => onProgress(p.playedSeconds)}
                            playing={play}
                            controls={false}
                            playbackRate={playRate}
                            width="100%"
                        />
                    )}
                    {videoURL === '' && <img src={GameImage} style={{ width: '100%', height: '100%', borderRadius: '12px', position: 'absolute', left: 0, top: 0 }} />}
                </div>
            </div>
            <div style={{ position: 'absolute', left: '36px', top: '12px', width: '90%', display: 'flex', alignItems: 'center' }}>
                <FormControlLabel control={<Switch checked={showLogo} onChange={(e) => setShowLogo(e.target.checked)} />} label="" sx={{ color: '#ffffff', margin: 0, flex: 1 }} />
                {showLogo && tagList.length > 0 && (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', justifyContent: 'center', flex: 4 }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#17375E', width: '110px', borderRadius: '5px' }}>
                            <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '24px', fontWeight: 500, color: '#ffffff' }}>
                                {`${getPeriod(tagList[curIdx]?.period)} - ${tagList[curIdx]?.time_in_game}'`}
                            </Typography>
                        </div>
                        <>
                            <img src={tagList[curIdx]?.home_team_logo ? tagList[curIdx]?.home_team_logo : TEAM_ICON_DEFAULT} style={{ width: '45px', height: '45px' }} />
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#ffffff', width: '90px', borderRadius: '5px' }}>
                                <Typography
                                    sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '24px', fontWeight: 500, color: '#17375E' }}
                                >{`${tagList[curIdx]?.home_team_goal} : ${tagList[curIdx]?.away_team_goal}`}</Typography>
                            </div>
                            <img src={tagList[curIdx]?.away_team_logo ? tagList[curIdx]?.away_team_logo : TEAM_ICON_DEFAULT} style={{ width: '45px', height: '45px' }} />
                        </>
                    </div>
                )}
            </div>

            <div style={styles.buttonBox}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.4rem' }}>
                    <IconButton onClick={() => PlayVideo(-1)} style={styles.button}>
                        <SkipPreviousSharpIcon color="#ffffff" />
                    </IconButton>
                    <IconButton style={styles.button} onClick={() => fastVideo(-3)}>
                        <FastRewindIcon color="#ffffff" />
                    </IconButton>
                    <Button variant="outlined" sx={{ width: '60px', color: '#ffffff' }} onClick={() => setPlayRate(0.5)}>
                        {t('Slow')}
                    </Button>
                    <IconButton
                        onClick={() => {
                            if (playRate === 1) setPlay((p) => !p);
                            else setPlayRate(1);
                            if (!handle.active) handle.enter();
                        }}
                        style={{ color: '#ffffff', backgroundColor: '#80808069' }}
                    >
                        {play && playRate === 1 ? <PauseIcon /> : <PlayArrowIcon />}
                    </IconButton>
                    <Button variant="outlined" sx={{ width: '60px', color: '#ffffff' }} onClick={() => setPlayRate((s) => s + 0.5)}>
                        {t('Fast')}
                    </Button>
                    <IconButton style={styles.button} onClick={() => fastVideo(3)}>
                        <FastForwardIcon color="#ffffff" />
                    </IconButton>
                    <IconButton onClick={() => PlayVideo(1)} style={styles.button}>
                        <SkipNextSharpIcon />
                    </IconButton>

                    <FormControlLabel control={<Switch checked={canNext} onChange={(e) => setCanNext(e.target.checked)} />} label="AP" sx={{ color: '#ffffff' }} />
                    {/* <IconButton onClick={handle.active ? handle.exit : handle.enter} style={styles.button}>
                        {handle.active ? <FullscreenExitOutlinedIcon /> : <FullscreenIcon />}
                    </IconButton> */}
                </div>
                {tagList.length > 0 && handle.active && (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '2px 12px', background: '#80808069' }}>
                        <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '16px', fontWeight: 500, color: '#ffffff' }}>{tagList[curIdx].clip_name}</Typography>
                    </div>
                )}
            </div>
        </FullScreen>
    );
}
