import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import GameOverviewHeader from '../overview/header';
import GameService from '../../../../../services/game.service';
import GameStatsChart from './statChart';
import { getSeriesData } from '../../../../../common/utilities';
import GameStatsVideoPlayer from './videoDialog';

const GameStats = ({ game, t }) => {
    const [values, setValues] = useState({
        isOur: true,
        expandButtons: true,
        playerList: [],
        teamId: -1,
        opponentTeamId: -1,
        selectAll: false,
        clickEventName: ''
    });
    const [videoOpen, setVideoOpen] = useState(false);
    const [playData, setPlayData] = useState([]);
    const [refresh, setRefresh] = useState(false);

    const { user: currentUser } = useSelector((state) => state.auth);

    const handleChangeTeam = (flag) => {
        setValues({ ...values, isOur: flag, playerList: [] });
    };

    useEffect(() => {
        (async () => {
            if (values.playerList.length === 0) {
                if (values.teamId === -1 || values.opponentTeamId === -1) {
                    let team = 0;
                    let opponent = 0;

                    await GameService.getAllMyCoachTeam().then((res) => {
                        const filtered = res.filter(
                            (item) => item.season_name === game.season_name && item.league_name === game.league_name && (item.team_id === game.home_team_id || item.team_id === game.away_team_id)
                        );

                        team = filtered[0].team_id;
                        opponent = team === game.home_team_id ? game.away_team_id : game.home_team_id;
                    });
                    await GameService.getGamePlayerTags(currentUser.id, values.isOur ? team : opponent, null, `${game.id}`, null, null, null, null, null, null, null, null).then((res) => {
                        setValues({ ...values, teamId: team, opponentTeamId: opponent, playerList: getSeriesData(res) });
                    });
                } else {
                    await GameService.getGamePlayerTags(currentUser.id, values.isOur ? values.teamId : values.opponentTeamId, null, `${game.id}`, null, null, null, null, null, null, null, null).then(
                        (res) => {
                            setValues({ ...values, playerList: getSeriesData(res) });
                        }
                    );
                }
            }
        })();
    }, [values, refresh]);

  
    const getSum = (dataArr) => {
        var sm = 0;
        dataArr.map(e => {
            sm = sm + e
        })
        return sm;
    }

    const getList = (series) => {

        if (series) {
            const llst = []
            const slst = []
            const clst = []
            series.forEach(element => {
                if (element?.data > 0) {
                    llst.push(element.name)
                    slst.push(element.data)
                    clst.push(element.color)
                }
            });
            return { seriesDonut: slst, labelsLst: llst, colorLst: clst }
        }
    }

    function getPeriod(id) {
        return id === 1 ? 'H1' : id === 2 ? 'H2' : 'OT';
    }

    const handleClick = (data) => {
        try {
            console.log('handleClick', data);
            const newData = data.map((item) => ({
                tag_id: item.id,
                start_time: item.player_tag_start_time,
                end_time: item.player_tag_end_time,
                player_name: item.player_names,
                action_name: item.action_names,
                action_type: item.action_type_names,
                action_result: item.action_result_names,
                game_id: item.game_id,
                team_id: values.teamId,
                court_area: item.court_area_id,
                inside_pain: item.inside_the_pain,
                period: getPeriod(item.period),
                time: item.time_in_game,
                home_team_image: item.home_team_logo,
                away_team_image: item.away_team_logo,
                home_team_goals: item.home_team_goal,
                away_team_goals: item.away_team_goal
            }));
            if (newData.length > 0) {
                setPlayData(newData)
                setVideoOpen(true)
            }
        } catch (error) {
            console.error(error);
        }
    };

    console.log("playerlist", values.playerList)


    return (
        <Box sx={{ width: '100%', background: '#ffffff', overflowY: 'auto', padding: '20px 10px', gap: '10px', maxHeight: '79vh', minHeight: '77vh' }}>
            <Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <GameOverviewHeader
                        isOur={values.isOur}
                        ourname={values.teamId === game.away_team_id ? game.away_team_name : game.home_team_name}
                        enemyname={values.opponentTeamId === game.home_team_id ? game.home_team_name : game.away_team_name}
                        onChangeTeam={handleChangeTeam}
                        mb="0px"
                    />
                </Box>
            </Box>
            <Grid container spacing={2}>
                {values.playerList.map((stat, index) => (
                    stat.total > 0 && (
                        <Grid item xs={12} sm={6}>
                            <GameStatsChart
                                className="mb-3"
                                title={stat.title}
                                seriesBar={stat.seriesBar}
                                seriesDonut={getList(stat.seriesBar)}
                                total={stat.total}
                                tags={stat.tags}
                                handleClick={handleClick}
                                key={index}
                                t={t}
                            />
                        </Grid>
                    )
                ))}
            </Grid>
            <GameStatsVideoPlayer
                t={t}
                open={videoOpen}
                onClose={(flag) => {
                    setVideoOpen(false);
                    if (flag) setRefresh((r) => !r);
                }}
                video_url={game.video_url}
                tagList={playData}
            />
        </Box>
    );
};

export default GameStats;
