import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { isEmail } from 'validator';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import randomString from 'randomstring';
import CryptoJS from 'crypto-js';
import { SECRET } from '../../config/settings';
import useDeviceToken from '../../helpers/useDeviceToken';
import { login, logout, verification } from '../../actions/auth';
import { closeLoginPanel, openRegisterPanel, openForgotPanel } from '../../actions/settings.action';
import { Navigate, useParams } from 'react-router-dom';

const Login = () => {
    const { t } = useTranslation();
    const deviceToken = useDeviceToken();
    const params = useParams();
    const [cookies, setCookie] = useCookies(['data']);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { message } = useSelector((state) => state.message);
    const { isLoggedIn, user: currentUser } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    // Cookie handling
    useEffect(() => {
        if (!Object.keys(cookies).length) {
            const data = { device: randomString.generate(16), createDate: new Date() };
            setCookie('data', CryptoJS.AES.encrypt(JSON.stringify(data), SECRET).toString());
        }
    }, [cookies, setCookie]);

    const handleLogin = (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage('');

        // Validation
        if (!email) {
            setErrorMessage(t('Email field is required!'));
            setLoading(false);
            return;
        }
        if (!isEmail(email)) {
            setErrorMessage(t('Please type valid email.'));
            setLoading(false);
            return;
        }
        if (!password) {
            setErrorMessage(t('Password field is required!'));
            setLoading(false);
            return;
        }

        dispatch(login(email, password, deviceToken))
            .then(() => {
                dispatch(closeLoginPanel());
            })
            .catch((e) => {
                // console.log(e);
                if (message) {
                    setErrorMessage(t(message));
                }
                else {
                    setErrorMessage(t("Login Failed. Please try again!"));
                }
                setLoading(false);
            });
    };

    // Conditional navigation based on user roles
    if (isLoggedIn) {
        if (currentUser.roles.includes('ROLE_ADMIN')) return <Navigate to="/admin" />;
        else if (currentUser.roles.includes('ROLE_COACH')) return <Navigate to="/new_coach" />;
        else return <Navigate to="/" />;
    }

    return (
        <div className="col-md-12">
            <Box p={3} mt={2}>
                <img src="//ssl.gstatic.com/accounts/ui/avatar_2x.png" alt="profile-img" className="profile-img-card" />
                <form onSubmit={handleLogin}>
                    <div className="form-group">
                        <label htmlFor="email">{t('Email')}</label>
                        <input
                            type="text"
                            className="form-control"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value.toLowerCase())}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">{t('Password')}</label>
                        <input
                            type="password"
                            className="form-control"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>

                    <div className="form-group">
                        <button className="btn btn-primary btn-block" disabled={loading}>
                            {loading && <span className="spinner-border spinner-border-sm"></span>}
                            <span>{t('Login')}</span>
                        </button>
                    </div>

                    {errorMessage && (
                        <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                                {errorMessage}
                            </div>
                        </div>
                    )}

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <label style={{ cursor: 'pointer' }} onClick={() => dispatch(openForgotPanel())}>
                            {t('ForgotPassword')}
                        </label>
                        <label style={{ cursor: 'pointer' }} onClick={() => dispatch(openRegisterPanel())}>
                            {t('Register')}
                        </label>
                    </div>
                </form>
            </Box>
        </div>
    );
};

export default Login;

