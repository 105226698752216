import GameService from '../services/game.service';

import {
    setTeamsList,
    setSeasonList,
    setLeagueList,
    setPlayersList,
    setGamesList,
    setStandingList,
    setPositions,
    setRoles,
    setAgeGroups,
    setPlayersStatsAdvanceSummaryList,
    setGoalkeepersStatsAdvanceSummaryList
} from '../helpers/coachSlice';

import { getSeasonList, getLeagueList, getPlayerList } from '../helpers/coach';

export const getLists = () => (dispatch) => {
    const fetchDataPromises = [
        GameService.getAllMyCoachTeam().catch((error) => console.error('Error in getAllMyCoachTeam:', error)),
        GameService.getAllPlayersByCoach().catch((error) => console.error('Error in getAllPlayersByCoach:', error)),
        GameService.getTeamInitialStanding().catch((error) => console.error('Error in getTeamInitialStanding:', error)),
        GameService.getAllGamesByCoach(null, null, null, null).catch((error) => console.error('Error in getAllGamesByCoach:', error)),
        GameService.getAllPositions().catch((error) => console.error('Error in getAllPositions:', error)),
        GameService.getAllRoles().catch((error) => console.error('Error in getAllRoles:', error)),
        GameService.getAllAgeGroups().catch((error) => console.error('Error in getAllAgeGroups:', error))
    ];

    Promise.all(fetchDataPromises)
        .then((results) => {
            dispatch(setTeamsList(results[0]));
            dispatch(setSeasonList(getSeasonList(results[0])));
            dispatch(setLeagueList(getLeagueList(results[0])));
            dispatch(setPlayersList(results[1]));
            dispatch(setStandingList(results[2]));
            dispatch(setGamesList(results[3]));
            dispatch(setPositions(results[4]));
            dispatch(setRoles(results[5]));
            dispatch(setAgeGroups(results[6]));
        })
        .catch((error) => {
            console.error('An error occurred while fetching data:', error);
        });
};

export const getGames = (seasonID) => async (dispatch) => {
    await GameService.getAllGamesByCoach(seasonID === 'none' ? null : seasonID.id, null, null, null).then((res) => {
        dispatch(setGamesList(res));
    });
};

export const getPlayersStatsAdvanceSummaryList = (seasonFilter, leagueIds, teamIds, gameIds) => async (dispatch) => {
    dispatch(setPlayersStatsAdvanceSummaryList({ season_name: seasonFilter === 'none' ? 'all' : seasonFilter.name, data: [] }));
    await GameService.getPlayersStatsAdvanceSummary({
        seasonId: seasonFilter === 'none' ? null : seasonFilter.id,
        leagueId: leagueIds.length > 0 ? leagueIds.join(',') : null,
        gameId: gameIds.length > 0 ? gameIds.join(',') : null,
        teamId: teamIds.length > 0 ? teamIds.join(',') : null,
        playerId: null,
        gameTime: null,
        courtAreaId: null,
        insidePaint: null,
        homeAway: null,
        gameResult: null
    }).then((res) => {
        dispatch(setPlayersStatsAdvanceSummaryList({ season_name: seasonFilter === 'none' ? 'all' : seasonFilter.name, data: res }));
    });
};

export const getGoalkeepersStatsAdvanceSummaryList = (seasonFilter, leagueIds, teamIds, gameIds) => async (dispatch) => {
    dispatch(setGoalkeepersStatsAdvanceSummaryList({ season_name: seasonFilter === 'none' ? 'all' : seasonFilter.name, data: [] }));

    await GameService.getGoalkeepersStatsAdvanceSummary({
        seasonId: seasonFilter === 'none' ? null : seasonFilter.id,
        leagueId: leagueIds.length > 0 ? leagueIds.join(',') : null,
        gameId: gameIds.length > 0 ? gameIds.join(',') : null,
        teamId: teamIds.length > 0 ? teamIds.join(',') : null,
        playerId: null,
        gameTime: null,
        courtAreaId: null,
        insidePaint: null,
        homeAway: null,
        gameResult: null
    }).then((res) => {
        dispatch(setGoalkeepersStatsAdvanceSummaryList({ season_name: seasonFilter === 'none' ? 'all' : seasonFilter.name, data: res }));
    });
};
