import React from "react";
import "./../PlayerDetails.css";
import "./../responsive.css";
import "./../lightslider.css";


const Bar = ({ title, seriesBar, total, tags, handleClick }) => {
  const getPercent = (val) => {
    var per = (val * 100.00 / total)
    return per + '%'
  }
  return (
    <div className="player-pogress-col" >
      <div className="match-period">
        <h3>{title}</h3>
      </div>
      <div className="bar-status-row">
        {seriesBar.map((dt, idx) => (
          <span key={`lbl${idx}`} /*style={{cursor:"pointer"}}*/><b style={{ backgroundColor: dt.color }}  ></b>{dt.name}</span>
        ))}
      </div>
      <div className="bar-value-outer">
        <div className="bar-line-outer">
          {seriesBar.map((dt, idx) => (
            dt.data !== 0 && 
              <span 
                style={{ backgroundColor: dt.color, width: getPercent(dt.data), cursor: "pointer" }} 
                key={`bar${idx}`} 
                onClick={() => handleClick(dt.tags)}
                >{dt.data}</span>
          ))}
        </div>
        <div className="bar-value" >
          <span>{total}</span>
        </div>
      </div>
    </div>
  );
}
export default Bar;
