import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { CookiesProvider } from 'react-cookie';

import store from './store';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration'; // updated import

i18next
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        react: {
            useSuspense: false //   <---- this will do the magic
        },
        fallbackLng: 'en',
        debug: false,
        // Options for language detector
        detection: {
            order: ['path', 'cookie', 'htmlTag'],
            caches: ['cookie']
        },
        backend: {
            loadPath: `/locales/{{lng}}/admin_coach.json`
        }
    });

ReactDOM.createRoot(document.getElementById('root')).render(
    <Provider store={store}>
        <CookiesProvider>
            <App />
        </CookiesProvider>
    </Provider>
);

serviceWorkerRegistration.register(); // updated to match the new import

