import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Dialog, DialogContent, DialogTitle, TextField, Select, MenuItem, FormControlLabel, Switch, CircularProgress } from '@mui/material';
import { ChromePicker } from 'react-color';

import CloseIcon from '@mui/icons-material/CloseOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined';
import { TEAM_ICON_DEFAULT } from '../../../common/staticData';

import { SaveButton } from '../components/common';
import UploadButton from '../components/uploadButton';
import lang from '../../../assets/lang.json';
import GameService from '../../../services/game.service';
import '../coach_style.css';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

const TeamEditDialog = ({ open, onClose, team, t, onUpdateTeam }) => {
    const navigate = useNavigate();
    const [values, setValues] = useState({
        id: 0,
        name: '',
        short_name: '',
        image: '',
        team_color: '',
        second_color: '',
        sponsor_logo: '',
        sponsor_url: '',
        show_sponsor: false,
        create_highlights: false,
        team_language: 'en',
        filter_by_position: false
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (prop) => (e) => {
        setValues({ ...values, [prop]: e.target.value });
    };

    const saveChanges = () => {
        setLoading(true);
        GameService.updateTeam(values)
            .then((res) => {
                setLoading(false);
                onUpdateTeam(values);
            })
            .catch((e) => {
                setLoading(false);
            });
        onClose();
        navigate('/new_coach/teams/');
    };

    useEffect(() => {
        setValues({
            ...values,
            id: team.team_id,
            name: team.team_name,
            short_name: team.team_short_name,
            image: team.team_image ?? '',
            team_color: team.team_color ?? 'white',
            second_color: team.team_second_color ?? 'white',
            sponsor_logo: team.team_sponsor_logo ?? '',
            sponsor_url: team.team_sponsor_url ?? '',
            show_sponsor: team.team_show_sponsor ?? false,
            create_highlights: team.team_create_highlights ?? false,
            team_language: team.team_language ?? 'en',
            filter_by_position: team.team_filter_by_position ?? false
        });
    }, [team]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="1500px" maxheight="initial">
            <DialogTitle>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '4px', cursor: 'pointer' }} onClick={onClose}>
                    <CloseIcon sx={{ width: '18px', height: '18px' }} />
                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d' }}>{t('Close')}</Typography>
                </Box>
            </DialogTitle>
            <DialogContent style={{ display: 'flex', margin: '0 30px', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '16px' }}>
                {loading && (
                    <div style={{ width: '100%', height: '100%', zIndex: 9999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                    </div>
                )}

                <Box sx={{ marginBottom: '-40px', display: 'flex', alignItems: 'center', gap: '16px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '16px' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '4px' }}>
                            <p className="select-narrator">{t('Team Logo')}</p>
                            <UploadButton
                                class_name="upload-team-view"
                                id_name="team-logo"
                                dirName={process.env.REACT_APP_DIR_TEAM}
                                img={values.image ? (values.image.includes(process.env.REACT_APP_S3_URI) ? values.image : process.env.REACT_APP_S3_URI + values.image) : TEAM_ICON_DEFAULT}
                                onURL={(url) => setValues({ ...values, image: url })}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '4px' }}>
                            <p className="select-narrator">{t('Sponsor Logo')}</p>
                            <UploadButton
                                class_name="upload-sponsor-view"
                                id_name="sponsor-logo"
                                dirName={process.env.REACT_APP_DIR_TEAM}
                                img={values.sponsor_logo ? (values.sponsor_logo.includes(process.env.REACT_APP_S3_URI) ? values.sponsor_logo : process.env.REACT_APP_S3_URI + values.sponsor_logo) : TEAM_ICON_DEFAULT}
                                onURL={(url) => setValues({ ...values, sponsor_logo: url })}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', padding: '16px 0' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '32px', marginBottom: '16px' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '300px' }}>
                                <p className="select-narrator">{t('Team Color')}</p>
                                <ChromePicker
                                    color={values.team_color || 'ffffff'}
                                    onChange={(color) => setValues({ ...values, team_color: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` })}
                                    style={{ '& .ChromePicker-MuiInputBase-input': { color: '#1a1b1d', height: '50px' } }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '300px' }}>
                                <p className="select-narrator">{t('Second Color')}</p>
                                <ChromePicker
                                    color={values.second_color || 'ffffff'}
                                    onChange={(color) => setValues({ ...values, second_color: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` })}
                                    style={{ '& .ChromePicker-MuiInputBase-input': { color: '#1a1b1d', height: '50px' } }}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '32px', marginBottom: '16px' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                <p className="select-narrator">{t('Team Name')}</p>
                                <TextField
                                    value={values.name}
                                    onChange={handleChange('name')}
                                    label=""
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    variant="outlined"
                                    placeholder={t('Team Name')}
                                    sx={{ borderRadius: '10px', height: '48px', width: '300px', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                <p className="select-narrator">{t('Team Short Name')}</p>
                                <TextField
                                    value={values.short_name}
                                    onChange={handleChange('short_name')}
                                    label=""
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    variant="outlined"
                                    placeholder={t('Team Short Name')}
                                    sx={{ borderRadius: '10px', height: '48px', width: '300px', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '32px', marginBottom: '16px' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                <p className="select-narrator">{t('Sponsor URL')}</p>
                                <TextField
                                    value={values.sponsor_url}
                                    onChange={handleChange('sponsor_url')}
                                    label=""
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    variant="outlined"
                                    placeholder={t('Sponsor URL')}
                                    sx={{ borderRadius: '10px', height: '48px', width: '300px', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                />
                                <FormControlLabel
                                    sx={{ mt: 1 }}
                                    control={
                                        <Switch
                                            checked={values.show_sponsor}
                                            onChange={() => setValues({ ...values, show_sponsor: !values.show_sponsor })}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                    label={t('Show Sponsor')}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', marginBottom: '55px' }}>
                                <p className="select-narrator">{t('Team Language')}</p>
                                <Select
                                    value={values.team_language}
                                    onChange={handleChange('team_language')}
                                    label=""
                                    variant="outlined"
                                    IconComponent={ExpandMoreIcon}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    MenuProps={MenuProps}
                                    sx={{ outline: 'none', height: '55px', width: '300px', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                >
                                    {lang.map((item, index) => (
                                        <MenuItem key={index} value={item.code}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <SaveButton onClick={saveChanges} sx={{ width: '300px', fontSize: '0.7rem' }}>
                    {t('Save changes')}
                </SaveButton>
            </DialogContent>
        </Dialog>
    );
};

export default TeamEditDialog;
