import React, { useState } from "react";
import "./PlayerDetails.css";
import "./responsive.css";
import "./lightslider.css";

import { PieChart, Pie, Cell } from 'recharts';
import GameExportToEdits from '../newcoach/games/tabs/overview/exportEdits';
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + 8; // Adjust the radius to position the label outside the pie chart
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const displayPercent = percent === 1 ? (percent * 100) : (percent * 100).toFixed(1);

    if (displayPercent < 1) return null;

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {displayPercent}%
        </text>
    );
};

const PlayerStatsChart = ({ title, seriesBar, total, tags, handleClick, t }) => {
    const [exportOpen, setExportOpen] = useState(false);
    const [playData, setPlayData] = useState([]);
    const getPercent = (val) => {
        var per = (val * 100.00 / total)
        return per + '%'
    }

    const handlePieExportTags = (videoList) => (e) => {
        console.log("sdfdsfsf")
        e.preventDefault();
        setPlayData(videoList);
        setExportOpen(true);
    };

    return (
        <div
            className="player-pogress-col"
            style={{
                width: '100%',
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                padding: '15px',
                borderRadius: "20px",
                background: "linear-gradient(114deg, rgba(21, 44, 54, 1) 0%, rgba(48, 87, 110, 1) 100%)",
                border: "2px solid darkgray"
            }}>
            <div className="match-period">
                <h3>{title}</h3>
            </div>
            <div className="bar-status-row">
                {seriesBar.map((dt, idx) => (
                    <span key={`lbl${idx}`} /*style={{cursor:"pointer"}}*/><b style={{ backgroundColor: dt.color }} ></b>{dt.name}</span>
                ))}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <PieChart width={240} height={240}>
                    <Pie
                        data={seriesBar}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        isAnimationActive={false}
                        label={renderCustomizedLabel}
                        outerRadius={75}
                        fill="#8884d8"
                        dataKey="data"
                    >
                        {seriesBar.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={entry.color}
                                onClick={() => handleClick(entry.tags)}
                                onContextMenu={(e) => handlePieExportTags(entry.tags)} // Prevent default context menu
                            />
                        ))}
                    </Pie>
                </PieChart>
            </div>
            <div className="bar-value-outer">
                <div className="bar-line-outer">
                    {seriesBar.map((dt, idx) => (
                        dt.data !== 0 && 
                            <span 
                                style={{ backgroundColor: dt.color, width: getPercent(dt.data), cursor: "pointer" }} 
                                key={`bar${idx}`} 
                                onClick={() => handleClick(dt.tags)}>{dt.data}</span>
                    ))}
                </div>
                <div className="bar-value" >
                    <span>{total}</span>
                </div>
            </div>
            <GameExportToEdits t={t} open={exportOpen} onClose={() => setExportOpen(false)} tagList={playData} isTeams={false} />
        </div>
    );
}
export default PlayerStatsChart;
