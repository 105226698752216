import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';

import PlayersIcon from '@mui/icons-material/PersonOutlineOutlined';
import SortIcon from '@mui/icons-material/SortOutlined';

import { TEAM_ICON_DEFAULT } from '../../../common/staticData';
import TeamEditDialog from './teamEditDialog';
import gameService from '../../../services/game.service';
import {
    setTeamsList,
} from '../../../helpers/coachSlice';

const TeamListItem = ({ team, isHover, t }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    const handleChangePath = (teamId, seasonId, leagueId, imageUrl) => () => {
        const encodedImageUrl = encodeURIComponent(imageUrl);
        navigate(`/new_coach/teams/${btoa(`${teamId}|${seasonId}|${leagueId}|${encodedImageUrl}`)}`);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    const updateTeam = () => {
        gameService.getAllMyCoachTeam().then((res) => {
            dispatch(setTeamsList(res));
        })
    };

    return (
        <Box
            sx={{
                padding: '12px 18px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '8px',
                backgroundColor: '#ffffff',
                borderRadius: '10px',
                cursor: 'pointer',
                transition: 'box-shadow 0.3s ease',
                minWidth: '850px',
                fontWeight: 500,
                height: '70px',
                width: '100%',
                boxShadow: isHover ? '0px 4px 16px rgba(0, 0, 0, 0.1)' : 'none'
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', flex: 22 }} onClick={handleChangePath(team.team_id, team.season_id, team.league_id, team.team_image)}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
                    <img style={{ height: '48px' }} 
                    src={ team.team_image ? (team.team_image.includes(process.env.REACT_APP_S3_URI) ? team.team_image : process.env.REACT_APP_S3_URI + team.team_image) 
                        : TEAM_ICON_DEFAULT} alt="Team Logo" />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', flex: 4, padding: '0 8px' }}>
                    <p className="normal-text-strong">{team.team_name}</p>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', flex: 4, padding: '0 8px' }}>
                    <p className="normal-text-strong">{team.team_short_name}</p>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', flex: 3, padding: '0 8px' }}>
                    <p className="normal-text">{team.league_name}</p>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', flex: 2, padding: '0 8px' }}>
                    <p className="normal-text">{team.season_name}</p>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textTransform: 'uppercase', flexDirection: 'column', gap: '4px', flex: 2, padding: '0 8px' }}>
                    <p className="normal-text">{t('Games')}</p>
                    <p className="normal-text">{team.total_game_played}</p>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textTransform: 'uppercase', flexDirection: 'column', gap: '4px', flex: 2, padding: '0 8px' }}>
                    <p className="normal-text">{t('Won')}</p>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#52B030', borderRadius: '12px', width: '24px', height: '24px' }}>
                            <p className="normal-text-white">W</p>
                        </Box>
                        <p className="normal-text-strong">{team.won}</p>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '4px', flex: 2, padding: '0 8px' }}>
                    <p className="normal-text" style={{ textTransform: 'uppercase' }}>
                        {t('Draw')}
                    </p>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#A4AAB0', borderRadius: '12px', width: '24px', height: '24px' }}>
                            <p className="normal-text-white">D</p>
                        </Box>
                        <p className="normal-text-strong">{team.draw}</p>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '4px', flex: 2, padding: '0 8px' }}>
                    <p className="normal-text" style={{ textTransform: 'uppercase' }}>
                        {t('Lose')}
                    </p>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#C1272D', borderRadius: '12px', width: '24px', height: '24px' }}>
                            <p className="normal-text-white">L</p>
                        </Box>
                        <p className="normal-text-strong">{team.lost}</p>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textTransform: 'uppercase', flexDirection: 'column', gap: '4px', flex: 2, padding: '0 8px' }}>
                    <p className="normal-text">{t('Goals')}</p>
                    <p className="normal-text-strong">{team.goals}</p>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', 'svg path': { fill: '#FE5E00' }, marginLeft: '8px', flex: 2 }}>
                    <PlayersIcon />
                    <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: '4px' }}>
                        <p className="normal-text">{t('Players')}</p>
                        <p className="normal-text">{team.player_count}</p>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    flex: 0.5,
                    'svg path': { fill: '#A5A5A8' },
                    '&:hover': { 'svg path': { fill: '#17375E' } }
                }}
                onClick={() => setOpen(true)}
            >
                <SortIcon />
            </Box>
            <TeamEditDialog open={open} t={t} onClose={handleCloseDialog} team={team} onUpdateTeam={updateTeam} />
        </Box>
    );
};

export default TeamListItem;
