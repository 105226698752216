import React, { useReducer, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, CircularProgress, MenuItem, Select } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined';

import LeadersPlayerStatColumn from './playerStatColumn';
import { MenuProps } from '../components/common';
import PlayerStatDialog from '../players/status';

import SeasonContext from '../../../common/Context';
import { getFilteredArray } from '../../../helpers/coach';
import { setPlayersStatsAdvanceSummaryList, setGoalkeepersStatsAdvanceSummaryList } from '../../../helpers/coachSlice';

//Missing more categories
const statCategory = [
    { id: 'player_games', title: 'Games Played' },
    { id: 'games_starting', title: 'Starting 11' },
    { id: 'games_minutes', title: 'Minutes Played' },
    { id: 'goal', title: 'Goals' },

    { id: 'passes', title: 'Passes' },
    { id: 'successful_passes', title: 'Successful Passes' },
    { id: 'passes_for_goals', title: 'Assists' },
    { id: 'key_passes', title: 'Key Passes' },

    { id: 'through_passes', title: 'Through Passes' },
    { id: 'through_passes_successful', title: 'Through Passes Successful' },
    { id: 'passes_for_shots', title: 'Passes For Shots' },   
    { id: 'shot', title: 'Shots' },

    { id: 'shot_on_target', title: 'Shots On Target' },
    { id: 'shot_off_target', title: 'Shots Off Target' },
    { id: 'shot_on_box', title: 'Shots On Box' },
    { id: 'shot_out_of_box', title: 'Long Shots' },

    { id: 'free_kick', title: 'Free Kicks' },
    { id: 'crosses', title: 'Crosses' },
    { id: 'penalties', title: 'Penalties' },
    { id: 'penalties_missed', title: 'Missed Penalties' },
 
    { id: 'dribble', title: 'Dribbles' },
    { id: 'dribble_successful', title: 'Successful 1v1 Attacking' },
    { id: 'dribble_unsuccessful', title: 'Unsuccessful 1v1 Attacking' },
    { id: 'turnover', title: 'Turnovers' },

    { id: 'turnover_on_defensive_court', title: 'Turnovers on Defensive Court' },
    { id: 'turnover_on_offensive_court', title: 'Turnovers on Offensive Court' },
    { id: 'offside', title: 'Offside' },
    { id: 'own_goals', title: 'Own Goals' },

    { id: 'tackle', title: 'Tackles' },
    { id: 'tackle_on_offensive_court', title: 'Tackles on Offensive Court' },
    { id: 'tackle_on_defensive_court', title: 'Tackles on Defensive Court' },
    { id: 'saved', title: 'Saved & Super Saved' },

    { id: 'clearance', title: 'Clearance' },
    { id: 'interception', title: 'Interceptions' },
    { id: 'interception_on_defensive_court', title: 'Interceptions on Defensive Court' },
    { id: 'interception_on_offensive_court', title: 'Interceptions on Offensive Court' },

    { id: 'fouls', title: 'Fouls' },
    { id: 'draw_fouls', title: 'Draw Fouls' },
    { id: 'red_cards', title: 'Red Cards' },
    { id: 'yellow_cards', title: 'Yellow Cards' }
];

const Leaders = ({ t }) => {
    const { seasonFilter } = React.useContext(SeasonContext);
    const [state, setState] = useReducer((old, action) => ({ ...old, ...action }), {
        hoverIndex: -1,
        leagueFilter: 'none',
        loading: false,
        teamFilter: 'none',
        playerFilter: 'none'
    });

    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [filteredPlayers, setFilteredPlayers] = useState([]);

    const teamsList = useSelector((state) => state.coach.teamsList);
    const leagueList = useSelector((state) => state.coach.leagueList);
    const gamesList = useSelector((state) => state.coach.gamesList);
    const playersStatsAdvanceSummaryList = useSelector((state) => state.coach.playersStatsAdvanceSummaryList);
    const goalkeepersStatsAdvanceSummaryList = useSelector((state) => state.coach.goalkeepersStatsAdvanceSummaryList);

    const { leagueFilter, teamFilter, playerFilter } = state;

    const [loading, setLoading] = useState(false);
    const [displayOption, setDisplayOption] = useState('total');
    const [currentPlayer, setCurrentPlayer] = useState(null);
    const [statOpen, setStatOpen] = useState(false);
    const [gameList, setGameList] = useState([]);
    const [seasonTeamsList, setSeasonTeamsList] = useState([]);
    const [seasonLeaguesList, setSeasonLeaguesList] = useState([]);

    const dispatch = useDispatch();

    const handleChange = (prop) => (e) => {
        setState({ ...state, [prop]: e.target.value });
    };

    const handleDisplayStatDialog = (player) => {
        const player_games = gamesList.filter((game) => game.season_id === seasonFilter.id && (game.home_team_id === player.team_id || game.away_team_id === player.team_id));
        setGameList(player_games);
        setCurrentPlayer(player);
        setStatOpen(true);
    };

    const updatePlayer = (newPlayer) => {
        if (playersStatsAdvanceSummaryList && playersStatsAdvanceSummaryList.hasOwnProperty(seasonFilter === 'none' ? 'all' : seasonFilter.name)) {
            dispatch(
                setPlayersStatsAdvanceSummaryList({
                    season_name: seasonFilter === 'none' ? 'all' : seasonFilter.name,
                    data: playersStatsAdvanceSummaryList[seasonFilter === 'none' ? 'all' : seasonFilter.name].map((player) => {
                        if (player.player_id === newPlayer.player_id) {
                            return { ...player, ...newPlayer };
                        }
                        return player;
                    })
                })
            );
            if (newPlayer.player_position_name === 'Goalkeeper') {
                dispatch(
                    setGoalkeepersStatsAdvanceSummaryList({
                        season_name: seasonFilter === 'none' ? 'all' : seasonFilter.name,
                        data: goalkeepersStatsAdvanceSummaryList[seasonFilter === 'none' ? 'all' : seasonFilter.name].map((player) => {
                            if (player.player_id === newPlayer.player_id) {
                                return { ...player, ...newPlayer };
                            }
                            return player;
                        })
                    })
                );
            }
        }
    };

    useEffect(() => {
        (async () => {
            if (
                playersStatsAdvanceSummaryList &&
                playersStatsAdvanceSummaryList.hasOwnProperty(seasonFilter === 'none' ? 'all' : seasonFilter.name) &&
                playersStatsAdvanceSummaryList[seasonFilter === 'none' ? 'all' : seasonFilter.name].length
            )
                setLoading(false);
        })();
    }, [selectedPlayers]);

    useEffect(() => {
        (async () => {
            if (playersStatsAdvanceSummaryList && playersStatsAdvanceSummaryList.hasOwnProperty(seasonFilter === 'none' ? 'all' : seasonFilter.name)) {
                const filtered_arr = getFilteredArray(playersStatsAdvanceSummaryList[seasonFilter === 'none' ? 'all' : seasonFilter.name], seasonFilter, null, null, teamFilter, playerFilter);
                setSelectedPlayers(filtered_arr);
            }
        })();
    }, [playersStatsAdvanceSummaryList, leagueFilter, teamFilter, playerFilter, seasonFilter]);

    console.log('playersStatsAdvanceSummaryList',playersStatsAdvanceSummaryList);

    useEffect(() => {
        (async () => {
            setState({ playerFilter: 'none' });
            if (playersStatsAdvanceSummaryList && playersStatsAdvanceSummaryList.hasOwnProperty(seasonFilter === 'none' ? 'all' : seasonFilter.name)) {
                const filtered_arr = getFilteredArray(playersStatsAdvanceSummaryList[seasonFilter === 'none' ? 'all' : seasonFilter.name], seasonFilter, null, null, teamFilter, null);
                setFilteredPlayers(filtered_arr);
            }
        })();
    }, [playersStatsAdvanceSummaryList, seasonFilter, leagueFilter, teamFilter]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            if (teamsList.length) {
                const filtered_arr = getFilteredArray(teamsList, seasonFilter, leagueFilter, null, null);
                setSeasonTeamsList(filtered_arr);
                setState({ teamFilter: 'none' });
                setState({ playerFilter: 'none' });
            }
        })();
    }, [leagueFilter, teamsList]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            if (leagueList.length) {
                const filteredLeagues = leagueList.filter((league) => league.season_id === seasonFilter.id);
                setSeasonLeaguesList(filteredLeagues);
                setState({ leagueFilter: 'none' });
            }
            if (teamsList.length) {
                const filtered_arr = getFilteredArray(teamsList, seasonFilter, leagueFilter, null, null);
                setSeasonTeamsList(filtered_arr);
                setState({ teamFilter: 'none' });
            }
            setState({ playerFilter: 'none' });
        })();
    }, [seasonFilter, leagueList]);

    return (
        <Box sx={{ width: '98%', margin: '0 auto' }}>
            <Box sx={{ padding: '24px 24px 24px 24px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <p className="page-title">{t('Rankings')}</p>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '24px', paddingLeft: '30px' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <p className="select-narrator">{t('Leagues')}</p>
                            <Select
                                value={leagueFilter}
                                onChange={handleChange('leagueFilter')}
                                label=""
                                variant="outlined"
                                IconComponent={ExpandMoreIcon}
                                inputProps={{ 'aria-label': 'Without label' }}
                                MenuProps={MenuProps}
                                sx={{ outline: 'none', height: '36px', width: '170px', fontSize: '0.8rem', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                            >
                                <MenuItem key="0" value="none">
                                    {t('All')}
                                </MenuItem>
                                {seasonLeaguesList.map((league, index) => (
                                    <MenuItem key={index + 1} value={league}>
                                        {league.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <p className="select-narrator">{t('Teams')}</p>
                            <Select
                                value={teamFilter}
                                onChange={handleChange('teamFilter')}
                                label=""
                                variant="outlined"
                                IconComponent={ExpandMoreIcon}
                                inputProps={{ 'aria-label': 'Without label' }}
                                MenuProps={MenuProps}
                                sx={{ outline: 'none', height: '36px', width: '220px', fontSize: '0.8rem', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                            >
                                <MenuItem key="0" value="none">
                                    {t('All')}
                                </MenuItem>
                                {seasonTeamsList.map((team, index) => (
                                    <MenuItem key={index + 1} value={team}>
                                        {team.team_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <p className="select-narrator">{t('Display')}</p>
                            <Select
                                value={displayOption}
                                onChange={(e) => setDisplayOption(e.target.value)}
                                label=""
                                variant="outlined"
                                IconComponent={ExpandMoreIcon}
                                inputProps={{ 'aria-label': 'Without label' }}
                                MenuProps={MenuProps}
                                sx={{ outline: 'none', height: '36px', width: '110px', fontSize: '0.8rem', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                            >
                                <MenuItem key="0" value="total">
                                    {t('Total')}
                                </MenuItem>
                                <MenuItem key="1" value="average">
                                    {t('Average')}
                                </MenuItem>
                            </Select>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <p className="select-narrator">{t('Player')}</p>
                            <Select
                                value={playerFilter}
                                onChange={handleChange('playerFilter')}
                                label=""
                                variant="outlined"
                                IconComponent={ExpandMoreIcon}
                                inputProps={{ 'aria-label': 'Without label' }}
                                MenuProps={MenuProps}
                                sx={{ outline: 'none', height: '36px', width: '110px', fontSize: '0.8rem', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                            >
                                <MenuItem key="0" value="none">
                                    {t('All')}
                                </MenuItem>
                                {filteredPlayers.map((player, index) => (
                                    <MenuItem key={index + 1} value={player}>
                                        {player.player_full_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {loading && (
                <div style={{ width: '100%', height: '80%', zIndex: 9999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && selectedPlayers.length > 0 && (
                <Box sx={{ width: '100%', maxHeight: '85vh', overflowY: 'auto' }}>
                    <Box sx={{ fontSize: '0.8rem', display: 'grid', gridTemplateColumns: 'auto auto auto auto', gap: '4px'}}>
                        {statCategory.map((item) => (
                            <LeadersPlayerStatColumn
                                key={item.id}
                                list={selectedPlayers}
                                isTotal={item.id === 'player_games' ? true : displayOption === 'total'}
                                option={item.id}
                                title={item.title}
                                onClick={handleDisplayStatDialog}
                                t={t}
                            />
                        ))}
                    </Box>
                </Box>
            )}
            <PlayerStatDialog
                open={statOpen}
                t={t}
                onClose={() => {
                    setStatOpen(false);
                }}
                player={currentPlayer}
                games={gameList}
                gameIds={gameList.map((item) => item.id)}
                updatePlayer={updatePlayer}
                page="yes"
            />
        </Box>
    );
};

export default Leaders;
