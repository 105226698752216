import { getComparator, stableSort } from '../components/newcoach/components/utilities';

export const getSeasonList = (data) => {
    if (data.length > 0) {
        const desc = stableSort(data, getComparator('desc', 'season_name'));
        let result = [];
        desc.forEach((item) => {
            if (!result.find((r) => r.id === item.season_id && r.name === item.season_name)) {
                result.push({ id: item.season_id, name: item.season_name });
            }
        });
        return result;
    }
    return [];
};

export const getLeagueList = (data) => {
    if (data.length > 0) {
        const desc = stableSort(data, getComparator('desc', 'league_name'));
        let result = [];
        desc.forEach((item) => {
            if (!result.find((r) => r.id === item.league_id && r.name === item.league_name)) {
                result.push({ id: item.league_id, name: item.league_name, season_id: item.season_id });
            }
        });
        return result;
    }
    return [];
};

export const getLeagueIds = (array) => {
    if (array.length > 0) {
        let result = [];
        array.map((item) => {
            const filter = result.filter((league) => league === item.id);
            if (filter.length === 0) result = [...result, item.id];
            return result;
        });
        return result;
    }
    return [];
};

export const getTeamIds = (array, seasonId) => {
    if (array.length > 0) {
        let result = [];
        array.map((item) => {
            const filter = result.filter((team) => team === item.team_id);
            if (filter.length === 0 && item.season_id === seasonId) result = [...result, item.team_id];
            return result;
        });
        return result;
    }
    return [];
};

export const getGameIds = (array, seasonId) => {
    if (array.length > 0) {
        let result = [];
        array.map((item) => {
            const filter = result.filter((game) => game === item.id);
            if (filter.length === 0 && item.season_id === seasonId) result = [...result, item.id];
            return result;
        });
        return result;
    }
    return [];
};

export const getFilteredArray = (array, seasonFilter = null, leagueFilter = null, games, teamFilter = null, playerFilter = null) => {
    let keys = [];

    if (seasonFilter !== 'none' && seasonFilter !== null) keys.push({ key: 'season_id', value: seasonFilter.id });
    if (leagueFilter !== 'none' && leagueFilter !== null) keys.push({ key: 'league_id', value: leagueFilter.id });
    if (teamFilter !== 'none' && teamFilter !== null) keys.push({ key: 'team_id', value: teamFilter.team_id });
    if (playerFilter !== 'none' && playerFilter !== null) keys.push({ key: 'player_id', value: playerFilter.player_id });

    // if no filters are provided, return the original array
    if (!keys.length) return array;
    if (array.length) {
        let res_array = array.filter((item) => {
            return keys.every((key) => item[key.key] === key.value);
        });

        if (games !== 'none' && games !== null && games.length) {
            return res_array.filter((res_item) => {
                return games.every((game) => res_item.game_id === game.id);
            });
        } else return res_array;
    }
    return [];
};
