import React, { useEffect, useState } from 'react';
import { Box, Button, Avatar, Dialog, DialogContent, Divider, Popover, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { green, grey, red } from '@mui/material/colors';
import { TEAM_ICON_DEFAULT } from '../../../../common/staticData';

import MatchAll from '../../../../assets/match_all.png';
import SeasonContext from '../../../../common/Context';
import { PLAYER_ICON_DEFAULT } from '../../../../common/staticData';
import GameService from '../../../../services/game.service';
import { ActionData } from '../../components/common';
import TeamStatsVideoPlayer from '../../teams/tabs/stats/videoDialog';
import GameExportToEdits from '../../games/tabs/overview/exportEdits';
import { getPeriod } from '../../games/tabs/overview/tagListItem';
import PlayerStatErrorMessage from './errorMessage';
import PlayerEditDialog from '../playerEditDialog';
import redcard from '../../../../assets/images/redcard.jpeg';
import yellowcard from '../../../../assets/images/yellowcard.jpeg';

export const statList = [
    { id: 'goal', title: 'Goals', action: 'Goal' },
    { id: 'shot', title: 'Shots', action: 'GoalKick' },
    { id: 'penalties', title: 'Penalties', action: 'Penalty' },
    { id: 'penalties_missed', title: 'Penalties Missed', action: 'PenaltyMissed' },
    { id: 'shot_on_target', title: 'Shots On Target', action: 'GoalOpportunity' },
    { id: 'shot_off_target', title: 'Shots Off Target', action: 'ShotOffTarget' },
    { id: 'shot_on_box', title: 'Shots In The Box', action: 'GoalKick' },
    { id: 'shot_out_of_box', title: 'Shots Out Of The Box', action: 'GoalKick' },
    { id: 'dribble', title: 'Dribbles', action: 'Dribble' },
    { id: 'dribble_successful', title: 'Successful Dribbles', action: 'DribbleSuccess' },
    { id: 'crosses', title: 'Crosses', action: 'Cross' },
    { id: 'free_kick', title: 'Free Kicks', action: 'FreeKick' },
    { id: 'corner', title: 'Corners', action: 'Corner' },
    { id: 'passes', title: 'Passes', action: 'Passes' },
    { id: 'successful_passes', title: 'Successful Passes', action: 'PassesSuccess' },
    { id: 'passes_for_shots', title: 'Passes For Shots', action: 'PassesShots' },
    { id: 'key_passes', title: 'Key Passes', action: 'KeyPass' },
    { id: 'through_passes', title: 'Through Passes', action: 'ThroughPass' },
    { id: 'turnover', title: 'Turnovers', action: 'Turnover' },
    { id: 'offside', title: 'Offsides', action: 'Offside' },
    { id: 'draw_fouls', title: 'Draw Fouls', action: 'DrawFoul' },
    { id: 'tackle', title: 'Tackles', action: 'Tackle' },
    { id: 'interception', title: 'Interceptions', action: 'Interception' },
    { id: 'saved', title: 'Saved', action: 'Saved' },
    { id: 'clearance', title: 'Clearance', action: 'Clearance' },
    { id: 'blocked', title: 'Blocked', action: 'Blocked' },
    { id: 'fouls', title: 'Fouls', action: 'Foul' },
    { id: 'yellow_cards', title: 'Yellow Cards', action: 'YellowCard' },
    { id: 'red_cards', title: 'Red Cards', action: 'RedCard' },
    { id: 'player_games', title: 'Games', action: '' }
];

export const goalkeeper = [
    { id: 'passes', title: 'Passes', action: 'Passes' },
    { id: 'successful_passes', title: 'Successful Passes', action: 'PassesSuccess' },
    { id: 'short_passes', title: 'Short Passes', action: 'ShortPass' },
    { id: 'long_passes', title: 'Long Passes', action: 'LongPass' },
    { id: 'build_ups', title: 'Build Ups', action: 'BuildUp' },
    { id: 'super_save', title: 'Super Saved', action: 'SuperSaved' },
    { id: 'saved', title: 'Saved', action: 'Saved' },
    { id: 'goalkeeper_exit', title: 'Exits', action: 'Exits' },
    { id: 'air_challenge', title: 'Air Challenges', action: 'AirChallenge' },
    { id: 'ground_challenge', title: 'Ground Challenges', action: 'GroundChallenge' },
    { id: 'one_vs_one', title: '1 vs 1', action: 'One' },
    { id: 'goal_received', title: 'Goals Received', action: 'GoalReceive' },
    { id: 'tackle', title: 'Tackles', action: 'Tackle' },
    { id: 'interception', title: 'Interceptions', action: 'Interception' },
    { id: 'clearance', title: 'Clearance', action: 'Clearance' },
    { id: 'fouls', title: 'Fouls', action: 'Foul' },
    { id: 'draw_fouls', title: 'Draw Fouls', action: 'DrawFoul' },
    { id: 'red_cards', title: 'Red Cards', action: 'RedCard' },
    { id: 'yellow_cards', title: 'Yellow Cards', action: 'YellowCard' },
    { id: 'player_games', title: 'Games Played', action: '' }
];

const PlayerStatDialog = ({ open, onClose, player, games, isOur = true, gameIds, initialState, t }) => {
    const { seasonFilter } = React.useContext(SeasonContext);

    const [playerState, setPlayerState] = useState(null);
    const [gameHalf, setGameHalf] = useState(['first', 'second']);
    const [gameTime, setGameTime] = useState(['1', '2', '3', '4', '5', '6']);
    const [loading, setLoading] = useState(false);
    const [courtArea, setCourtArea] = useState(['1', '2', '3', '4']);
    const [errorOpen, setErrorOpen] = useState(false);
    const [gameResult, setGameResult] = useState(null);
    const [gamePlace, setGamePlace] = useState(null);
    const [playData, setPlayData] = useState([]);
    const [gameList, setGameList] = useState([]);
    const [videoOpen, setVideoOpen] = useState(false);
    const [exportOpen, setExportOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [playMode, setPlayMode] = useState(false);
    const [multipleIds, setMultipleIds] = useState([]);
    const [editOpen, setEditOpen] = useState(false);
    const [currentPlayer, setCurrentPlayer] = useState({});
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);

    const menuPopoverOpen = Boolean(menuAnchorEl);
    const menuPopoverId = menuPopoverOpen ? 'simple-popover' : undefined;
    const arr_stat_profile = ['player_games', 'yellow_cards', 'red_cards'];

    const { user: currentUser } = useSelector((state) => state.auth);

    const handleChangeGameHalf = (e, newHalf) => {
        setGameHalf(newHalf);

        if (newHalf.length === 2) {
            setGameTime(['1', '2', '3', '4', '5', '6']);
        } else if (newHalf.length === 1) {
            if (newHalf.includes('first')) {
                const newList = gameTime.filter((item) => item === '4' || item === '5' || item === '6');
                setGameTime(newList.length === 3 ? ['1', '2', '3'] : [...newList, '1', '2', '3']);
            } else if (newHalf.includes('second')) {
                const newList = gameTime.filter((item) => item === '1' || item === '2' || item === '3');
                setGameTime(newList.length === 3 ? ['4', '5', '6'] : [...newList, '4', '5', '6']);
            }
        } else {
            if (e.target.innerText === '1 HALF') {
                setGameTime(gameTime.filter((item) => item === '4' || item === '5' || item === '6'));
            } else if (e.target.innerText === '2 HALF') {
                setGameTime(gameTime.filter((item) => item === '1' || item === '2' || item === '3'));
            }
        }
    };

    const handleChangeGameTime = (e, newTime) => {
        setGameTime(newTime);

        if (newTime.length === 6) {
            setGameHalf(['first', 'second']);
        } else if (newTime.length >= 0 && newTime.length <= 2) {
            setGameHalf([]);
        } else if (newTime.length >= 3 && newTime.length <= 5) {
            const diff1 = ['1', '2', '3'].filter((item) => !newTime.includes(item));
            const diff2 = ['4', '5', '6'].filter((item) => !newTime.includes(item));

            if (diff1.length === 0) setGameHalf(['first']);
            if (diff2.length === 0) setGameHalf(['second']);
            if (diff1.length !== 0 && diff2.length !== 0) setGameHalf([]);
        }
    };

    const handleChangeGameResult = (e, newResult) => {
        setGameResult(newResult);
    };

    const handleChangeGamePlace = (e, newPlace) => {
        setGamePlace(newPlace);
    };

    const getPlayerStat = (id) => {
        if (id === 'player_games') return playerState[`total_${id}`];
        return playerState[`total_games_player_played`] > 1
            ? playerState[`total_${id}`] + ' (' + playerState[`average_${id}`] + ')'
            : playerState[`total_${id}`];
    };

    const handleChangeCourtArea = (courtId) => {
        const newList = courtArea.includes(courtId) ? courtArea.filter((item) => item !== courtId) : [...courtArea, courtId];
        setCourtArea(newList);
    };

    const handlePlayerStat = () => {
        if (gameTime.length === 0 || courtArea.length === 0) {
            setErrorOpen(true);
            return;
        }

        setLoading(true);
        const fetchData = async () => {
            try {
                const res = player.player_position_name === 'Goalkeeper' || player.pos_name === 'Goalkeeper'
                    ? await GameService.getGoalkeepersStatsAdvanceSummary({
                        seasonId: seasonFilter === 'none' ? null : seasonFilter.id,
                        leagueId: null,
                        gameId: !gameIds || (gameIds && gameIds.length === 0) ? null : gameIds.join(','),
                        teamId: currentPlayer.team_id,
                        playerId: currentPlayer.player_id,
                        gameTime: gameTime.join(','),
                        courtAreaId: courtArea.join(','),
                        insidePaint: null,
                        homeAway: gamePlace ? parseInt(gamePlace) : null,
                        gameResult: gameResult ? parseInt(gameResult) : null
                    })
                    : await GameService.getPlayersStatsAdvanced({
                        seasonId: seasonFilter === 'none' ? null : seasonFilter.id,
                        leagueId: null,
                        gameId: !gameIds || (gameIds && gameIds.length === 0) ? null : gameIds.join(','),
                        teamId: currentPlayer.team_id,
                        playerId: currentPlayer.player_id,
                        gameTime: gameTime.join(','),
                        courtAreaId: courtArea.join(','),
                        insidePaint: null,
                        homeAway: gamePlace ? parseInt(gamePlace) : null,
                        gameResult: gameResult ? parseInt(gameResult) : null
                    });

                setPlayerState(res[0]);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };
        fetchData();
    };

    const handleDisplayVideo = (cell) => async (e) => {
        if (playerState && playerState[`total_${cell.id}`] > 0 && cell.title !== 'Games') {
            const target = e.currentTarget;
            setMultipleIds([]);
            try {
                const res = await GameService.getGamePlayerTags(
                    currentUser.id,
                    currentPlayer.team_id,
                    `${currentPlayer.player_id}`,
                    !gameIds || (gameIds && gameIds.length === 0) ? null : gameIds.join(','),
                    ActionData[cell.action].action_id,
                    ActionData[cell.action].action_type_id,
                    ActionData[cell.action].action_result_id,
                    gameTime.length === 0 ? null : gameTime.join(','),
                    courtArea.length === 0 ? null : courtArea.join(','),
                    null,
                    gameResult ? parseInt(gameResult) : null,
                    gamePlace ? parseInt(gamePlace) : null
                );

                const data = cell.title === 'Shots In The Box'
                    ? res.filter((item) => item.inside_the_pain === true)
                    : cell.title === 'Shots Out Of The Box'
                        ? res.filter((item) => item.inside_the_pain === false)
                        : res;

                const newData = data.map((item) => ({
                    tag_id: item.id,
                    start_time: item.player_tag_start_time,
                    end_time: item.player_tag_end_time,
                    player_name: item.player_names,
                    action_name: item.action_names,
                    action_type: item.action_type_names,
                    action_result: item.action_result_names,
                    game_id: item.game_id,
                    team_id: currentPlayer.team_id,
                    court_area: item.court_area_id,
                    inside_pain: item.inside_the_pain,
                    period: getPeriod(item.period),
                    time: item.time_in_game,
                    home_team_image: item.home_team_logo,
                    away_team_image: item.away_team_logo,
                    home_team_goals: item.home_team_goal,
                    away_team_goals: item.away_team_goal
                }));

                for (const item of newData) {
                    const data = await GameService.addMultiple(item);
                    setMultipleIds((old) => [...old, data[0].id]);
                }

                setPlayData(newData);
                setMenuAnchorEl(target);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleExportTags = (cell) => async (e) => {
        e.preventDefault();
        if (playerState && playerState[`total_${cell.id}`] > 0 && cell.title !== 'Games') {
            try {
                const res = await GameService.getGamePlayerTags(
                    currentUser.id,
                    currentPlayer.team_id,
                    `${currentPlayer.player_id}`,
                    !gameIds || (gameIds && gameIds.length === 0) ? null : gameIds.join(','),
                    ActionData[cell.action].action_id,
                    ActionData[cell.action].action_type_id,
                    ActionData[cell.action].action_result_id,
                    gameTime.length === 0 ? null : gameTime.join(','),
                    courtArea.length === 0 ? null : courtArea.join(','),
                    null,
                    gameResult ? parseInt(gameResult) : null,
                    gamePlace ? parseInt(gamePlace) : null
                );

                const data = cell.title === 'Shots In The Box'
                    ? res.filter((item) => item.inside_the_pain === true)
                    : cell.title === 'Shots Out Of The Box'
                        ? res.filter((item) => item.inside_the_pain === false)
                        : res;

                setPlayData(data);
                setExportOpen(true);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleDisplayMultiple = () => {
        if (playData.length !== multipleIds.length) return;
        const crypt_msg = (!gameIds || (gameIds && gameIds.length === 0) ? null : gameIds.join(',')) + '|' + multipleIds.join(',');
        setMenuAnchorEl(null);
        setPlayMode(true);
        window.open(`/new_coach/multiple/${btoa(crypt_msg)}`, '_blank');
    };

    const handleDisplaySingle = () => {
        setMenuAnchorEl(null);
        setPlayMode(false);
        setVideoOpen(true);
    };

    const handleUpdatePlayer = (updatedPlayer) => {
        setCurrentPlayer(updatedPlayer);
        setPlayerState((prevState) => ({
            ...prevState,
            ...updatedPlayer,
        }));
    };

    useEffect(() => {
        setPlayerState(initialState);
        setGameHalf(['first', 'second']);
        setGameTime(['1', '2', '3', '4', '5', '6']);
        setCourtArea(['1', '2', '3', '4']);
        setGameResult(null);
        setGamePlace(null);
    }, [initialState, open]);

    useEffect(() => {
        if (games && games.length) {
            const filteredGames = games.filter((item) => gameIds && gameIds.includes(item.id));
            setGameList(filteredGames);
        }
    }, [games, gameIds]);

    useEffect(() => {
        setCurrentPlayer({});
        setLoading(true);
        if (player && Object.keys(player).length !== 0) {
            const fetchData = async () => {
                try {
                    const res = await GameService.getPlayerById(player.id ?? player.player_id);
                    const playerData = {
                        ...currentPlayer,
                        ...res,
                        player_full_name: `${res.player_first_name} ${res.player_last_name}`,
                        player_jersey_number: res.player_jersey_number,
                        player_image: res.player_image,
                        player_id: res.player_id,
                        player_position_id: res.player_position_id,
                        player_position_name: res.player_position_name,
                        player_first_name: res.player_first_name,
                        player_last_name: res.player_last_name,
                        player_date_of_birth: res.player_date_of_birth,
                        player_weight: res.player_weight,
                        player_height: res.player_height,
                        player_age_group_id: res.player_age_group_id,
                        player_age_group_name: res.player_age_group_name
                    };

                    setCurrentPlayer(playerData);

                    if (player.player_position_name === 'Goalkeeper') {
                        const statsRes = await GameService.getGoalkeepersStatsAdvanceSummary({
                            seasonId: seasonFilter === 'none' ? null : seasonFilter.id,
                            leagueId: null,
                            gameId: !gameIds || (gameIds && gameIds.length === 0) ? null : gameIds.join(','),
                            teamId: res.team_id,
                            playerId: player.player_id ?? null,
                            gameTime: gameTime.join(','),
                            courtAreaId: courtArea.join(','),
                            insidePaint: null,
                            homeAway: gamePlace ? parseInt(gamePlace) : null,
                            gameResult: gameResult ? parseInt(gameResult) : null
                        });

                        setPlayerState(statsRes[0]);
                        setLoading(false);
                    } else {
                        const statsRes = await GameService.getPlayersStatsAdvanceSummary({
                            seasonId: seasonFilter === 'none' ? null : seasonFilter.id,
                            leagueId: null,
                            gameId: !gameIds || (gameIds && gameIds.length === 0) ? null : gameIds.join(','),
                            teamId: res.team_id,
                            playerId: player.player_id ?? null,
                            gameTime: gameTime.join(','),
                            courtAreaId: courtArea.join(','),
                            insidePaint: null,
                            homeAway: gamePlace ? parseInt(gamePlace) : null,
                            gameResult: gameResult ? parseInt(gameResult) : null
                        });

                        setPlayerState(statsRes[0]);
                        setLoading(false);
                    }
                } catch (error) {
                    console.error(error);
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, [player, editOpen, seasonFilter, gameIds, gameTime, courtArea, gamePlace, gameResult]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="1400px" style={{ margin: '-5px' }}>
            <DialogContent style={{ padding: '16px', width:'1400px', display: 'flex', gap: '24px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', width: '550px'}}>
                    <Box sx={{ width: '100%', minHeight: '330px', border: '1px solid #E8E8E8', display: 'flex', flexDirection: 'column',  padding: '20px', borderRadius: '8px' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '10px' }}>
                            <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '16px', fontWeight: 600, color: '#1a1b1d', margin: '6px 0px' }}>{t('PROFILE')}</Typography>
                            {isOur && (
                                <Button variant="contained" sx={{ background: '#5C738E', '&:hover': { background: '#17375E' }, width: '25%', height: '36px', fontSize: '0.7rem' }}  onClick={() => setEditOpen(true)}>
                                   
                                    {t('Edit')}
                                </Button>
                            )}
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: '10px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', height: '140px', width: '125px' }}>
                                <img
                                    src={
                                        currentPlayer?.player_image
                                            ? currentPlayer?.player_image.includes(process.env.REACT_APP_S3_URI)
                                                ? currentPlayer?.player_image
                                                : process.env.REACT_APP_S3_URI + currentPlayer?.player_image
                                            : PLAYER_ICON_DEFAULT
                                    }
                                    style={{ borderRadius: '12px', height: '100%', width: 'auto' }}
                                    alt="Player"
                                />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '6px', width: '300px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', flex: 1, maxWidth: '130px', minWidth: '130px' }}>
                                        {t('FirstName')}:{' '}
                                    </Typography>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d', flex: 1 }}>
                                        {currentPlayer?.player_first_name ?? ''}
                                    </Typography>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', flex: 1, maxWidth: '130px', minWidth: '130px' }}>
                                        {t('LastName')}:{' '}
                                    </Typography>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d', flex: 1 }}>
                                        {currentPlayer?.player_last_name ?? ''}
                                    </Typography>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', flex: 1, maxWidth: '130px', minWidth: '130px' }}>
                                        {t('Jersey Number')}:{' '}
                                    </Typography>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d', flex: 1 }}>
                                        {currentPlayer?.player_jersey_number ?? ''}
                                    </Typography>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', flex: 1, maxWidth: '130px', minWidth: '130px' }}>
                                        {t('Birthday')}:{' '}
                                    </Typography>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d', flex: 1 }}>
                                        {currentPlayer?.player_date_of_birth
                                            ? ((date) => {
                                                  const day = date.getDate().toString().padStart(2, '0');
                                                  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // JavaScript months are 0-indexed.
                                                  const year = date.getFullYear();
                                                  return `${day}/${month}/${year}`;
                                              })(new Date(currentPlayer.player_date_of_birth))
                                            : ''}
                                    </Typography>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', width: '50px' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', flex: 1, maxWidth: '130px', minWidth: '130px' }}>
                                        {t('Games Played')}:{' '}
                                    </Typography>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d', flex: 1 }}>
                                        {!loading ? (playerState ? playerState['total_games_player_played'] : '0') : ''}
                                    </Typography>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '50px' }}>
                                {!loading && playerState && Number(playerState['total_yellow_cards']) > 0 && (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div
                                            style={{
                                                backgroundImage: `url(${yellowcard})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'cover',
                                                color: '#ffffff',
                                                lineHeight: '15px',
                                                fontSize: '20px',
                                                padding: '10px 16px 25px 18px',
                                                textAlign: 'center'
                                            }}
                                        >
                                            {playerState['total_yellow_cards']}
                                        </div>
                                    </div>
                                )}
                                {!loading && playerState && Number(playerState['total_red_cards']) > 0 && (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div
                                            style={{
                                                backgroundImage: `url(${redcard})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'cover',
                                                color: '#ffffff',
                                                lineHeight: '15px',
                                                fontSize: '20px',
                                                padding: '10px 16px 25px 18px',
                                                textAlign: 'center'
                                            }}
                                        >
                                            {playerState['total_red_cards']}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '6px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '6px', width: '100%', flex: 2 }}>
                                <div style={{ display: 'flex', alignItems: 'start' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', flex: 1, minWidth: '120px' }}>
                                        {t('Age Group')}:{' '}
                                    </Typography>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d', flex: 1 }}>
                                        {currentPlayer?.player_age_group_name ?? ''}
                                    </Typography>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'start' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', flex: 1, minWidth: '120px' }}>
                                        {t('Height')}:{' '}
                                    </Typography>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d', flex: 1 }}>
                                        {currentPlayer?.player_height ? parseInt(currentPlayer?.player_height) : ''} cm
                                    </Typography>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'start' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', flex: 1, minWidth: '120px' }}>
                                        {t('Weight')}:{' '}
                                    </Typography>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d', flex: 1 }}>
                                        {currentPlayer?.player_weight ? parseInt(currentPlayer?.player_weight) : ''} kg
                                    </Typography>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'start' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', flex: 1, minWidth: '120px' }}>
                                        {t('Preferred Foot')}:{' '}
                                    </Typography>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d', flex: 1 }}>
                                        {currentPlayer?.player_strong_foot ?? ''}
                                    </Typography>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '6px', width: '100%', flex: 3 }}>
                                <div style={{ display: 'flex', alignItems: 'start' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', flex: 1, maxWidth: '130px' }}>
                                        {t('First Position')}:{' '}
                                    </Typography>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d', flex: 1 }}>
                                        {currentPlayer?.player_position_name ?? ''}
                                    </Typography>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'start' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', flex: 1, maxWidth: '130px' }}>
                                        {t('First Role')}:{' '}
                                    </Typography>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d', flex: 1 }}>
                                        {currentPlayer?.player_position_role_one_name ?? ''}
                                    </Typography>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'start' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', flex: 1, maxWidth: '130px' }}>
                                        {t('Second Position')}:{' '}
                                    </Typography>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d', flex: 1 }}>
                                        {currentPlayer?.player_second_position_name ?? ''}
                                    </Typography>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'start' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', flex: 1, maxWidth: '130px' }}>
                                        {t('Second Role')}:{' '}
                                    </Typography>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d', flex: 1 }}>
                                        {currentPlayer?.player_position_role_two_name ?? ''}
                                    </Typography>
                                </div>
                            </div>
                        </Box>
                    </Box>
                    <Box sx={{ width: '100%', minHeight: '360px', border: '1px solid #E8E8E8', borderRadius: '8px', padding: '20px', display: 'flex', flexDirection: 'column', gap: '16px' }}>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                            <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '16px', fontWeight: 600, color: '#1a1b1d' }}>{t('FILTERS')}</Typography>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                            <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d' }}>{t('MATCH PERIOD')}</Typography>
                            <div style={{ width: '100%' }}>
                                <ToggleButtonGroup color="primary" fullWidth size="small" value={gameHalf} onChange={handleChangeGameHalf}>
                                    <ToggleButton value="first">{t('1 HALF')}</ToggleButton>
                                    <ToggleButton value="second">{t('2 HALF')}</ToggleButton>
                                </ToggleButtonGroup>
                                <ToggleButtonGroup color="primary" fullWidth size="small" value={gameTime} onChange={handleChangeGameTime}>
                                    <ToggleButton value="1">1-15'</ToggleButton>
                                    <ToggleButton value="2">16-30'</ToggleButton>
                                    <ToggleButton value="3">31-45'+</ToggleButton>
                                    <ToggleButton value="4">46-60'</ToggleButton>
                                    <ToggleButton value="5">61-75'</ToggleButton>
                                    <ToggleButton value="6">76-90'+</ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                        </div>
                        <div style={{ display: 'flex', gap: '16px' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '150px',
                                    width: '272px',
                                    borderRadius: '12px',
                                    background: `url(${MatchAll}) center center / cover no-repeat silver`
                                }}
                            >
                                {['4', '3', '2', '1'].map((court, index) => (
                                    <div
                                        key={`${index}-${court}`}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flex: 1,
                                            borderRadius: index === 0 ? '12px 0 0 12px' : index === 3 ? '0 12px 12px 0' : 0,
                                            height: '100%',
                                            cursor: 'pointer',
                                            background: courtArea.includes(court) ? 'rgba(200, 200, 200, 0)' : 'rgba(200, 200, 200, 0.7)',
                                            border: '1px solid white'
                                        }}
                                        onClick={() => handleChangeCourtArea(court)}
                                    />
                                ))}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flex: 1, padding: '6px', borderRadius: '8px', border: '1px solid #E8E8E8' }}>
                                <div style={{ width: '100%' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d' }}>{t('GAME RESULT')}</Typography>
                                    <ToggleButtonGroup disabled={loading} exclusive color="primary" fullWidth size="small" value={gameResult} onChange={handleChangeGameResult}>
                                        <ToggleButton value="1">{t('Won')}</ToggleButton>
                                        <ToggleButton value="2">{t('Draw')}</ToggleButton>
                                        <ToggleButton value="3">{t('Lose')}</ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                                <div style={{ width: '100%' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d' }}>{t('GAME PLACE')}</Typography>
                                    <ToggleButtonGroup disabled={loading} exclusive color="primary" fullWidth size="small" value={gamePlace} onChange={handleChangeGamePlace}>
                                        <ToggleButton value="1">{t('Home')}</ToggleButton>
                                        <ToggleButton value="2">{t('Away')}</ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' , width: '850px'}}>
                    <Box sx={{ width: '100%', minHeight: '120px', border: '1px solid #E8E8E8', borderRadius: '8px', padding: '16px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                        <Box sx={{ display: 'flex', width: '100%' }}>
                            <Typography sx={{ width: '98%', fontFamily: "'DM Sans', sans-serif", fontSize: '16px', fontWeight: 600, color: '#1a1b1d' }}>{t('TEAM RESULTS')}</Typography>
                            <CloseIcon sx={{ color: 'red', cursor: 'pointer' }} onClick={onClose} />
                        </Box>
                        <Box style={{ display: 'flex', width: '100%', alignItems: 'center', gap: 5 }}>
                            <div style={{ display: 'flex', flexGrow: 1, alignItems: 'center' }}>
                            <img src={!loading ? 
                                    (playerState && playerState['team_image'] && playerState['team_image'].trim() !== '') ? 
                                    (playerState['team_image'].includes(process.env.REACT_APP_S3_URI) ? 
                                        playerState['team_image'] : process.env.REACT_APP_S3_URI + playerState['team_image']) : 
                                    TEAM_ICON_DEFAULT : 
                                    TEAM_ICON_DEFAULT
                                } style={{ width: 60 }} alt="Team" />

                                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, gap: 10 }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d' }}>{!loading ? (playerState ? playerState['team_name'] : '') : ''}</Typography>
                                    <div style={{ display: 'flex', gap: 80 }}>
                                        <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d' }}>
                                            Total Team Games: {!loading ? (playerState ? playerState['team_total_games'] : '0') : ''}
                                        </Typography>
                                        <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d' }}>
                                            Games Selected: {!loading ? (playerState ? playerState['games_selected'] : '0') : ''}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', gap: 30, marginRight: 40 }}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d' }}>{!loading ? (playerState ? playerState['total_games_won'] : '0') : '0'}</Typography>
                                    <Avatar sx={{ bgcolor: green[500], width: 28, height: 28, fontSize: '14px' }}>W</Avatar>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d' }}>{!loading ? (playerState ? playerState['total_games_draw'] : '0') : '0'}</Typography>
                                    <Avatar sx={{ bgcolor: grey[500], width: 28, height: 28, fontSize: '14px' }}>D</Avatar>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d' }}>{!loading ? (playerState ? playerState['total_games_lose'] : '0') : '0'}</Typography>
                                    <Avatar sx={{ bgcolor: red[500], width: 28, height: 28, fontSize: '14px' }}>L</Avatar>
                                </div>
                            </div>
                        </Box>
                    </Box>
                    <Box sx={{ width: '100%', minHeight: '193px', border: '1px solid #E8E8E8', borderRadius: '8px', padding: '16px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                        <Box sx={{ display: 'flex', width: '100%' }}>
                            <Typography sx={{ width: '98%', fontFamily: "'DM Sans', sans-serif", fontSize: '16px', fontWeight: 600, color: '#1a1b1d' }}>{t('MINUTES')}</Typography>
                        </Box>
                        <div style={{ display: 'flex', width: '100%', gap: 100 }}>
                            <div style={{ width: '50%' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: -10 }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d' }}>ALL GAMES</Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', flex: 1 }}>{t('Total Minutes Played')}: </Typography>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d' }}>
                                        {!loading ? (playerState ? playerState['total_games_player_minutes'] : '0') : ''}
                                    </Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', flex: 1 }}>{t('Total Games Played')}: </Typography>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d' }}>
                                        {!loading ? (playerState ? playerState['total_games_player_played'] : '0') : ''}
                                    </Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', flex: 1 }}>{t('Starting 11')}: </Typography>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d' }}>
                                        {!loading ? (playerState ? playerState['total_games_player_starting'] : '0') : ''}
                                    </Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', flex: 1 }}>{t('Average Minutes Per Game')}:</Typography>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d' }}>
                                        {!loading ? (playerState ? parseInt(playerState['total_games_player_average_minutes']) : '0') : ''}
                                    </Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', flex: 1 }}>{t('Percentage Minutes Played')}:</Typography>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d' }}>
                                        {!loading ? (playerState ? parseInt(playerState['total_games_player_percentage_minutes']) + ' %' : '0') : ''}
                                    </Typography>
                                </div>
                            </div>
                            <div style={{ width: '50%' }}>
                                <div style={{ display: 'flex', marginTop: -10, textAlign: 'center', justifyContent: 'center', width: '100%' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d' }}>SELECTED GAMES</Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', flex: 1 }}>{t('Minutes Played')}: </Typography>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d' }}>
                                        {!loading ? (playerState ? playerState['selected_games_player_minutes'] : '0') : ''}
                                    </Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', flex: 1 }}>{t('Games Played')}: </Typography>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d' }}>
                                        {!loading ? (playerState ? playerState['selected_games_player_played'] : '0') : ''}
                                    </Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', flex: 1 }}>{t('Starting 11')}: </Typography>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d' }}>
                                        {!loading ? (playerState ? playerState['selected_games_player_starting'] : '0') : ''}
                                    </Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', flex: 1 }}>{t('Average Minutes Per Game')}:</Typography>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d' }}>
                                        {!loading ? (playerState ? parseInt(playerState['selected_games_player_average_minutes']) : '0') : ''}
                                    </Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 600, color: '#1a1b1d', flex: 1 }}>{t('Percentage Minutes Played')}:</Typography>
                                    <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '14px', fontWeight: 500, color: '#1a1b1d' }}>
                                        {!loading ? (playerState ? parseInt(playerState['selected_games_player_percentage_minutes']) + ' %' : '0') : ''}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </Box>
                    <Box sx={{ width: '100%', minHeight: '360px', border: '1px solid #E8E8E8', borderRadius: '8px', padding: '16px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                        <Box sx={{ display: 'flex', width: '100%' }}>
                            <Typography sx={{ width: '98%', fontFamily: "'DM Sans', sans-serif", fontSize: '16px', fontWeight: 600, color: '#1a1b1d' }}>{t('PLAYER STATS')}</Typography>
                        </Box>
                        <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto auto', gap: '8px', minWidth: 500 }}>
                            {((player && player.player_position_name && player.player_position_name === 'Goalkeeper') || (player && player.pos_name && player.pos_name === 'Goalkeeper')
                                ? goalkeeper
                                : statList
                            ).map(
                                (item, index) =>
                                    !arr_stat_profile.includes(item.id) &&
                                    playerState &&
                                    playerState[`total_${item.id}`] > 0 && (
                                        <div
                                            key={index}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                flexDirection: 'column',
                                                padding: '6px 0',
                                                width: '180px',
                                                height: '44px',
                                                borderRadius: '12px',
                                                border: '1px solid #E8E8E8',
                                                background: '#17375E',
                                                cursor: 'pointer'
                                            }}
                                            onClick={handleDisplayVideo(item)}
                                            onContextMenu={handleExportTags(item)}
                                        >
                                            <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '13px', fontWeight: 500, color: '#FFFFFF' }}>{!loading ? (playerState ? playerState[`total_${item.id}`] : '') : ''} {t(item.title)}</Typography>
                                            {!loading && playerState && playerState.selected_games_player_played > 1 && (
                                                <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '13px', fontWeight: 500, color: '#FFFFFF' }}>
                                                    {playerState ? playerState[`average_${item.id}`] : '0'} per game
                                                </Typography>
                                            )}
                                        </div>
                                    )
                            )}
                        </div>
                    </Box>
                </Box>
            </DialogContent>
            <PlayerStatErrorMessage open={errorOpen} onClose={() => setErrorOpen(false)} />
            {!playMode && (
                <TeamStatsVideoPlayer
                    t={t}
                    open={videoOpen}
                    onClose={(flag) => {
                        setVideoOpen(false);
                        if (flag) setRefresh((r) => !r);
                    }}
                    video_url={gameList}
                    tagList={playData}
                />
            )}
            <GameExportToEdits t={t} open={exportOpen} onClose={() => setExportOpen(false)} tagList={playData} isTeams={false} />
            <Popover
                id={menuPopoverId}
                open={menuPopoverOpen}
                anchorEl={menuAnchorEl}
                onClose={() => setMenuAnchorEl(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                sx={{ '& .MuiPopover-paper': { width: '220px', borderRadius: '12px', border: '1px solid #E8E8E8' } }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', padding: '12px 20px', cursor: 'pointer' }} onClick={handleDisplaySingle}>
                    <p className="menu-item">Single Play</p>
                </Box>
                <Divider sx={{ width: '100%' }} />
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', padding: '12px 20px', cursor: 'pointer' }} onClick={handleDisplayMultiple}>
                    <p className="menu-item">Multiple Play</p>
                </Box>
            </Popover>
            <PlayerEditDialog t={t} open={editOpen} onClose={() => setEditOpen(false)} player={player} onSave={handleUpdatePlayer} />
        </Dialog>
    );
};

export default PlayerStatDialog;